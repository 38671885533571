import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticatedAdmin, setLoadingAdmin, setAdmin, setLogsData, setNeedReloadingLogs } from "../features/adminSlice.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery(
  { 
    baseUrl: getBackendURI("/api/v1/admin"),
    mode: getQueryCorsMode(),
    credentials: 'include', // Include cookies in the request
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: ["Admin", "Customers", "Users", "Deliveries", "FinishedDeliveries", "Logs"],
  endpoints: (builder) => ({
    //---------- Own Admin ----------
    adminGetMe: builder.query({
      query: () => {
        return {
          url: "/me",
          credentials: 'include',
        };
      },
      transformResponse: (result) => result.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        console.log("adminGetMe started");
        try {
          const { data } = await queryFulfilled;
          dispatch(setAdmin(data));
          dispatch(setIsAuthenticatedAdmin(true));
          dispatch(setLoadingAdmin(false));
        } catch (error) {
          dispatch(setLoadingAdmin(false));
          console.log(error);
        }
      },
      providesTags: ["Admin"],
    }),
    adminUpdateProfile: builder.mutation({
      query(body) {
        return {
          url: "/me/update",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Admin"],
    }),
    adminUploadAvatar: builder.mutation({
      query(body) {
        return {
          url: "/me/upload_avatar",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Admin"],
    }),
    adminUpdatePassword: builder.mutation({
      query(body) {
        return {
          url: "/password/update",
          method: "PUT",
          body,
        };
      },
    }),
    adminForgotPassword: builder.mutation({
      query(body) {
        return {
          url: "/password/forgot",
          method: "POST",
          body,
        };
      },
    }),
    adminResetPassword: builder.mutation({
      query({ token, body }) {
        return {
          url: `/password/reset/${token}`,
          method: "PUT",
          body,
        };
      },
    }),
    //---------- Customer ----------
    adminGetAllCustomers: builder.query({
      query: (params) => ({
        url: "/customers",
        params: {
          status: params?.status,
        },
      }),
      transformResponse: (result) => result.customers,
      providesTags: ["Customers"],
    }),
    adminDeleteCustomer: builder.mutation({
      query(id) {
        return {
          url: `/customers/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Customers"],
    }),
    adminGetCustomerDetails: builder.query({
      query: (id) => `/customers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Customers', id }],
    }),
    adminUpdateCustomer: builder.mutation({
      query({ id, body }) {
        return {
          url: `/customers/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Customers"],
    }),
    adminCreateCustomer: builder.mutation({
      query({ body }) {
        return {
          url: `/customers/new`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Customers"],
    }),    
    // //---------- Users ----------
    adminGetAllUsers: builder.query({
      query: (params) => ({
        url: "/users",
        params: {
          status: params?.status,
        },
      }),
      /*
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        console.log("Started");
        try {
          const result = await queryFulfilled;
          console.log("Responded");
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      },
      */
      transformResponse: (result) => result.users,
      providesTags: ["Users"],
    }),
    adminDeleteUser: builder.mutation({
      query(id) {
        return {
          url: `/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
    adminGetUserDetails: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: ["Users"],
    }),
    adminUpdateUser: builder.mutation({
      query({ id, body }) {
        return {
          url: `/users/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    adminCreateUser: builder.mutation({
      query({ body }) {
        return {
          url: `/users/new`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    //----------- Driver ---------
    adminGetAllDrivers: builder.query({
      query: (params) => ({
        url: "/drivers",
        params: {
          status: params?.status,
        },
      }),
      /*
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        console.log("Started");
        try {
          const result = await queryFulfilled;
          console.log("Responded");
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      },
      */
      transformResponse: (result) => result.drivers,
      providesTags: ["Users"],
    }),
    adminDeleteDriver: builder.mutation({
      query(id) {
        return {
          url: `/drivers/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
    adminGetDriverDetails: builder.query({
      query: (id) => `/drivers/${id}`,
      providesTags: ["Users"],
    }),
    adminUpdateDriver: builder.mutation({
      query({ id, body }) {
        return {
          url: `/drivers/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    adminCreateDriver: builder.mutation({
      query({ body }) {
        return {
          url: `/drivers/new`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    //---------- Driver Deliveries ----------
    adminGetDriverDeliveries: builder.query({
      query: ({driverId, filter, params}) => ({
        url: `/drivers/${driverId}/deliveries/${filter}`,
        params: {
          page: params?.page,
        },
      }),
      providesTags: ["Deliveries"],
    }),    
    adminUpdateDriverDelivery: builder.mutation({
      query({ deliveryId, body }) {
        return {
          url: `/deliveries/${deliveryId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Deliveries"],
    }),
    //---------- Vendor ----------
    adminGetAllVendors: builder.query({
      query: (params) => ({
        url: "/vendors",
        params: {
          status: params?.status,
        },
      }),
      transformResponse: (result) => result.vendors,
      providesTags: ["Vendors"],
    }),
    adminDeleteVendor: builder.mutation({
      query(id) {
        return {
          url: `/vendors/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Vendors"],
    }),
    adminGetVendorDetails: builder.query({
      query: (id) => `/vendors/${id}`,
      providesTags: (result, error, id) => [{ type: 'Vendors', id }],
    }),
    adminUpdateVendor: builder.mutation({
      query({ id, body }) {
        return {
          url: `/vendors/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Vendors"],
    }),
    adminCreateVendor: builder.mutation({
      query({ body }) {
        return {
          url: `/vendors/new`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Vendors"],
    }),    
    //---------- Log ----------
    adminReadLogs: builder.query({
      query: (params) => ({
        url: "/logs",
        params: {
          timestamp: params?.timestamp,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogsData(data?.logs));
          dispatch(setNeedReloadingLogs(false));
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ["Logs"],
    }),
  }),
});

export const {
  useAdminGetMeQuery,
  useAdminUpdateProfileMutation,
  useAdminUploadAvatarMutation,
  useAdminUpdatePasswordMutation,
  useAdminForgotPasswordMutation,
  useAdminResetPasswordMutation,

  useAdminGetAllCustomersQuery,
  useAdminDeleteCustomerMutation,
  useAdminGetCustomerDetailsQuery,
  useAdminUpdateCustomerMutation,
  useAdminCreateCustomerMutation,

  useAdminGetAllUsersQuery,
  useAdminDeleteUserMutation,
  useAdminGetUserDetailsQuery,
  useAdminUpdateUserMutation,
  useAdminCreateUserMutation,

  useAdminGetAllDriversQuery,
  useLazyAdminGetAllDriversQuery,
  useAdminDeleteDriverMutation,
  useAdminGetDriverDetailsQuery,
  useAdminUpdateDriverMutation,
  useAdminCreateDriverMutation,
  
  useAdminGetDriverDeliveriesQuery,
  useAdminUpdateDriverDeliveryMutation,

  useAdminGetAllVendorsQuery,
  useAdminDeleteVendorMutation,
  useAdminGetVendorDetailsQuery,
  useAdminUpdateVendorMutation,
  useAdminCreateVendorMutation,

  useLazyAdminReadLogsQuery,
} = adminApi;
