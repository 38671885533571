
import React, { useState, useEffect, useRef  } from 'react';
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import _ from "lodash";
import { LeftAlignedCell, EditableNumberCell } from '../CustomMDBDataTableCell';
import { usePopper } from 'react-popper';

export const PriceListGeneratePopup = ({ onClose, productHash, visibility, vendor, vendorId, updateFunction }) => {
  const [formUpdated, setFormUpdated] = useState(false);
  const popupRef = useRef();
  const suggestionPopup = useRef();   // Create a ref

  const [clonedPriceListData, setClonedPriceListData] = useState([]);
  const [pastedText, setPastedText] = useState("");
  const [productNameHash, setProductNameHash] = useState({});
  const [suggestions, setSuggestions] = useState({});
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [usedProductIds, setUsedProductIds] = useState(new Set());
  const [selectedLineIndex, setSelectedLineIndex] = useState(-1);
  const [processButtonDisabled, setProcessButtonDisabled] = useState(true);

  const [showPopup, setShowPopup] = useState(false);
  const textAreaRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(textAreaRef.current, popperElement, {
    placement: 'left-start',
  });


  // Add this useEffect hook
  useEffect(() => {
    const handleClick = (event) => {
      console.log("clicked");
      
      if (showPopup && suggestionPopup?.current && !suggestionPopup?.current.contains(event.target)){
        setSelectedLineIndex(-1);
        setShowPopup(false);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        event.preventDefault(); // Prevent default behavior of the event
        event.stopPropagation(); // Stop the event from propagating further
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("click", handleClick, true);
    } else {
      document.removeEventListener("click", handleClick, true);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClick, true);
    };
  }, [visibility, showPopup ]);


  // Generate the product name hash for faster lookup
  useEffect (() => {
    const hash = {}; 
    if (productHash) {
      Object.entries(productHash).forEach(([id, product]) => {
        const words = product.product.name.toLowerCase().split(/\s+/);
        words.forEach(word => {
          if (!hash[word]) {
            hash[word] = [];
          }
          hash[word].push(id);
        });
      });
      setProductNameHash(hash)
    }
  }, [productHash]); 

  //--------------------------------- Functions ---------------------------------

  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedPriceListData[index] !== value) {
      clonedPriceListData[index].price = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  const areProductNamesMatched = (name1, name2) => {
    name1 = String(name1).toLowerCase();
    name2 = String(name2).toLowerCase();

    // Remove all non-word characters and split the strings into arrays of words
    const words1 = name1.replace(/\W/g, ' ').split(/\s+/).sort().join('');
    const words2 = name2.replace(/\W/g, ' ').split(/\s+/).sort().join('');

    // Check if the arrays of words are equal
    return _.isEqual(words1, words2);
  }

  const adjustSpacing = (line) => {
    // Regular expression to find '$' not preceded by a space
    const regex1 = /\$/g;
    line = line.replace(regex1, (match, offset, string) => {
      // Check if the character before the match is not a space
      if (offset === 0 || string[offset - 1] !== ' ') {
        return ' $';
      }
      return match;
    });


    // Regular expression to find numbers at the end of a line not preceded by a space
    const regex2 = /(\d+)$/gm;
    // Replace such occurrences with a space followed by the number
    line = line.replace(regex2, ' $1');

    return line;
  };

  const removeArrowFromTextEnd =(line) => {
    // Regular expression to match ' =>' followed by optional whitespace at the end of a line
    const regex = / =>\s*$/;
    // Replace the matched pattern with an empty string
    return line.replace(regex, '');
  } 

  // insert the product into the clonedPriceListData
  const handleGeneratePriceList = () => {
    if (processButtonDisabled) return;
    setProcessButtonDisabled(true);
    const lines = pastedText.split('\n');
    const priceList = [];
    let unmatchedLines = []; // Store lines that don't match any product
    const unmatchedSuggestions = {};

    lines.forEach(line => {
      // there may be arrow added by previous processing
      const originalLine = removeArrowFromTextEnd(line);
      // Add space between price and name
      const adjustedLine = adjustSpacing(originalLine);
      const parts = adjustedLine.match(/(.*?)\s-\s\$(\d+)/) || adjustedLine.match(/(.*?)\s\$(\d+)/) || adjustedLine.match(/(.*?)\s(\d+)/);

      if (parts && parts.length === 3) {
        const productNamePart = parts[1].trim().replace(/\$+$/, '').trim();
        const price = parseInt(parts[2], 10); // Convert price to a number
        const product = Object.values(productHash).find(product => areProductNamesMatched(product.product.name, productNamePart))?.product;
        if (product) {
          if (!usedProductIds.has(product._id)) {
            if (!clonedPriceListData.find(item => item.product === product._id)) {
              priceList.push({ name: product.name, product: product._id, price });
              setUsedProductIds((prevIds) => new Set(prevIds).add(product._id));
            }
          }
        } else {
          const suggestions = generateNotFoundProductSuggestions(productNamePart, price);
          if (suggestions.length > 0) {
            unmatchedSuggestions[originalLine] = suggestions;
            unmatchedLines.push(`${originalLine} =>`); // Add line to unmatchedLines if no product matches
          } else {
            unmatchedLines.push(originalLine);
          }
        }
      } else {
        unmatchedLines.push(originalLine); // Add line to unmatchedLines if no product matches
      }
    });
    setSuggestions(unmatchedSuggestions);

    const newPriceList = [...clonedPriceListData, ...priceList];
    newPriceList.sort((a, b) => a.name.localeCompare(b.name));
    setClonedPriceListData(newPriceList);
    setFormUpdated(!formUpdated);
    setPastedText(unmatchedLines.join('\n')); // Update pastedText to only include unmatched lines
  };

  const generateNotFoundProductSuggestions = (name, price) => {
    const words = name.toLowerCase().split(/\s+/);
    const suggestions = [];
    words.forEach(word => {
      word = word.toLowerCase();
      if (productNameHash[word]) {
        productNameHash[word].forEach(id => {
          if (!suggestions.find(suggestion => suggestion.id === id) && !usedProductIds.has(id)) {
            suggestions.push({ id, price });
          }
        });
      }
    });
    return suggestions;
  };

  const handleInsertData = (data) => {
    const priceList = clonedPriceListData.map((item) => {
      return {product: item.product, price: item.price};
    });
    onClose();
    updateFunction({
      vendorId,
      body: {
        priceList,
      },
    });
  }

  const handleOverwriteData = (data) => {
    const priceList = clonedPriceListData.map((item) => {
      return {product: item.product, price: item.price};
    });
    onClose();
    updateFunction({
      vendorId,
      body: {
        overwrite: true,
        priceList,
      },
    });
  }

  const removeSelectedLine = (index) => {
    const lines = pastedText.split("\n"); // Assuming pastedText holds the text area content
    if (index >= 0 && index < lines.length) {
      lines.splice(index, 1); // Remove the line at selectedLineIndex
      const newText = lines.join("\n");
      setPastedText(newText); // Assuming setPastedText updates the text area content
    }
  };

  const removeAnItem = (index) => {
    if (index >=0 && index < clonedPriceListData.length) {
      // Update used ids
      usedProductIds.delete(clonedPriceListData[index].product);
      clonedPriceListData.splice(index, 1);
      setFormUpdated(!formUpdated);
    }
  };

  const handleAccepSuggestion = ({ id, price }) => {
    const product = Object.values(productHash).find(product => product.product._id === id).product;
    const newItem = { name: product.name, product: id, price };
    const newPriceList = [...clonedPriceListData, newItem];
    setUsedProductIds((prevIds) => new Set(prevIds).add(id));
    setShowPopup(false);
    removeSelectedLine(selectedLineIndex)
    setSelectedLineIndex(-1);
    newPriceList.sort((a, b) => a.name.localeCompare(b.name));
    setClonedPriceListData(newPriceList);
    setFormUpdated(!formUpdated);
  }

  const handleTextChange = (e) => {
    setProcessButtonDisabled(false);
    setPastedText(e.target.value);
  };

  const handleTextAreaClick = (e) => {
    console.log("handleTextAreaClick")
    if (showPopup){
      setSelectedLineIndex(-1);
      setShowPopup(false);
    }
  }

  const handleTextAreaDoubleClick = (e) => {
    const textArea = e.target;
    const text = textArea.value;
    if (!text) return;
    const cursorPosition = textArea.selectionStart; // Get cursor position
  
    // Calculate the line number
    const textBeforeCursor = text.substr(0, cursorPosition);
    const lineNumber = textBeforeCursor.split("\n").length;
  
    // Optionally, you can also determine the text of the selected line
    const lines = text.split("\n");
    const currentLineText = removeArrowFromTextEnd(lines[lineNumber - 1]);

    // determine clicked y position
    const mouseY = e.clientY;
    const textAreaRect = textArea.getBoundingClientRect();
    const textAreaTop = textAreaRect.top + window.scrollY;
    if (popperElement) {
      popperElement.style.left = `0px`; 
      popperElement.style.top = `${mouseY - textAreaTop}px`; 
    }
    const filteredSuggestions = suggestions && suggestions[currentLineText] ? suggestions[currentLineText].filter(suggestion => !usedProductIds.has(suggestion.id)) : [];

    if (filteredSuggestions && filteredSuggestions.length > 0) {
      setSelectedLineIndex(lineNumber - 1);
      setShowPopup(true);
      setFormUpdated(!formUpdated);
      setCurrentSuggestions(filteredSuggestions);
    }
  };

  const windowStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };

  return(
    <div ref={popupRef} id="popup" class="popupWide" style={{...windowStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div style={{ width: '90%'}}>
          <h4 style={{ color: 'white' }}>{vendor} Price List</h4>
        </div>
        <div style={{ width: '10%'}}>
          <center>
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </center>
        </div>
      </div>

      <div className="row col-12 my-2">
        <div style={{ width: '35%', maxHeight: '80vh', overflow: 'overlay'}}>
          {/* when showing popup, dont handle double click */}
          { showPopup ? 
            (
              <textarea 
                ref={textAreaRef}
                className="form-control" 
                rows="20" 
                placeholder="Paste text here"
                value={pastedText} // Set the value to the state variable
                onChange={handleTextChange} //
                onClick={(e) => { handleTextAreaClick(e) }}
              >
              </textarea>
            ) :
            (
              <textarea 
                ref={textAreaRef}
                className="form-control" 
                rows="20" 
                placeholder="Paste text here"
                value={pastedText} // Set the value to the state variable
                onChange={handleTextChange} //
                onDoubleClick={(e) => { handleTextAreaDoubleClick(e)}}
              >
              </textarea>
            )
          }
          
        </div>
        <div style={{ width: '65%', maxHeight: '80vh', overflow: 'overlay'}}>
          <table className="table px-3" style={{ border: '1px solid black' }}>

            <thead>
              <tr>
                <th style={{ width: '10%', border: '1px solid black' }}>#</th>
                <th style={{ width: '65%', border: '1px solid black' }}>Product</th>
                <th style={{ width: '15%', border: '1px solid black' }}>Price</th>
                <th style={{ width: '10%', border: '1px solid black' }}>Action</th>
              </tr>
            </thead>
            <tbody>
            {
              clonedPriceListData?.map((item, index) => {
                let priceColor = "black";
                const name = item.product ? `${productHash[item.product]?.product.name}` : "";
                return (
                  <tr key={index}>
                    <td key={`product-${index}`} style={{ width: '10%', border: '1px solid black'}}>
                      {index + 1}
                    </td>
                    <td key={`name-${index}`} style={{ width: '65%', border: '1px solid black' }}>
                      <LeftAlignedCell value={name}/>
                    </td>
                    <td key={`price-${index}`} style={{ width: '15%', border: '1px solid black' }}>
                      <EditableNumberCell value={item.price} onChange={(val) => {handlePriceChange(index, val)}} color={priceColor} />
                    </td>
                    <td key={`remove-${index}`} style={{ width: '10%', border: '1px solid black' }}>
                    <button
                      className="btn btn-outline-danger ms-2"
                      onClick={() => removeAnItem(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>
        </div>
        {/* Bottom buttons */}
        <div className="row col-12 my-2">
          <div style={{ width: '35%'}}> 
            <center>
              <button className='btn btn-primary' onClick={() => handleGeneratePriceList()} disabled={processButtonDisabled}>
                Process
              </button>
            </center>
          </div> 
          <div style={{ width: '65%'}} className="text-center"> 
            <button className='btn btn-success' onClick={() => handleInsertData()} style={{ marginRight: '10px' }} disabled={!clonedPriceListData || clonedPriceListData.length === 0}>
              Insert Data
            </button>
            
            <button className='btn btn-success' onClick={() => handleOverwriteData()} style={{ marginLeft: '10px' }}  disabled={!clonedPriceListData || clonedPriceListData.length === 0}>
              Overwrite Data
            </button>
          </div>
        </div>
      </div>
      { showPopup && currentSuggestions && (
        <div className="popupTiny" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <div ref={suggestionPopup} style={{ backgroundColor: 'white', padding: '5px', border: '1px solid black', borderRadius: '5px' }}>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {currentSuggestions.map((suggestion, index) => (
                <li key={`${suggestion.id}-${suggestion.price}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}  onClick={()=>{handleAccepSuggestion({id: suggestion.id, price: suggestion.price })}}>
                  <span style={{ textAlign: 'left' }}>{productHash[suggestion.id].product.name}</span>
                  <span style={{ textAlign: 'right' }}>{suggestion.price}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};