import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setAccountLocked, setCustomer, setIsAuthenticated } from "../features/customerSlice.js";
import { customerApi } from "./customerApi.js";
import { productsApi } from "./productsApi.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const customerAuthApi = createApi({
  reducerPath: "customerAuthApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  endpoints: (builder) => ({
    customerRegister: builder.mutation({
      query(body) {
        return {
          url: "/register",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(customerApi.endpoints.customerGetMe.initiate(null, { forceRefetch: true }));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    customerLogin: builder.mutation({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAccountLocked(false));
          await dispatch(customerApi.endpoints.customerGetMe.initiate(null, { forceRefetch: true }));
          dispatch(productsApi.util.invalidateTags(["Product"]));
        } catch (error) {
          //console.log(error);
        }
      },
    }),
    customerLogout: builder.query({
      query: () => "/logout",
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setIsAuthenticated(false));
          dispatch(setCustomer(null));
          dispatch(productsApi.util.invalidateTags(["Product"]));
        } catch (error) {
          console.log(error);
        }
      },
    })
  }),
});

export const { useCustomerLoginMutation, useCustomerRegisterMutation, useLazyCustomerLogoutQuery } = customerAuthApi;
