import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticated, setLoading, setCustomer, setAccountLocked } from "../features/customerSlice.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    customerGetMe: builder.query({
      query: () => `/me`,
      transformResponse: (result) => result.customer,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCustomer(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
          dispatch(setAccountLocked(false));
        } catch (error) {
          dispatch(setLoading(false));
          if (error?.error?.status === 423) {
            dispatch(setAccountLocked(true));
          }
        }
      },
      providesTags: ["Customer"],
    }),
    customerUpdateProfile: builder.mutation({
      query(body) {
        return {
          url: "/me/update",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Customer"],
    }),
    customerUploadAvatar: builder.mutation({
      query(body) {
        return {
          url: "/me/upload_avatar",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Customer"],
    }),
    customerUpdatePassword: builder.mutation({
      query(body) {
        return {
          url: "/password/update",
          method: "PUT",
          body,
        };
      },
    }),
    customerForgotPassword: builder.mutation({
      query(body) {
        return {
          url: "/password/forgot",
          method: "POST",
          body,
        };
      },
    }),
    customerResetPassword: builder.mutation({
      query({ token, body }) {
        return {
          url: `/password/reset/${token}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useCustomerGetMeQuery,
  useCustomerUpdateProfileMutation,
  useCustomerUploadAvatarMutation,
  useCustomerUpdatePasswordMutation,
  useCustomerForgotPasswordMutation,
  useCustomerResetPasswordMutation,
} = customerApi;
