import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import _ from "lodash";

import AdminLayout from "../../layout/AdminLayout";
import { useAdminCreateDriverMutation } from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, USER_STATUS } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { validateUserPassword, validateAdminProfileForm } from "../../../utils/validators";

const CreateDriver = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(USER_STATUS.ACTIVE);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const { loadingAdmin } = useSelector((state) => state.adminAuth);

  const [adminCreateDriver, { error, isSuccess, isLoading: creatingDriver }] = useAdminCreateDriverMutation(undefined, { refetchOnMountOrArgChange: true });
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Driver Created");
      navigate("/admin/drivers");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const newDriver = {
      name,
      email,
      phone,
      status,
      password,
      confirmPassword,
    };

    const { formIsValid, formErrors } = validateAdminProfileForm(newDriver);
    const { passwordIsValid, passwordErrors } = validateUserPassword(newDriver);

    if (formIsValid && passwordIsValid) {
      adminCreateDriver({ body: newDriver });
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  if (loadingAdmin) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"New Driver"} />
      <HeaderAdmin title={"New Driver"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={USER_STATUS.ACTIVE}>{String(USER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={USER_STATUS.INACTIVE}>{String(USER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                {/* Email */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="email_field" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />  
                </div>
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => {setPhone(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row">
                {/* Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="password_field" className="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password_field"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}         
                  />
                  <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                    Password must be at least 8 characters long
                  </p>
                </div>

                {/* Confirm Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}    
                  />
                </div>
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>

              <button disabled={creatingDriver} type="submit" className="btn update-btn w-100 py-2">
                Create
              </button>
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default CreateDriver;
