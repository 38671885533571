import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import {
  useAdminDeleteProductMutation,
  useAdminGetProductsQuery,
} from "../../../redux/api/productsAdminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import AdminLayout from "../../layout/AdminLayout";
import { ADMIN_MENU_ITEMS, PRODUCT_CATEGORIES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { CanCreateProduct, CanDeleteProduct } from "../AdminActionEligibilities";
import { CenterAlignedCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";

const ListProducts = () => {
  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  let [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const categoryName = category  ?  PRODUCT_CATEGORIES.find((cat) => cat.id === category)?.name : "All Products";
  const categoryOtherName = category  ? ` - ${PRODUCT_CATEGORIES.find((cat) => cat.id === category)?.otherName}`  : "";
  const params = category !== null ? { category } : {};
  const { data, isLoading, error, refetch  } = useAdminGetProductsQuery(params, { refetchOnMountOrArgChange: true });

  const [adminLogout] = useLazyAdminLogoutQuery();

  const [adminDeleteProduct, { isLoading: isDeleteLoading, error: deleteError, isSuccess },] = useAdminDeleteProductMutation();

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  
  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (isSuccess) {
      toast.success("Product Deleted");
    }
  }, [deleteError, isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }
  }, [error]);

  const deleteProductHandler = (id) => {
    adminDeleteProduct(id);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    if (category === "all") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", category);
    }
    refetch();
  };

  const setProducts = () => {
    const products = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"SKU"}> </CenterAlignedCell>,
          field: "sku",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    const canCreateProduct = CanCreateProduct(user);
    const canDeleteProduct = CanDeleteProduct(user);

    data?.products?.forEach((product, index) => {
      const otherName = product?.otherName ? ` - ${product?.otherName} `: "";
      products.rows.push({
        id: <CenterAlignedCell value={index + 1} > </CenterAlignedCell>,
        sku: <CenterAlignedCell value={product?.sku} > </CenterAlignedCell>,
        name: `${product?.name} ${otherName}`,
        status: product?.isActive ? "" : <span style={{ color: "red" }}>Inactive</span>,
        actions: (
          <center>
          {canCreateProduct && (
            <Link
              to={`/admin/products/${product?._id}`}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>          
          )}
          {canDeleteProduct && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => deleteProductHandler(product?._id)}
              disabled={isDeleteLoading}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
          </center>
        ),
      });
    });

    return products;
  };

  if (isLoading || loadingAdmin) return <Loader />;

  return (
    <>
    <MetaData title={"All Products"} />
    <HeaderAdmin title={`${categoryName} ${categoryOtherName}`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRODUCTS.name}>
        <div className="col-11 col-lg-11">
          <div className="row my-4">
            <div className="col-3 col-lg-3 my-0">
              <select className="form-select"
                aria-label="Select category"
                defaultValue="all"
                onChange={handleCategoryChange}
              >
                <option key = "all" value="all">All Products</option>
                {PRODUCT_CATEGORIES.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-center col-4 col-lg-4  offset-1">
              <h3 className="my-2">{data?.products?.length} Products</h3>
            </div>
            <div className="col-3 col-lg-3 offset-1 text-end">
              <Link to="/admin/products/new" className="btn btn-primary">
                Create New Product
              </Link>
            </div>
          </div>
          <MDBDataTable
            data={setProducts()}
            className="px-3"
            entriesOptions={[10, 20, 50, 100, 200, 500, 1000]}
            entries={1000}
            bordered
            striped
            hover
            noBottomColumns
          />
        </div>
      </AdminLayout>
    </>
  );
};

export default ListProducts;