import React, { useEffect, useState, useRef } from "react";
import SimpleBar from 'simplebar-react';
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import _ from "lodash";
import {
  useAdminGetInventoryQuery,
  useAdminUpdateInventoryMutation,
} from "../../../redux/api/inventoriesAdminApi";
import { useAdminUpdateOrdersByProductMutation, useAdminRemoveOrderItemByProductMutation } from "../../../redux/api/orderAdminApi";
import { useAdminUpdatePurchasesByProductMutation, useAdminRemovePurchaseItemByProductMutation } from "../../../redux/api/purchaseAdminApi";
import { useAdminGetAllVendorsQuery, useAdminGetAllCustomersQuery } from '../../../redux/api/adminApi';
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import AdminLayout from "../../layout/AdminLayout";
import { ADMIN_MENU_ITEMS, ORDER_STATUS, PURCHASE_STATUS } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { InventoryInOutBoundPopup } from "../../layout/Popup/InventoryInOutBoundPopup";
import { InventoryUpdatePopup } from "../../layout/Popup/InventoryUpdatePopup";
import { CanUserUpdateInProgressOrder, CanUserUpdateInProgressPurchase, CanUpdateInventory } from "../AdminActionEligibilities";

const ListInventories = () => {
  // Sort options
  const INVENTORY_COLUMNS = {
    NONE: 'None',
    ID: 'id',
    NAME: 'name',
    STOCK: 'stock',
    INBOUND: 'inbound',
    OUTBOUND: 'outbound',
    BALANCE: 'balance',
    STATUS: 'status',
    ACTIONS: 'actions',
  };
  const INVENTORY_SORT_ASC = {
    UP: 'Up',
    DOWN: 'Down',
  };
  const INVENTORY_ITEM_PER_PAGE = [10, 20, 50, 100, 1000];

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory: inventories } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct, inventoryDataOrdersByProductTimestamp } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct, inventoryDataPurchasesByProductTimestamp } = useSelector((state) => state.inProgressPurchases);

  const [originalDataTable, setOriginalDataTable] = useState(null);
  const [sortedAndFilteredDataRows, setSortedAndFilteredDataRows] = useState([]);
  const [inOutBoundPopup, setInOutBoundPopup] = useState({ data: [], visibility: 'hidden' });
  const [inventoryUpdatePopup, setInventoryUpdatePopup] = useState({ data: [], visibility: 'hidden' });
  const [selectedRowProduct, setSelectedRowProduct] = useState();
  const [selectedColumn, setSelectedColumn] = useState(INVENTORY_COLUMNS.NONE);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(INVENTORY_ITEM_PER_PAGE[INVENTORY_ITEM_PER_PAGE.length - 1]);

  const inventoryFilter = React.useRef(INVENTORY_COLUMNS.NONE);
  const inventoryFilterAsc = React.useRef(INVENTORY_SORT_ASC.UP);
  const searchText = React.useRef('');

  const defaultColumnSort = useRef(); // Declare defaultColumnSort as a ref
  const currentRowOrder = useRef(); // Declare currentRowOrder as a ref

  const dispatch = useDispatch();

  // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();
  // Vendor query
  const { data: vendorsData, isLoading: isLoadingVendor } = useAdminGetAllVendorsQuery();
  // Customer query
  const { data: customersData, isLoading: isLoadingCustomer } = useAdminGetAllCustomersQuery();  

  // Update queries
  const [adminUpdateOrdersByProductMutation, { data: ordersUpdatedData, isLoading: isUpdatingOrders, error: ordersUpdateError }] = useAdminUpdateOrdersByProductMutation();
  const [adminUpdatePurchasesByProductMutation, { data: purchasesUpdatedData, isLoading: isUpdatingPurchases, error: purchasesUpdateError }] = useAdminUpdatePurchasesByProductMutation();
  const [adminUpdateInventoryMutation, { isLoading: isUpdatingInventories, error: inventoryUpdateError }] = useAdminUpdateInventoryMutation();

  // Remove item queries
  const [adminRemoveOrderItemByProductMutation, { isLoading: isRemovingOrderItem, error: orderItemRemoveError }] = useAdminRemoveOrderItemByProductMutation();
  const [adminRemovePurchaseItemByProductMutation, { isLoading: isRemovingPurchaseItem, error: purchaseItemRemoveError }] = useAdminRemovePurchaseItemByProductMutation();

  // Log out query
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
    defaultColumnSort.current = {name: INVENTORY_SORT_ASC.UP, stock: INVENTORY_SORT_ASC.UP, inbound: INVENTORY_SORT_ASC.UP, outbound: INVENTORY_SORT_ASC.UP, balance: INVENTORY_SORT_ASC.UP};
    currentRowOrder.current = {};
  }, []);

  useEffect(() => {
    if (ordersUpdateError) {
      toast.error(ordersUpdateError?.data?.message);
      if (ordersUpdateError?.status === 401) {
        adminLogout();
      }
    }
    if (purchasesUpdateError) {
      toast.error(purchasesUpdateError?.data?.message);
      if (purchasesUpdateError?.status === 401) {
        adminLogout();
      }
    }
    if (inventoryUpdateError) {
      toast.error(inventoryUpdateError?.data?.message);
      if (inventoryUpdateError?.status === 401) {
        adminLogout();
      }
    }
  }, [ordersUpdateError, purchasesUpdateError, inventoryUpdateError]);

  useEffect(() => {
    if (orderItemRemoveError) {
      toast.error(orderItemRemoveError?.data?.message);
      if (orderItemRemoveError?.status === 401) {
        adminLogout();
      }
    }
    if (purchaseItemRemoveError) {
      toast.error(purchaseItemRemoveError?.data?.message);
      if (purchaseItemRemoveError?.status === 401) {
        adminLogout();
      }
    }
  }, [ordersUpdateError, purchaseItemRemoveError]);
  
  useEffect(() => {
    if (originalDataTable) {
      resortTable();
    }
  }, [originalDataTable]);
  

  function resortTable() {
    if (originalDataTable) {
      const sortedInventoryRows = sortInventoriesWithPreviousOrder(originalDataTable.rows);
      const sortedAndFilteredRows = filterData(searchText.current, sortedInventoryRows);
      setSortedAndFilteredDataRows(sortedAndFilteredRows);
    }
  }

  function sortTable() {
    if (originalDataTable) {
      const sortedInventoryRows = sortInventories(originalDataTable.rows, inventoryFilter.current, inventoryFilterAsc.current);
      const sortedAndFilteredRows = filterData(searchText.current, sortedInventoryRows);
      setSortedAndFilteredDataRows(sortedAndFilteredRows);
    }
  }

  useEffect(() => {
    if (inventories && inventoryDataOrdersByProduct && inventoryDataPurchasesByProduct) {
      const dataTable = setInventories();
      // Store originalDataTable
      setOriginalDataTable(dataTable);
    }
  }, [inventories, inventoryDataOrdersByProduct, inventoryDataPurchasesByProduct]);

  const adjustColumnWidths = () => {
    const headerTable = document.getElementById('inventoryTableHeader');
    const bodyTable = document.getElementById('inventoryTableEntries');

    if (bodyTable && headerTable) {
      const bodyColumns = bodyTable.rows[0].cells;
      const headerColumns = headerTable.rows[0].cells;

      for (let i = 0; i < headerColumns.length; i++) {
        const headerColumnWidth = headerColumns[i].offsetWidth;
        bodyColumns[i].style.width = `${headerColumnWidth}px`; 
      }
    }
  };

  // Add table resize listener
  useEffect(() => {
    adjustColumnWidths();

    // Optional: Adjust column widths on window resize
    window.addEventListener('resize', adjustColumnWidths);

    // Cleanup
    return () => {
      window.removeEventListener('resize', adjustColumnWidths);
    };
  },[sortedAndFilteredDataRows, user]);
  
  const canUpdateOrders = CanUserUpdateInProgressOrder(user);
  const canUpdatePurchases = CanUserUpdateInProgressPurchase(user);
  const canUpdateInventories  = CanUpdateInventory(user);

  const displayInOutBoundPopup = ({column, title, productId, data}) => {
    if (inOutBoundPopup.visibility === 'hidden' || inOutBoundPopup.id !== productId) {
      setInOutBoundPopup({column, productId, title, data, visibility: "visible" });
    }
  }

  const displayInventoryUpdatePopup = ({productId, productName }) => {
    if (inventoryUpdatePopup.visibility === 'hidden' || inventoryUpdatePopup.id !== productId) {
      setInventoryUpdatePopup({productId, title: productName, visibility: "visible" });
    }
  }

  const handleColumnSelect = (field) => { 
    // Disable touch if inOutBoundPopup is visible
    if (inOutBoundPopup.visibility === 'visible')
      return;

    if (selectedColumn === field) {
      setSelectedColumn(INVENTORY_COLUMNS.NONE);
    } else {
      setSelectedColumn(field);
    }
  }

  const handleArrowSortSelect = (field) => {
    if (defaultColumnSort.current[field] === INVENTORY_SORT_ASC.UP) {
      defaultColumnSort.current[field] = INVENTORY_SORT_ASC.DOWN;
    } else {
      defaultColumnSort.current[field] = INVENTORY_SORT_ASC.UP;
    }
    inventoryFilter.current = field;
    inventoryFilterAsc.current = defaultColumnSort.current[field];
    sortTable();
  }

  const handleRowSelect = (productId, productName, field) => {
    // Disable touch if inOutBoundPopup is visible or row is not yet selected
    if (inOutBoundPopup.visibility === 'visible')
      return;

    if (productId === selectedRowProduct) {
      if (field === INVENTORY_COLUMNS.OUTBOUND) {
        const outboundTitle = `Outbound - ${productName}`;
        displayInOutBoundPopup({column: field, title: outboundTitle, productId });
      }
      else if (field === INVENTORY_COLUMNS.INBOUND) {
        const inboundTitle = `Inbound - ${productName}`;
        displayInOutBoundPopup({column: field, title: inboundTitle, productId });
      }
    } else {
      setSelectedRowProduct(productId);
    }
  }

  const handleSearchTextChange = (value) => {
    searchText.current = value;
    resortTable();
  }

  // Search and filter by inventory name (search field)    
  function filterData(_searchText, dataRows) {
    if (!dataRows || !_searchText || _searchText === '') 
      return dataRows;
    const filteredRows = dataRows.filter(row => {
      // Check if the name in the row matches the search text
      return String(row.name).toLowerCase().includes(_searchText.toLowerCase());
    });

    // Update the data table with the filtered rows
    return filteredRows;
  }

  const sortInventories = (inventoryRows, filter, filterAsc) => {
    let sortedRows = [...inventoryRows]
    switch (filter) {
      case INVENTORY_COLUMNS.NAME:
        if (filterAsc === INVENTORY_SORT_ASC.UP) {
          sortedRows.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          sortedRows.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case INVENTORY_COLUMNS.STOCK:
        if (filterAsc === INVENTORY_SORT_ASC.UP) {
          sortedRows.sort((a, b) => a.stock - b.stock);
        } else {
          sortedRows.sort((a, b) => b.stock - a.stock);
        }
        break;
      case INVENTORY_COLUMNS.INBOUND:
        if (filterAsc === INVENTORY_SORT_ASC.UP) {
          sortedRows.sort((a, b) => a.inbound - b.inbound);
        } else {
          sortedRows.sort((a, b) => b.inbound - a.inbound);
        }
        break;
      case INVENTORY_COLUMNS.OUTBOUND:
        if (filterAsc === INVENTORY_SORT_ASC.UP) {
          sortedRows.sort((a, b) =>a.outbound - b.outbound);
        } else {
          sortedRows.sort((a, b) =>b.outbound - a.outbound);
        }
        break;
      case INVENTORY_COLUMNS.BALANCE:
        if (filterAsc === INVENTORY_SORT_ASC.UP) {
          sortedRows.sort((a, b) => a.balance - b.balance);
        } else {
          sortedRows.sort((a, b) => b.balance - a.balance);
        }
        break;
      default:
        break;
    }
    // Add id to each row as sequence number - remember current order so that when we refetch, the same order is kept
    currentRowOrder.current = {};
    sortedRows.forEach((row, index) => {
      row.id = index + 1;
      currentRowOrder.current[row.productId] = index;
    });
    return sortedRows;
  };

  const sortInventoriesWithPreviousOrder = (inventoryRows) => {
    let sortedRows = [...inventoryRows];
    if (currentRowOrder.current && !_.isEqual(currentRowOrder.current, {})) {
      sortedRows.sort((a, b) => (currentRowOrder.current[a.productId] !== undefined ? currentRowOrder.current[a.productId] :  Number.MAX_SAFE_INTEGER) - (currentRowOrder.current[b.productId] !== undefined ? currentRowOrder.current[b.productId] : Number.MAX_SAFE_INTEGER));
    }
    return sortedRows;
  }

  // Populate the data table
  const setInventories = () => {
    const inventoryTable = {
      columns: [
        {
          label: <CenterAlignedCell value={""}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.ID,
          align: 'text-center'
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.NAME,
          align: 'text-start'
        },
        {
          label: <CenterAlignedCell value={"Stock"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.STOCK,
          align: 'text-end'
        },
        {
          label: <CenterAlignedCell value={"Outbound"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.OUTBOUND,
          align: 'text-end'
        },
        {
          label: <CenterAlignedCell value={"Inbound"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.INBOUND,
          align: 'text-end'
        },
        {
          label: <CenterAlignedCell value={"Balance"} > </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.BALANCE,
          align: 'text-end'
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.STATUS,
          align: 'text-end'
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: INVENTORY_COLUMNS.ACTIONS,
          align: 'text-center'
        },
      ],
      rows: [],
    };

    let index = 0;
    for (let [productId, inventory] of Object.entries(inventories)) {
      const product = inventory?.product;
      const otherName = product?.otherName ? ` - ${product?.otherName} `: "";
      const incommingQuantity = (product && inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId]?.inProgressTotal : 0);
      const outcommingQuantity = (product && inventoryDataOrdersByProduct &&  inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId]?.inProgressTotal : 0);
      const balance = inventory?.quantity + incommingQuantity - outcommingQuantity;
      const activeString = inventory?.active ? "" : "Inactive";
      inventoryTable.rows.push({
        id: index++,
        productId: product._id,
        name: `${product?.name} ${otherName}`,
        stock:  inventory?.quantity,
        inbound:  incommingQuantity,
        outbound:  outcommingQuantity,
        balance:  balance,
        status: <span style={{ color: "red" }}>{activeString}</span>,
        actions: (
          <div id={`${INVENTORY_COLUMNS.ACTIONS}_${productId}`} className='inventoryTableCell'>
            <center>
            {canUpdateInventories && (
              <i className="fa fa-pencil" onClick={() => displayInventoryUpdatePopup({productId: product._id, productName: product.name })} />
            )}
            </center>
          </div>
        ),
      });
    }
    return inventoryTable;
  }

  // --------------------------------------------------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  //if (isLoadingInventory || isLoadingVendor || isLoadingCustomer || loadingAdmin || loadingInventoryDataOrdersByProduct || loadingInventoryDataPurchasesByProduct || user === null || inventories === null) {
  if (user === null) {
    return <Loader />;
  }
  // --------------------------------------------------------------------------
  
  const firstItemIndex = (currentPage - 1) * itemPerPage;
  const lastItemIndex = firstItemIndex + itemPerPage;

  return (
    <>
    <MetaData title={"All Inventories"} />
    <HeaderAdmin title={`All Inventories`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.INVENTORY.name}>
        <div className="col-12 col-lg-12" style={{padding: '20px'}} >
          <div className="row my-3">
            <div className="col-2 col-lg-2">
              <select className="form-select"
                  aria-label="Items per page"
                  defaultValue={INVENTORY_ITEM_PER_PAGE[INVENTORY_ITEM_PER_PAGE.length - 1]}
                  onChange={(e)=>setItemPerPage(Number(e.target.value))}
                >
                {INVENTORY_ITEM_PER_PAGE.map((count) => (
                  <option key={count} value={count}>
                    {count}
                  </option>
                ))}
              </select>  
            </div>
            <div className="col-8 d-flex  justify-content-center">
              {sortedAndFilteredDataRows.length > INVENTORY_ITEM_PER_PAGE[0] && (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={itemPerPage}
                  totalItemsCount={sortedAndFilteredDataRows.length}
                  onChange={setCurrentPage}
                  nextPageText={"Next"}
                  prevPageText={"Prev"}
                  firstPageText={"First"}
                  lastPageText={"Last"}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
            </div>            
            <div className="col-2 col-lg-2">
              <input
                type="text"
                value={searchText.current}
                onChange={e => handleSearchTextChange(e.target.value)}
                placeholder="Search..."
                className="form-control"
              />
            </div>
          </div>
          {/* Table header */}
          { originalDataTable && (
            <div style={{width: `calc(100% - 10px)`}}>
              <table id="inventoryTableHeader" className="table table-bordered myMDBDataListInventoryTableRow">
                <thead>
                  <tr>
                    {originalDataTable.columns.map((column, index) => (
                      <th key={index}>
                        {defaultColumnSort.current[column.field] && selectedColumn === column.field && (
                          <div className='row unselectable'>
                            <div  className="text-center unselectable" style={{width: '70%' }} onClick={()=>{handleColumnSelect(column.field)}}>
                              {column.label}
                            </div>
                            <div  className="text-center" style={{width: '30%' }}  onClick={()=>{if (selectedColumn === column.field) {handleArrowSortSelect(column.field)} else {handleColumnSelect(column.field)}}}>
                              {selectedColumn === column.field && (
                                <span  >
                                  {inventoryFilterAsc.current === INVENTORY_SORT_ASC.UP ? (
                                    <i className="fa fa-arrow-up"  style={{ color: 'blue' }}></i>
                                  ) : (
                                    <i className="fa fa-arrow-down"  style={{ color: 'green' }}></i>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {defaultColumnSort.current[column.field] && selectedColumn !== column.field && (
                          <div className="text-center unselectable" onClick={()=>{handleColumnSelect(column.field)}}>
                            {column.label}
                          </div>
                        )}
                        {!defaultColumnSort.current[column.field] && (
                          <div className='row unselectable'>
                            {column.label}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>)
          }
          
          {/* Entries */}
          { sortedAndFilteredDataRows && (
            <SimpleBar style={{ maxHeight: '60vh' }}>
              <div className="col-12" style={{ height: '60vh', overflowX: 'auto'}}>
                <table id="inventoryTableEntries" className="table-bordered">
                  <tbody>
                    {sortedAndFilteredDataRows.slice(firstItemIndex, lastItemIndex).map((row, index) => (
                      <tr key={index}>
                        {originalDataTable.columns.map((column, i) => (
                          <td className={`${column.align}`} key={i} style={{backgroundColor: selectedRowProduct === row.productId ? '#90EE90' : (selectedColumn === column.field ? 'yellow' : '')}}  onClick={()=>{handleRowSelect(row.productId, row.name, column.field)}}>
                            {row[column.field]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
          )}
        </div> 
        {inOutBoundPopup && inOutBoundPopup.visibility === 'visible' && (
          <InventoryInOutBoundPopup 
            product={inventories[inOutBoundPopup.productId]?.product}
            title={inOutBoundPopup.title} 
            visibility={inOutBoundPopup.visibility}
            user={user}
            vendors={vendorsData}
            customers={customersData}
            dataTimestamp = {inOutBoundPopup.column === INVENTORY_COLUMNS.OUTBOUND ? inventoryDataOrdersByProductTimestamp : inventoryDataPurchasesByProductTimestamp}
            data={inOutBoundPopup.column === INVENTORY_COLUMNS.OUTBOUND ?  
                 ((inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[inOutBoundPopup.productId]?.orders) ? inventoryDataOrdersByProduct[inOutBoundPopup.productId]?.orders.filter((order) => order.status !== ORDER_STATUS.DELIVERED) : []) : 
                 ((inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[inOutBoundPopup.productId]?.purchases) ? inventoryDataPurchasesByProduct[inOutBoundPopup.productId]?.purchases.filter((purchase) => purchase.status !== PURCHASE_STATUS.DELIVERED) : [])}
            column={inOutBoundPopup.column}
            onClose={() => {setInOutBoundPopup({ ...inOutBoundPopup, visibility: 'hidden' });}}
            updateFunction={(inOutBoundPopup.column === INVENTORY_COLUMNS.OUTBOUND 
                              ? (canUpdateOrders    ? { update: adminUpdateOrdersByProductMutation, updating: isUpdatingOrders       } : null)
                              : (canUpdatePurchases ? {update: adminUpdatePurchasesByProductMutation, updating: isUpdatingPurchases  } : null))}
            removeItemFunction={(inOutBoundPopup.column === INVENTORY_COLUMNS.OUTBOUND 
                              ? (canUpdateOrders    ? { remove: adminRemoveOrderItemByProductMutation, removing: isRemovingOrderItem      } : null)
                              : (canUpdatePurchases ? { remove: adminRemovePurchaseItemByProductMutation, removing: isRemovingPurchaseItem} : null))}
          />
        )}
        {inventoryUpdatePopup && inventoryUpdatePopup.visibility === 'visible' && (
          <InventoryUpdatePopup 
            product={inventories[inventoryUpdatePopup.productId]?.product}
            visibility={inventoryUpdatePopup.visibility}
            title={inventoryUpdatePopup.title}
            data={{quantity: inventories?.[inventoryUpdatePopup.productId]?.quantity, active: inventories?.[inventoryUpdatePopup.productId]?.active}}
            onClose={() => {setInventoryUpdatePopup({ ...inventoryUpdatePopup, visibility: 'hidden' })}}
            updateFunction={{update: adminUpdateInventoryMutation, updating: isUpdatingInventories }}
          />
        )}
      </AdminLayout>
    </>
  );
};

export default ListInventories;