import { ORDER_USER_RIGHTS, ORDER_STATUS, USER_ROLES, PURCHASE_STATUS, PURCHASE_USER_RIGHTS } from '../../constants/constants.js';

//------------------- ORDER -------------------
export const DetermineUserOrderRights = (user, orderStatus) => {
  switch (orderStatus) {
    case ORDER_STATUS.PENDING:
        if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE) {
          return {rights: [ORDER_USER_RIGHTS.CONFIRM, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
        }
        break;
    case ORDER_STATUS.CONFIRMED:
        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.PACK, ORDER_USER_RIGHTS.UPDATE,ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.ADD]};
        }
        if (user.role === USER_ROLES.SALE) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.ADD]};
        }
        if (user.role === USER_ROLES.ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.ADD]};
        }
        if (user.role === USER_ROLES.WAREHOUSE) {
            return {rights: [ORDER_USER_RIGHTS.PACK]};
        }
        break;
    case ORDER_STATUS.PACKING:
        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.FINISH_PACKING]};
        }
        if (user.role === USER_ROLES.ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.FINISH_PACKING]};
        }
        if (user.role === USER_ROLES.WAREHOUSE) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.FINISH_PACKING]};
        }
        if (user.role === USER_ROLES.SUPER_ADMIN ) {
            return {rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.FINISH_PACKING]};
        }
        break;
    case ORDER_STATUS.DELIVERY_READY:
        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return { rights: [ORDER_USER_RIGHTS.DELIVER, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE]};
        }
        if (user.role === USER_ROLES.ADMIN) {
            return { rights: [ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE]};
        }
        if (user.role === USER_ROLES.WAREHOUSE) {
            return {rights: [ORDER_USER_RIGHTS.DELIVER, ORDER_USER_RIGHTS.UPDATE, ORDER_USER_RIGHTS.ADD]};
        }
        break;
    case ORDER_STATUS.DELIVERED:
        if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN) {
            return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS, ORDER_USER_RIGHTS.ADD, ORDER_USER_RIGHTS.DELETE, ORDER_USER_RIGHTS.UPDATE]};
        }
        if (user.role === USER_ROLES.ACCOUNTANT) {
            return {rights: [ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS]};
        }
        break;
    default:
      return {rights: []};
  }
  return {rights: []};
}

export const CanUserUpdateInProgressOrder = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUserCreateOrder = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUserViewOrder = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.ACCOUNTANT || user.role === USER_ROLES.WAREHOUSE;
}
//------------------- PURCHASE -------------------
export const DetermineUserPurchaseRights = (user, purchaseStatus) => {
    switch (purchaseStatus) {
      case PURCHASE_STATUS.PENDING:
          if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE) {
            return {rights: [PURCHASE_USER_RIGHTS.ORDER, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
          }
          break;
    case PURCHASE_STATUS.ORDERED:
        if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE) {
            return {rights: [PURCHASE_USER_RIGHTS.CONFIRM, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD]};
        }
        break;
    case PURCHASE_STATUS.CONFIRMED:
        if (user.role === USER_ROLES.SUPER_ADMIN) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
        }
        if (user.role === USER_ROLES.ADMIN) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
        }
        if (user.role === USER_ROLES.SALE) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD]};
        }
        if (user.role === USER_ROLES.WAREHOUSE) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.ADD, PURCHASE_USER_RIGHTS.RECEIVE]};
        }
        break;
    case PURCHASE_STATUS.DELIVERED:
        if (user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.WAREHOUSE || user.role === USER_ROLES.ADMIN) {
            return {rights: [PURCHASE_USER_RIGHTS.UPDATE, PURCHASE_USER_RIGHTS.DELETE, PURCHASE_USER_RIGHTS.ADD]};
        }
        break;
    default:
        return {rights: []};
    }
    return {rights: []};
  }

export const CanUserUpdateInProgressPurchase = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUserCreatePurchase = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUserViewPurchase = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.ACCOUNTANT || user.role === USER_ROLES.WAREHOUSE;
}
//------------------- CUSTOMER -------------------
export const CanUserCreateCustomer = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanUpdateCustomer = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanDeleteCustomer = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}
//------------------- VENDOR -------------------
export const CanUserCreateVendor = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanUserUpdateVendor = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanDeleteVendor = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUserUpdatePriceList = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

//------------------- USER -------------------
export const CanCreateUser = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUpdateUser = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanDeleteUser = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}
//------------------- DRIVER -------------------
export const CanCreateDriver = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanUpdateDriver = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}

export const CanDeleteDriver = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;
}


//------------------- INVENTORY -------------------
export const CanCreateInventory = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanDeleteInventory = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}

export const CanUpdateInventory = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.WAREHOUSE;
}
//------------------- PRODUCT -------------------
export const CanCreateProduct = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE || user.role === USER_ROLES.WAREHOUSE;
}

export const CanUpdateProduct = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SALE;
}

export const CanDeleteProduct = (user) => {
    return user.role === USER_ROLES.SUPER_ADMIN;
}