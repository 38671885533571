// Validate all admin form inputs
export const validateAdminProfileForm = (user) => {
    const { name, phone, email } = user;

    let formIsValid = true;
    const formErrors = {};

    // Validate name
    if (name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate phone
    if (phone.trim() === "") {
        formErrors.phone = "Phone Number is required";
        formIsValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
        formErrors.phone = "Invalid phone number";
        formIsValid = false;
    }

    // Validate email
    if (email.trim() === "") {
        formErrors.email = "Email is required";
        formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        formErrors.email = "Invalid email address";
        formIsValid = false;
    }

    return { formIsValid, formErrors };
};

// Validate all customer form inputs
export const validateCustomerProfileForm = (user) => {
    const { name, extraId, addressInfo, email } = user;

    let formIsValid = true;
    const formErrors = {};

    // Validate extraId
    if (user.extraId.trim() === "") {
        formErrors.extraId = "Quickbooks ID is required";
        formIsValid = false;
    }

    // Validate name
    if (name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate extraId
    if (extraId.trim() === "") {
        formErrors.name = "Extra ID is required";
        formIsValid = false;
    }

    // Validate address
    if (addressInfo.address.trim() === "") {
        formErrors.address = "Address is required";
        formIsValid = false;
    }

    // Validate city
    if (addressInfo.city.trim() === "") {
        formErrors.city = "City is required";
        formIsValid = false;
    }

    // Validate phone
    if (addressInfo.phone.trim() === "") {
        formErrors.phone = "Phone Number is required";
        formIsValid = false;
    } else if (!/^\d{10}$/.test(addressInfo.phone)) {
        formErrors.phone = "Invalid phone number";
        formIsValid = false;
    }

    // Validate zip code
    if (addressInfo.zipCode.trim() === "") {
        formErrors.zipCode = "Zip Code is required";
        formIsValid = false;
    } else if (!/^\d{5}$/.test(addressInfo.zipCode)) {
        formErrors.zipCode = "Invalid zip code";
        formIsValid = false;
    }

    // Validate email
    if (email.trim() === "") {
        formErrors.email = "Email is required";
        formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        formErrors.email = "Invalid email address";
        formIsValid = false;
    }

    return { formIsValid, formErrors };
};

export const validateUserPassword = (user) => {
    let passwordIsValid = true;
    const passwordErrors = {};
    const { password, confirmPassword } = user;

    // Validate password
    if (password.trim() === "") {
        passwordErrors.password = "Password is required";
        passwordIsValid = false;
    } else if (password.length < 8) {
        passwordErrors.password = "Password must be at least 8 characters long";
        passwordIsValid = false;
    }
    
    // Validate confirm password
    if (confirmPassword.trim() === "") {
        passwordErrors.confirmPassword = "Confirm Password is required";
        passwordIsValid = false;
    } else if (password !== confirmPassword) {
        passwordErrors.confirmPassword = "Passwords do not match";
        passwordIsValid = false;
    }

    return { passwordIsValid, passwordErrors };
}

// Validate all vendor form inputs
export const validateVendorProfileForm = (vendor) => {
    const { name, phone } = vendor;

    let formIsValid = true;
    const formErrors = {};

    // Validate name
    if (name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate phone
    if (phone.trim() === "") {
        formErrors.phone = "Phone Number is required";
        formIsValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
        formErrors.phone = "Invalid phone number";
        formIsValid = false;
    }

    return { formIsValid, formErrors };
};

export const valueIsANumber = (value) => {
    return value && !isNaN(value) && value !== '' && !String(value).includes('e');
};

export const valueIsANonNegativeNumber = (value) => {
    return value && !isNaN(value) && value !== '' && !String(value).includes('e') && value >= 0;
};