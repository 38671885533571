import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSellPrice } from "../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, setCartItem } from "../../redux/features/cartSlice";
import { toast } from "react-toastify";
import { update } from "lodash";

const ProductItem = ({ product, customer, columnSize }) => {
    const { cartItems } = useSelector((state) => state.cart);

    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(0);
    const [cartQuantity, setCartQuantity] = useState(0);
    const { price, dollars, cents } = getSellPrice(product, customer.tier);

    useEffect(() => {
        //console.log('cartItems', cartItems);
        if (cartItems) {
            const cartItem = cartItems.find((item) => item.product === product._id);
            if (cartItem) {
                setQuantity(cartItem.finalQuantity);
                setCartQuantity(cartItem.finalQuantity);
            } else {
                setQuantity(0);
                setCartQuantity(0);
            }
        }
    }, [cartItems, product._id]);

    const productImage = product?.images?.length > 0 ? product.images[0].url : "/images/default_product.png";
    const increseQty = () => {
        const qty = (quantity ? quantity : 0) + 1;
        setQuantity(qty);
    };
    
    const decreseQty = () => {
        const qty = (quantity ? quantity : 0) - 1;
        if (qty <= 0) return;
        setQuantity(qty);
    };

    const setItemToCart = () => {
        const cartItem = {
          product: product?._id,
          name: product?.name,
          finalPrice: price,
          image: product?.images[0]?.url,
          stock: product?.stock,
          palletSize: product?.palletSize,
          finalQuantity: quantity,
        };
    
        dispatch(setCartItem(cartItem));
        //toast.success("Item added to Cart");
    };

    const updateCartHandler = () => {
        if (quantity === 0) {
            dispatch(removeCartItem(product?._id));
        } else {
            setItemToCart();
        }
    }

    return (
        <div className={`col-sm-9 col-md-6 col-lg-4 my-3`}>
            <div className="card p-3 rounded">
                <Link to={`/products/${product?._id}`} style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        className="card-img-top mx-auto"
                        src={productImage}
                        alt=""
                    />
                </Link>
                <div className="card-body ps-3 d-flex justify-content-center flex-column">
                    <h5 className="card-title text-center">
                        <Link to={`/products/${product?._id}`}>{product?.name} {product?.otherName ? ` - ${product.otherName}` : ''}</Link>
                    </h5>
                    {/*}
                    <div className="ratings mt-auto d-flex">
                    <div className="star-ratings">
                        <i className="fa fa-star star-active"></i>
                        <i className="fa fa-star star-active"></i>
                        <i className="fa fa-star star-active"></i>
                        <i className="fa fa-star star-active"></i>
                        <i className="fa fa-star star-active"></i>
                    </div>
                    <span id="no_of_reviews" className="pt-2 ps-2"> (0) </span>
                    </div>
                    */}
                    <div className="text-center">
                        {price !== undefined ? (
                            <p id="card-text mt-2">
                                <span className="dollars">${dollars}</span>
                                <span className="cents">.{cents}</span>
                            </p>
                        ) : (
                            <p id="product_price">
                                <span>Call</span>
                            </p>              
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            type="button"
                            id="cart_btn"
                            className="btn btn-primary d-inline"
                            onClick={updateCartHandler}
                            disabled={quantity === cartQuantity}
                        >
                            {cartQuantity === 0 ? 'Add to Cart' : 'Update Cart'}
                        </button>
                    </div>
                    <div className="stockCounter d-inline text-center">
                        <span className="btn btn-danger minus" onClick={decreseQty}>
                            -
                        </span>
                        <input
                            type="number"
                            className="form-control count d-inline"
                            style={{ width: '4vw' , marginLeft: '10px', marginRight: '10px'}}
                            value={quantity}
                            readonly
                        />
                        <span className="btn btn-primary plus" onClick={increseQty}>
                            +
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
