import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import { useAdminGetPriceListQuery, useAdminUpdatePriceListMutation, useAdminRemoveAPriceListItemMutation } from "../../../redux/api/priceListAdminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { useAdminGetAllVendorsQuery } from "../../../redux/api/adminApi";
import { useAdminGetProductsQuery } from "../../../redux/api/productsAdminApi";
import AdminLayout from "../../layout/AdminLayout";
import { ADMIN_MENU_ITEMS } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ButtonCell, CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { CanUserUpdatePriceList } from "../AdminActionEligibilities";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import { ProductSelection } from "../../product/ProductSelection";
import { ConfirmDialog } from "../ConfirmDialog";
import { PriceListGeneratePopup } from "../../layout/Popup/PriceListGeneratePopup";

const PriceList = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  //let [searchParams] = useSearchParams();
  const { data: priceListData, isLoading: loadingPriceListData, error: errorLoadingPriceListData  } = useAdminGetPriceListQuery(vendorId);
  const [adminUpdatePriceListMutation, { isLoading: updatingPriceList, error: errorUpdatingPriceList  }] = useAdminUpdatePriceListMutation();
  const [adminRemoveAPriceListItemMutation, { isLoading: removingAPriceListItem, error: errorRemovingAPriceListItem  }] = useAdminRemoveAPriceListItemMutation();
  const { data: vendorData, isLoading: loadingVendorData, error: errorLoadingVendorData } = useAdminGetAllVendorsQuery();
  const { data: products, isLoading: isLoadingProducts, error: errorLoadingProducts  } = useAdminGetProductsQuery(null, { refetchOnMountOrArgChange: true });
  const [adminLogout] = useLazyAdminLogoutQuery();

  const [productHash, setProductHash] = useState({});
  const [reloadingPriceList, setReloadingPriceList] = useState(true);
  let [clonedPriceListItems, setClonedPriceListItems] = useState([]);
  const [formUpdated, setFormUpdated] = useState(false);
  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [priceGenerateToolVisibilty, setPriceGenerateToolVisibilty] = useState('hidden');
  const [vendorName, setVendorname] = useState("");

  useEffect(() => {
    if (errorLoadingPriceListData) {
      toast.error(errorLoadingPriceListData?.data?.message);
      if (errorLoadingPriceListData?.status === 401) {
        adminLogout();
      }
    }

    if (errorLoadingVendorData) {
      toast.error(errorLoadingVendorData?.data?.message);
    }

    if (errorLoadingProducts) {
      toast.error(errorLoadingProducts?.data?.message);
    }
  }, [errorLoadingPriceListData, errorLoadingVendorData, errorLoadingProducts]);

  useEffect(() => {
    setReloadingPriceList(false);
    if (priceListData) {
      setClonedPriceListItems(JSON.parse(JSON.stringify(priceListData)));
    }
  }, [priceListData]);

  useEffect(() => {
    if (products) {
      const productHash = products.products?.reduce((hash, product) => {
        //const otherName = product?.otherName ? ` - ${product?.otherName} `: "";
        hash[product._id] = { product };
        return hash;
      }, {});
      setProductHash(productHash);
    }
  }, [products]);

  useEffect(() => {
    if (vendorData) {
      const vendor = vendorData.find(vendor => vendor._id === vendorId);
      if (vendor) {
        setVendorname(vendor.name);
      }
    }
  }, [vendorData, vendorId]);

  // Clone the products object
  let filteredProductHash = { ...productHash };
  // Remove entries that are already in clonedOrderItems
  // Only allow adding new items 
  clonedPriceListItems.forEach(item => {
    if (item.product in productHash) {
      delete filteredProductHash[item.product];
    }
  });

  const handleVendorChange = (e) => {
    setReloadingPriceList(true);
    const vendorId = e.target.value;
    navigate(`/admin/vendors/${vendorId}/pricelist/`, { replace: true });
  };

  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedPriceListItems[index] !== value) {
      clonedPriceListItems[index].price = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  const handleUpdateAPriceListItem = (index) => {
    adminUpdatePriceListMutation({
      vendorId,
      body: {
        priceList: [{ product: clonedPriceListItems[index].product, price: clonedPriceListItems[index].price }],
      },
    });
  };

  const handleRemoveItem = (index) => {
    if (clonedPriceListItems[index].product) {
      showRemoveItemConfirmDialog(index);
    } else {
      // If the item is new, remove it directly
      confirmRemovingItem(index);
    }
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      if (index < priceListData.length) {
        adminRemoveAPriceListItemMutation({
          vendorId,
          productId: priceListData[index].product,
        });
      }
      clonedPriceListItems.splice(index, 1);
      setFormUpdated(!formUpdated);
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Add item row
  const addItemRowHandler = () => {
    clonedPriceListItems.push({});
    setClonedPriceListItems(clonedPriceListItems);
    setFormUpdated(!formUpdated);
  };

  const handleImportPriceList = () => {
    setPriceGenerateToolVisibilty('visible');
    /*
    adminUpdatePriceListMutation({
      vendorId, 
      body: { 
        priceList: [
          { product: "6678e6f64a5aadc9995a404a", price: 100 },
          { product: "6678e71f4a5aadc9995a4055", price: 11 },
          { product: "6678e7534a5aadc9995a4060", price: 12 },
          { product: "6678e71f4a5aadc9995a4055", price: 13 },
          { product: "6678e7884a5aadc9995a406b", price: 14 },
          { product: "6678e7d64a5aadc9995a4076", price: 150 },
        ]},
    });
    */
  }
  const editable = CanUserUpdatePriceList(user);

  const setProducts = () => {
    const products = {
      columns: [
        {
          label: <CenterAlignedCell value={""}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
        },
      ],
      rows: [],
    };

    if (editable) {
      products.columns.push({
        label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
        field: "actions",
        sort: "asc",
      });
    }

    clonedPriceListItems?.forEach((item, index) => {
      const matchingIndex = priceListData.findIndex(originalItem => originalItem.product === item.product);
      const price = item.price;
      let priceColor = "black";
      if(matchingIndex < 0 || (priceListData && priceListData[index] && item.price !== priceListData[index].price)) {
        priceColor = "red";
      }
      const disableSaveButton = priceColor === "black" || !price || updatingPriceList || removingAPriceListItem;
      const disableRemoveButton = updatingPriceList || removingAPriceListItem;
      const otherName = (item.product && productHash[item.product]?.product.otherName) ? ` - ${productHash[item.product]?.product.otherName} `: "";
      const name = item.product ? `${productHash[item.product]?.product.name}${otherName}` : "";
      products.rows.push({
        id: index + 1,
        name: matchingIndex >= 0 
          ? name
          : ProductSelection({ productList: filteredProductHash, 
                            defaultItem: { ...item, sku: productHash[item.product]?.product.sku }, 
                            onChange: (product) => {
                              Object.assign(clonedPriceListItems[index], {
                                product: product._id,
                                name: product.name,
                              });
                              setFormUpdated(!formUpdated);
                            }
                          }),
        price: editable 
                ? (<EditableNumberCell readOnly={!editable} value={item.price} onChange={(val) => {handlePriceChange(index, val)}} color={priceColor}> </EditableNumberCell>)
                : (<RightAlignedCell value={item.price} > </RightAlignedCell>),
        actions:
             (<center>
              {/* Save button */}
              <ButtonCell buttonType={"btn btn-success ms-2"} labelType={"fas fa-save"} disabled={disableSaveButton} action={() => {handleUpdateAPriceListItem(index);}} > </ButtonCell>
              {/* Remove button */}
              <ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} disabled={disableRemoveButton} action={() => {handleRemoveItem(index);}} > </ButtonCell>
            </center>),
      });
    });

    return products;
  };

  if (loadingAdmin || isLoadingProducts ) return <Loader />;

  return (
    <>
    <MetaData title={`Price List`} />
    <HeaderAdmin title={`Price List`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.VENDORS.name}>
        <div className="col-11 col-lg-11">
          <div className="row my-4">
            <div className="col-3">
              <select className="form-select"
                aria-label="Select vendor"
                onChange={handleVendorChange}
                value={vendorId}
              >
                {vendorData?.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1">
              <button className="btn btn-primary" onClick={() => handleImportPriceList()}>Import</button>
            </div>
            <div className="text-center col-3 offset-1">
              <h3 className="my-2">{priceListData?.length} Products</h3>
            </div>
          </div>
          {(loadingPriceListData || reloadingPriceList || removingAPriceListItem) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
          </div>
          )}
          
          <MDBDataTable
            data={setProducts()}
            className="px-3"
            bordered
            striped
            hover
            noBottomColumns
          />
        </div>
        <div className="col-3 offset-8">
          {/* Add Item Button */}
          {editable && (
          <div className="my-4">
            <button 
                disabled={updatingPriceList}
                onClick={() => {addItemRowHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Add Item
            </button>
          </div>
          )}
        </div>
        <ConfirmDialog message={`Are you sure to remove ${clonedPriceListItems[itemIndexToRemove] ? productHash[clonedPriceListItems[itemIndexToRemove].product]?.product.name :"row"}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>

        {priceGenerateToolVisibilty === 'visible' && (
          <PriceListGeneratePopup 
            productHash={productHash}
            visibility={priceGenerateToolVisibilty}
            vendor={vendorName}
            vendorId={vendorId}
            onClose={() => {setPriceGenerateToolVisibilty('hidden');}}
            updateFunction={(data) => {adminUpdatePriceListMutation(data)}}
          />
        )}
      </AdminLayout>
    </>
  );
};

export default PriceList;