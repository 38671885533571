import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ADMIN_MENU_ITEMS, COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_NAME, PRODUCT_CATEGORIES} from "../../constants/constants.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAdminGetProductsQuery } from "../../redux/api/productsAdminApi.js";
import Loader from "../layout/Loader.jsx";
import { EditableNumberCell } from "../layout/CustomMDBDataTableCell.jsx";
import { getSellPrice } from "../../utils/utilities";
import MetaData from "../layout/MetaData.jsx";
import HeaderAdmin from "../layout/HeaderAdmin.jsx";
import AdminLayout from "../layout/AdminLayout.jsx";

const CustomerPriceList = () => {
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data: productList, isLoading: isLoadingProducts, error: errorLoadingProducts  } = useAdminGetProductsQuery({extraParams: 'withCategory,activeOnly'}, { refetchOnMountOrArgChange: true });
  
  const [clonedGroupedProducts, setClonedGroupedProducts] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const today = new Date().toLocaleDateString();
  const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString();
  const [dateRangeStr, setDateRangeStr] = useState(`${today} - ${nextWeek}`); 
  const [tier, setTier] = useState(1);

  useEffect(() => {
    if (errorLoadingProducts) {
      console.log('errorLoadingProducts', errorLoadingProducts);
    }
  }, [errorLoadingProducts]);

  useEffect(() => {
    if (productList && productList.products) {
      // Group products by category
      const groupedProducts = productList.products.reduce((acc, product) => {
        const categoryId = product.category._id;
        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }
        let productCopy = JSON.parse(JSON.stringify(product))
        productCopy.price = getSellPrice(product, tier).price;
        if (productCopy.price === undefined) {
          productCopy.price = 'CALL';
        }
        console.log('productCopy', productCopy);
        acc[categoryId].push(productCopy);
        return acc;
      }, {});
      setClonedGroupedProducts(JSON.parse(JSON.stringify(groupedProducts)));
    }
  }, [productList, tier]);

  useEffect(() => {
    if (!editMode) {
      handleDownload();
      setEditMode(true);
    }
  }, [editMode]);

  const todayStr = new Date().toLocaleDateString().replaceAll("/", "-");

  const handleDownload = async () => {
    const input = document.getElementById("price_list");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
  
      const pdf = new jsPDF();
      const margin = 10; // Set your desired margin
  
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
  
      pdf.addImage(imgData, "PNG", margin, margin, pdfWidth, 0);
      pdf.save(`customerPriceList_${todayStr}.pdf`);
    });
  };

  const handlePriceChange = (category, index, value) => {
    const clonedGroupedProductsCopy = JSON.parse(JSON.stringify(clonedGroupedProducts));
    clonedGroupedProductsCopy[category][index].price = value;
    setClonedGroupedProducts(clonedGroupedProductsCopy);
  };

  const handleTierSelect = (e) => {
    setTier(e.target.value);
  };

  const handleDateRangeChange = (value) => {
    setDateRangeStr(value);
  };

  if (loadingAdmin || isLoadingProducts || !user) return <Loader />;

  // Split grouped products into two arrays
  const categories = Object.keys(clonedGroupedProducts);
  let firstHalfCategories = [];
  let secondHalfCategories = [];
  
  let firstHalfProductCount = 0;
  let secondHalfProductCount = categories.reduce((acc, category) => acc + clonedGroupedProducts[category].length, 0) + categories.length;
  
  for (let category of categories) {
    const categoryProductCount = clonedGroupedProducts[category].length;
  
    if (firstHalfProductCount <= secondHalfProductCount) {
      firstHalfCategories.push(category);
      firstHalfProductCount += categoryProductCount + 1;
      secondHalfProductCount -= categoryProductCount + 1;
    } else {
      secondHalfCategories.push(category);
    }
  }

  const categoryOtherNames = categories.reduce((acc, category) => {
    const otherName = PRODUCT_CATEGORIES.find((cat) => cat.id === category).otherName;
    acc[category] = otherName;
    return acc;
  }, {});

  const salesReps = [
    {name: "Jack", email: "jack@tomproduce.com", phone: "(626) 807-6625"},
    {name: "Anita", email: "anita@tomproduce.com", phone: "(626) 638-5645"},
    {name: "Kevin", email: "tomtradinginc@gmail.com", phone: "(626) 638-5645"},
  ];

  const saleString = salesReps.map((rep, index) => (
    `${rep.name} ${rep.email} ${rep.phone} ${index < salesReps.length - 1 ? ', ' : ''}`
  ));

  return (
    <>
    <MetaData title={"Price List for Customer"} />
    <HeaderAdmin title={"Price List for Customer"} />
    <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRICE_LIST.name}>
      <div className="my-5 col-11">
        <div className="row col-12">
          <div className="col-2">
            <select className="w-100" style={{ height: '40px' }} value={tier} onChange={(e)=> {handleTierSelect(e)}}>
              <option value={1}>Tier 1</option>
              <option value={2}>Tier 2</option>
              <option value={3}>Tier 3</option>
            </select>
          </div>
          <div className="col-3 offset-7">
            <button className="btn btn-success text-end" onClick={() => {setEditMode(false)}}>
              <i className="fa fa-print"></i>&nbsp;Download Price List
            </button>
          </div>
        </div>
        <div id="price_list" className="p-3 border border-secondary" style={{ marginTop: '40px' }}>
          <div className="text-center">
            <div className='row col-12'>
              <div className='col-3'></div>
              <div className='col-6 text-center'>
                <h2>{COMPANY_NAME}</h2>
                <p>{COMPANY_ADDRESS_LINE1},{COMPANY_ADDRESS_LINE2}</p>
                <p>Phone: {COMPANY_ADDRESS_PHONE} - Fax: {COMPANY_ADDRESS_PHONE}</p>
              </div>
              <div className='col-3'>
                <strong>Date&nbsp;</strong>
                {editMode ? (<input
                              type='text'
                              value={dateRangeStr}
                              onChange={(val) => {handleDateRangeChange(val)}}
                              style={{ textAlign: "center", width: "100%", padding: '15px' }}
                            />
                          )           
                          : dateRangeStr}
              </div>
            </div>
            <div>
              <strong>SALES:&nbsp;</strong>
              {saleString}
            </div>
          </div>
          <div className="row my-5">
            <div className="col-6">
              <div className="table-responsive">
                <table style={{ border: '2px solid black', borderCollapse: 'collapse', width: '100%' }}>
                  <tbody>
                    {firstHalfCategories.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none'}}>{index === 0 ? 'QTY' : ''}</th>
                          <th style={{ width:'40%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(clonedGroupedProducts[category][0].category.name).toUpperCase()}</th>
                          <th style={{ width:'20%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(categoryOtherNames[category]).toUpperCase()}</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>PRICE</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>LB</th>
                          <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>ORI</th>
                        </tr>
                        {clonedGroupedProducts[category].map((product, idx) => (
                          <tr key={idx}>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}></td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}>{product.name}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.otherName}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{editMode ? <EditableNumberCell value={product.price} type={'text'} onChange={(val) => {handlePriceChange(category, index, val)}} ></EditableNumberCell> : (product.price)}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.dimension}</td>
                            <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.origin}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6">
              <div className="table-responsive">
                <table style={{ border: '2px solid black', borderCollapse: 'collapse', width: '100%' }}>
                  <tbody>
                    {secondHalfCategories.map((category, index) => (
                      <React.Fragment key={index}>
                      <tr>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none'}}>{index === 0 ? 'QTY' : ''}</th>
                        <th style={{ width:'40%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(clonedGroupedProducts[category][0].category.name).toUpperCase()}</th>
                        <th style={{ width:'20%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>{String(categoryOtherNames[category]).toUpperCase()}</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>PRICE</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>LB</th>
                        <th style={{ width:'10%', textAlign: 'center', color: 'red', fontSize: '18px', borderTop: '2px solid black', borderBottom: '2px solid black', borderLeft: 'none', borderRight: 'none' }}>ORI</th>
                      </tr>
                      {clonedGroupedProducts[category].map((product, idx) => (
                        <tr key={idx}>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}></td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}}>{product.name}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.otherName}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{editMode ? <EditableNumberCell value={product.price} type={'text'} onChange={(val) => {handlePriceChange(category, index, val)}} ></EditableNumberCell> : (product.price)}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.dimension}</td>
                          <td style = {{border: '2px solid black', fontSize: '16px', padding: '2px'}} className='text-center'>{product.origin}</td>
                      </tr>
                      ))}
                    </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
    </>
  );
};

export default CustomerPriceList;
