import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminOptions: localStorage.getItem("adminOptions") ? JSON.parse(localStorage.getItem("adminOptions")) : {},
};

export const adminOptionsSlice = createSlice({
  initialState,
  name: "adminOptionsSlice",
  reducers: {
    setAdminOptions: (state, action) => {
      state.adminOptions = action.payload;
      localStorage.setItem("adminOptions", JSON.stringify(state.adminOptions));
    },
    clearAdminOptions: (state, action) => {
      localStorage.removeItem("adminOptions");
      state.adminOptions = {};
    },
  },
});

export default adminOptionsSlice.reducer;

export const { setAdminOptions, clearAdminOptions } = adminOptionsSlice.actions;
