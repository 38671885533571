import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer: null,
  isAuthenticated: false,
  accountLocked: false,
  loading: true,
};

export const customerSlice = createSlice({
  initialState,
  name: "customerSlice",
  reducers: {
    setCustomer(state, action) {
      state.customer = action.payload;
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAccountLocked(state, action) {
      state.accountLocked = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export default customerSlice.reducer;

export const { setIsAuthenticated, setCustomer, setLoading, setAccountLocked } = customerSlice.actions;
