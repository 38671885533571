import React,  { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";

import MetaData from "../../layout/MetaData";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import Loader from "../../layout/Loader";
import { EditableNumberCell, ButtonCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS } from "../../../constants/constants.js";
import { useAdminCreateNewOrderMutation } from "../../../redux/api/orderAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import {  CalculateOrderSummary, getSellPrice } from "../../../utils/utilities";
import { ConfirmDialog } from "../ConfirmDialog";
import { ProductSelection } from "../../product/ProductSelection";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CustomerSelection } from "../../customer/CustomerSelection";
import { useAdminGetAllCustomersQuery } from '../../../redux/api/adminApi';
import { forEach } from "lodash";

const CreateOrder = () => {
  const navigate = useNavigate();

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);

  // state
  const [formUpdated, setFormUpdated] = useState(false);
  const [showingCancelNewOrderConfirm, showCancelNewOrderConfirmDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerTier, setCustomerTier] = useState(0);
  const [order, setOrder] = useState({itemList: []});
  const [notes, setNotes] = useState("");

  // Hooks
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminCreateNewOrder, {data: createdOrder, isLoading: isCreatingNewOrder, error: orderCreateError, isSuccess: orderCreateSuccess }] = useAdminCreateNewOrderMutation();
  const { data: customersData, isLoading: isLoadingCustomer } = useAdminGetAllCustomersQuery({params: {status: CUSTOMER_STATUS.ACTIVE}, refetchOnMountOrArgChange: true});
  
  // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();
  
  useEffect(() => {
    if (customersData) {
      setCustomers(customersData);
    }
  }, [customersData]);

  useEffect(() => {
    if (orderCreateError) {
      toast.error(orderCreateError?.data?.message);
      if (orderCreateError.status === 401) {
        adminLogout();
      }
    }
    else if (orderCreateSuccess && createdOrder) {
      //toast.success("Order Created");
      // Update local data to reflect the new order
      const orderId = createdOrder.order._id;

      navigate(`/admin/orders/${orderId}`);
    }
  }, [orderCreateError, orderCreateSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (isLoadingCustomer || isLoadingInventory  || loadingAdmin || user === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------
  // Remove item
  const removeItem = (index) => {
    if (index >= 0) {
      order.itemList.splice(index, 1);
      setOrder(order);
      setFormUpdated(!formUpdated);
    }
  }

  // Cancel
  const handleCancelNewOrder = () => {
    showCancelNewOrderConfirmDialog(true);
  };

  // Confirm cancel new order
  const confirmCancelNewOrder = () => {
    showCancelNewOrderConfirmDialog(false);
    navigate("/admin/");
  }

  // Creating order
  const createNewOrderHandler = () => {
    if (order.customer === undefined) {
      toast.error("Please select a customer.");
      return;
    }

    // Clean up the cloned order items
    const filteredList = order.itemList.filter(item => item.name);
    let thereIsError = false;
    filteredList.forEach((item, index) => {
      if (item.finalQuantity <= 0) {
        toast.error(`${item.name}'s quantity must be greater than 0.`);
        thereIsError = true;
      }
      if (item.finalPrice === undefined || item.finalPrice < 0) {
        toast.error(`${item.name}'s price is not entered.`);
        thereIsError = true;
      }
    });

    if (thereIsError) {
      return;
    }

    // Check if the order is empty
    if (filteredList.length === 0) {
      toast.error("Please add items to the order.");
      return;
    }

    //console.log(filteredList)

    adminCreateNewOrder({ customerId: order.customer, itemList: filteredList, notes });
  };

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && order.itemList[index].finalPrice !== value) {
      order.itemList[index].finalPrice = Number(value);
      setFormUpdated(!formUpdated);
    }
  };
    
  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && order.itemList[index].finalQuantity !== value) {
      order.itemList[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  // Set product
  const setProduct = (index, product) => {
    const { price } = getSellPrice(product, customerTier);
    Object.assign(order.itemList[index], {
      product: product._id,
      name: product.name,
      initQuantity: 1,
      finalQuantity: 1,
      initPrice: price,
      finalPrice: price,
    });
    setFormUpdated(!formUpdated);
  }

  // Handle customer select
  const handleCustomerSelect = (customer) => {
    if (order.customer !== customer._id) {
      order.customer = customer._id; 
      setCustomerTier(customer.tier);
      forEach(order.itemList, (item, index) => {
        const productId = order.itemList[index].product;
        const product = productId && inventory ? inventory[productId].product : undefined;
        const { price } = getSellPrice(product, customerTier);
        order.itemList[index].finalPrice = order.itemList[index].initPrice = price;
      });
      setFormUpdated(!formUpdated);
    }
  }

  // Add item row
  const addItemRowHandler = () => {
    if (order.customer === undefined) {
      toast.error("Please select a customer.");
      return;
    }
    order.itemList.push({});
    setFormUpdated(!formUpdated);
  };

  const {units, estPallet, itemTotal, tax, total} = CalculateOrderSummary(order.itemList, inventory);
  const standardColumnWidth = '8vw';
  const setOrders = () => {
    const items = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: '20vw',
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Quantity",
          field: "quantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Item Total",
          field: "itemTotal",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Action",
          field: "actions",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Stock",
          field: "stock",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Inbound",
          field: "incommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Outbound",
          field: "outcommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Balance",
          field: "balance",
          sort: "asc",
          width: standardColumnWidth,
        }
      ],
      rows: [],
    };  

    // Clone the inventory object
    let filteredInventory = { ...inventory };
    // Remove entries that are already in order.itemList
    // Only allow adding new items 
    order.itemList.forEach(item => {
        if (item.product in filteredInventory) {
            delete filteredInventory[item.product];
        }
    });

    order.itemList?.forEach((item, index) => {
      const productId = item.product;
      const product = productId && inventory ? inventory[productId] : undefined;
      const stock = product?.quantity;
      const quantity = item.finalQuantity;
      const incommingQuantity = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId].inProgressTotal : 0);
      const outcommingQuantity = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId].inProgressTotal : 0);

      let totalOutQty;
      let balance;
      // Existing item
      if (quantity !== undefined && outcommingQuantity !== undefined) {
        totalOutQty = outcommingQuantity + quantity;
      }
      if (totalOutQty !== undefined && stock !== undefined) {
        balance = stock + incommingQuantity - totalOutQty;
      }
      const price = item.finalPrice;
      //const priceString = price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const itemTotal = price ? Number(price) * Number(quantity) : undefined;
      const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
      
      items.rows.push({
        className: 'myMDBDataProcessOrderTableRow',
        name: ProductSelection({ productList: filteredInventory, defaultItem: {...item, sku: product?.product.sku }, onChange: (product) => {
                setProduct(index, product);
              }}),
        price: <EditableNumberCell value={price} onChange={(val) => {handlePriceChange(index, val)}} > </EditableNumberCell>,
        quantity: <EditableNumberCell  value={quantity} onChange={(val) => {handleQuantityChange(index, val)}}> </EditableNumberCell>,
        itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
        stock: <RightAlignedCell value={stock} > </RightAlignedCell>,
        incommingQuantity: <RightAlignedCell value={incommingQuantity} > </RightAlignedCell>,
        outcommingQuantity: <RightAlignedCell value={totalOutQty} > </RightAlignedCell>,
        balance: <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance} ></RightAlignedCell> </span>,
        actions: <center>
                  <ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {removeItem(index);}} > </ButtonCell>                
                </center>,
      });
    });

    return items;
  };

  return (
    <>
      <MetaData title={"Create Order"} />
      <HeaderAdmin title={"Create Order"} />
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 col-lg-11 my-0 offset-0">
          <div className="row my-0 ">

            <div className="col-10 col-lg-10 my-0 offset-1 text-center">
              <h1>Create New Order</h1>
            </div>
            <div className="col-1 col-lg-1 my-0 offset-0">
              {/* Cancel Order Button - right */}
              <ButtonCell buttonType={"btn btn-outline-danger ms-2"} disabled={showingCancelNewOrderConfirm} label={'Cancel'} action={(e) => {handleCancelNewOrder();}} > </ButtonCell>
            </div>
          </div>
          <h5 className="my-1 text-left"> Customer: {CustomerSelection({ customers , onChange: (customer)=> {handleCustomerSelect(customer)}})}</h5>
          {/* Order Items */}
          <MDBDataTable
            data={setOrders()}
            className="px-10 myMDBDataProcessOrderTableRow"
            bordered
            striped
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items added"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
          />
          <div className="row my-0">
            <div className="col-6 col-lg-6 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-2">
              {/* Add Item Button */}
              <div className="my-4">
                <button 
                    disabled={isCreatingNewOrder}
                    onClick={() => {addItemRowHandler();}} 
                    className="btn btn-primary"
                    style={{width: '100%'}}>
                    Add Item
                </button>
              </div>
              {/* Confirm Create Order */}
              <div className="my-4">
                <button 
                    disabled={isCreatingNewOrder || order.itemList.length === 0}
                    onClick={() => {createNewOrderHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Create Order
                </button>
              </div>

              {/* Order Summary */} 
              <div>
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="order-summary-values">
                      {units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="order-summary-values">
                      {estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="order-summary-values">
                      $
                      {itemTotal?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="order-summary-values">
                      $
                      {tax?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="order-summary-values">
                      $
                      {total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog message={`Are you sure to cancel?`} show={showingCancelNewOrderConfirm} confirm={()=>{confirmCancelNewOrder()}} cancel={()=> {showCancelNewOrderConfirmDialog(false)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default CreateOrder;
