import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminGetVendorDetailsQuery,
  useAdminUpdateVendorMutation,
} from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, USER_STATUS } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CanUserUpdateVendor } from "../AdminActionEligibilities";

const UpdateVendor = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(USER_STATUS.ACTIVE);

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data, isLoading } = useAdminGetVendorDetailsQuery(params?.id, { refetchOnMountOrArgChange: true });

  const [updateVendor, { error, isSuccess, isLoading: isUpdatingVendor }] = useAdminUpdateVendorMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [clonedVendorData, setClonedVendorData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (data?.vendor) {
      const vendor = data.vendor;
      setName(vendor.name);
      setEmail(vendor.email);
      setPhone(vendor.phone);
      setAddress(vendor.address);
      setStatus(vendor.status);

      setClonedVendorData(
      {
        name: vendor.name, 
        email: vendor.email, 
        phone: vendor.phone,
        address: vendor.address,
        status: vendor.status, 
      });
    }
  }, [data]);

  useEffect(() => {
    const newVendorData = {
      name,
      email,
      phone,
      address,
      status,
    };
    setFormChanged(!_.isEqual(newVendorData, clonedVendorData));
  }, [name, email, phone, address, status, clonedVendorData]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Vendor Updated");
      navigate("/admin/vendors");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (formChanged) {
      updateVendor({ id: params?.id, body: {
        name,
        email,
        phone,
        address,
        status,
      } });
    }
  };

  if (isLoading || loadingAdmin) {
    return <Loader />;
  } 

  const canUpdateVendor = CanUserUpdateVendor(user);

  return (
    <>
      <MetaData title={"Vendor Details"} />
      <HeaderAdmin title={"Vendor Details"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.VENDORS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
            <div className="row">
              <div className="col-6">
                <label htmlFor="status_field" className="form-label">
                  Status
                </label>
                <select
                  id="status_field"
                  className="form-select"
                  name="status"
                  value={status}
                  disabled={!canUpdateVendor}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value={USER_STATUS.ACTIVE}>{String(USER_STATUS.ACTIVE).toUpperCase()}</option>
                  <option value={USER_STATUS.INACTIVE}>{String(USER_STATUS.INACTIVE).toUpperCase()}</option>
                </select>
              </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name (*)
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  readOnly={!canUpdateVendor}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                {/* Email */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="email_field" className="form-label">
                    Email (*)
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    value={email}
                    readOnly={!canUpdateVendor}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />  
                </div>
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number (*)
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={phone}
                    readOnly={!canUpdateVendor}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => {setPhone(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="name_field" className="form-label">
                    Address
                  </label>
                  <input
                    type="address"
                    id="address_field"
                    className="form-control"
                    name="address"
                    readOnly={!canUpdateVendor}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>                
              </div>
              { canUpdateVendor && (<button disabled={!formChanged || isUpdatingVendor} type="submit" className="btn update-btn w-100 py-2">
                Update
              </button>)}
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateVendor;
