import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetProductDetailsQuery } from "../../redux/api/productsApi";
import { toast } from "react-hot-toast";
import Loader from "../layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCartItem } from "../../redux/features/cartSlice";
import MetaData from "../layout/MetaData";
import NotFound from "../layout/NotFound";
import Header from "../layout/Header";
import { useLazyCustomerLogoutQuery } from "../../redux/api/customerAuthApi";
import { getSellPrice } from "../../utils/utilities";

const ProductDetails = () => {
  const { customer, loading: loadingCustomer } = useSelector((state) => state.customerAuth);
  const params = useParams();
  const dispatch = useDispatch();
  const [customerLogout] = useLazyCustomerLogoutQuery();

  const [quantity, setQuantity] = useState(1);
  const [activeImg, setActiveImg] = useState("");

  const { data, isLoading, error, isError } = useGetProductDetailsQuery(
    params?.id
  );
  const product = data?.product;
  //const { isAuthenticated } = useSelector((state) => state.customerAuth);

  useEffect(() => {
    setActiveImg(
      product?.images[0]
        ? product?.images[0]?.url
        : "/images/default_product.png"
    );
  }, [product]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      if (isError && error?.status === 401) {
        customerLogout();
      }
    }
  }, [isError]);

  const increseQty = () => {
    const count = document.querySelector(".count");
    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  const decreseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber <= 1) return;

    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };

  /*
  const updateQty = (qty) => {
    if (isNaN(qty) || qty < 1) return;
    setQuantity(Number(qty));
  };
  */

  if (isLoading || loadingCustomer) return <Loader />;

  if (error && error?.status === 404) {
    return <NotFound />;
  }

  const { price, dollars, cents } = getSellPrice(product, customer.tier);

  const setItemToCart = () => {
    const cartItem = {
      product: product?._id,
      name: product?.name,
      finalPrice: price,
      image: product?.images[0]?.url,
      stock: product?.stock,
      palletSize: product?.palletSize,
      finalQuantity: quantity,
    };

    dispatch(setCartItem(cartItem));
    //toast.success("Item added to Cart");
  };

  return (
    <>
      <MetaData title={product?.name} />
      <Header />
      <div className="container">
        <div className="row d-flex justify-content-around">
          <div className="col-12 col-lg-5 img-fluid" id="product_image">
            <div className="p-3">
              <img
                className="d-block w-100"
                src={activeImg}
                alt={product?.name}
                width="340"
                height="390"
              />
            </div>
            <div className="row justify-content-start mt-5">
              {product?.images?.map((img) => (
                <div className="col-2 ms-4 mt-2">
                  <a role="button">
                    <img
                      className={`d-block border rounded p-3 cursor-pointer ${
                        img.url === activeImg ? "border-warning" : ""
                      } `}
                      height="100"
                      width="100"
                      src={img?.url}
                      alt={img?.url}
                      onClick={(e) => setActiveImg(img.url)}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className="col-12 col-lg-5 mt-5">
            <h3>{product?.name} {product?.otherName ? ` - ${product.otherName}` : ''}</h3>
            <p id="product_id">Product # {product?.sku}</p>

            <hr />
            {price !== undefined ? (
              <p id="product_price">
                <span className="dollars">${dollars}</span>
                <span className="cents">.{cents}</span>
              </p>
            ) : (
              <p id="product_price">
                CALL
              </p>              
            )}
            <div className="stockCounter d-inline">
              <span className="btn btn-danger minus" onClick={decreseQty}>
                -
              </span>
              <input
                type="number"
                className="form-control count d-inline"
                style={{ width: '4vw' , marginLeft: '10px', marginRight: '10px'}}
                value={quantity}
                readonly
              />
              <span className="btn btn-primary plus" onClick={increseQty}>
                +
              </span>
            </div>
            <button
              type="button"
              id="cart_btn"
              className="btn btn-primary d-inline ms-4"
              disabled={product?.stock <= 0}
              onClick={setItemToCart}
            >
              Add to Cart
            </button>

            <hr />

            <p>
              Status:{" "}
              <span
                id="stock_status"
                className={"greenColor"}
              >
                {"In Stock"}
              </span>
            </p>

            <hr />

            <h4 className="mt-2">Description:</h4>
            <p>{product?.description}</p>
            <hr />
            <p id="product_seller mb-3">
              Origin: <strong>{product?.origin}</strong>
            </p>
            {product?.dimensions && (         
              <p id="product_seller mb-3">
                Pallet Size: <strong>{product?.dimensions}</strong>
              </p>
            )}
            {product?.palletSize && (         
              <p id="product_seller mb-3">
                Pallet Size: <strong>{product?.palletSize}</strong> (Pallet)  
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
