import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import { productsApi } from "../../redux/api/productsApi";
import { customerApi } from "../../redux/api/customerApi";

export const DatabaseTrackingStreamCustomer = () => {
  const { isAuthenticated } = useSelector((state) => state.customerAuth);
    const dispatch = useDispatch();

    const allCollections = ['Product', 'Customer'];
    let socket;

    const connectSocket = () => {
      const URI = process.env.HOST_ENV === 'LOCAL' ? `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST_LOCAL}` : (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' ? `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST}` : `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_LIVE}`);
      socket = socketIOClient(URI, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        withCredentials: true,
        transports: ["websocket"],
      });

      allCollections.forEach(collection => {
        socket.off(`${collection}`);
      });

      allCollections.forEach(collection => {
        console.log(`Listening ${collection}`);
        socket.on(`${collection}`, data => {
          console.log(`Change in ${collection}`, data);
          if (collection === 'Product') {
            dispatch(productsApi.util.invalidateTags(['Product']));
          } else {
            dispatch(customerApi.util.invalidateTags(['Customer']));
          }
        });
      });
    };

    const disconnectSocket = () => {
      if (socket) {
        allCollections.forEach(collection => {
          socket.off(`${collection}`);
        });
        socket.disconnect();
      }
    };    

    useEffect(() => {
      if (isAuthenticated) {
        // Connect the socket when the component mounts
        connectSocket();
      
        // Disconnect the socket when the component unmounts
        return disconnectSocket;
      }
    }, [isAuthenticated]);

    // Automatically disconnect and reconnect the socket when the page loses and regains focus
    useEffect(() => {
      if (isAuthenticated) {
        const handleVisibilityChange = () => {
          if (document.hidden) {
            disconnectSocket();
          } else {
            connectSocket();
          }
        };
        window.addEventListener('visibilitychange', handleVisibilityChange);

        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }
    }, [isAuthenticated]);
    return (<></>);
};
