import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ConfirmDialog } from "../ConfirmDialog";
import { useAdminDeleteOrderMutation } from "../../../redux/api/orderAdminApi";
import { ADMIN_MENU_ITEMS, ORDER_STATUS, ORDER_STATUS_ICON, ORDER_USER_RIGHTS } from "../../../constants/constants.js";
import { CanUserCreateOrder, DetermineUserOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { setAdminOptions } from "../../../redux/features/adminOptionsSlice";
import { left } from "@popperjs/core";

const ListOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [orderToBeDeleted, showDeleteOrderConfirmDialog] = useState(null);

  // Redux
  const { unviewedNewOrder, unviewedUpdatedOrder, loadingAdmin, user, viewOrderHistory } = useSelector((state) => state.adminAuth);
  const { inProgressOrderData } = useSelector((state) => state.inProgressOrders);
  const { adminOptions } = useSelector((state) => state.adminOptions);

  const [adminDeleteOrder, { isLoading: isDeletingOrder, error: orderDeleteError, isSuccess: orderDeleteSuccess }] = useAdminDeleteOrderMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [orderData, setOrderData] = useState(null);

  const [totalOrderCount, setTotalOrderCount] = useState({ });

  // Default view to in-progress orders if there is no previous selection
  const [orderFilter, setOrderFilter] = useState("ALL");

  function assignOrderData() {
    const pendingCount = inProgressOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.PENDING).length || 0;
    const confirmedCount = inProgressOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.CONFIRMED).length || 0;
    const packingCount = inProgressOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.PACKING).length || 0;
    const deliveryReadyCount = inProgressOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.DELIVERY_READY).length || 0;
    const deliveredCount = inProgressOrderData?.orders?.filter(purchase => purchase.status === ORDER_STATUS.DELIVERED).length || 0;
    const allCount = inProgressOrderData?.orders?.length || 0;

    setTotalOrderCount({
      [ORDER_STATUS.PENDING]: pendingCount,
      [ORDER_STATUS.PROCESSING]: confirmedCount + packingCount + deliveryReadyCount,
      [ORDER_STATUS.DELIVERED]: deliveredCount,
      ALL: allCount
    });

    switch (orderFilter) {
      case ORDER_STATUS.PENDING:
        const pendingOrderData = {orders : inProgressOrderData?.orders?.filter(order => order.status === ORDER_STATUS.PENDING)};
        setOrderData(pendingOrderData);
        break;
      case ORDER_STATUS.PROCESSING:
        const processingOrderData = {orders : inProgressOrderData?.orders?.filter(order => order.status ===  ORDER_STATUS.CONFIRMED || order.status ===  ORDER_STATUS.PACKING || order.status ===  ORDER_STATUS.DELIVERY_READY)};
        setOrderData(processingOrderData);
        break;
      case ORDER_STATUS.DELIVERED:
        const deliveredOrderData = {orders : inProgressOrderData?.orders?.filter(order => order.status ===  ORDER_STATUS.DELIVERED)};
        setOrderData(deliveredOrderData);
        break;
      case "ALL":
        setOrderData(inProgressOrderData);
        break;
      default:
        break;
    }    
  }

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  
  useEffect(() => {
    assignOrderData();
  }, [inProgressOrderData]);

  useEffect(() => {
    // Save selection
    const newAdminOptions = {...adminOptions, allOrdersStatusFilter: orderFilter};
    dispatch(setAdminOptions(newAdminOptions));
    assignOrderData();
  }, [orderFilter]);


  useEffect(() => {
    if (orderDeleteError) {
      toast.error(orderDeleteError?.data?.message);
    }

    /*
    if (orderDeleteSuccess) {
      toast.success("Order Removed");
    }*/
  }, [orderDeleteError, orderDeleteSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (user === null || loadingAdmin || orderData === null) {
    return <Loader />;
  }

  //--------------------------------- Functions ---------------------------------

  // Delete order
  const handleDeleteOrder = (order) => {
    if (!isDeletingOrder && order !== null) showDeleteOrderConfirmDialog(order);
  }

  // Confirm deleting order
  const confirmDeletingOrder = () => {
    if (!isDeletingOrder && orderToBeDeleted !== null) {
      adminDeleteOrder(orderToBeDeleted._id);
      showDeleteOrderConfirmDialog(null);
    }
  }

  // Create new order
  const createNewOrderHandler = () => {
    // Redirect to create new order page
    navigate('/admin/orders/new');
  }

  const checkOrderStatus = (order) => {
    const isNew =  viewOrderHistory && viewOrderHistory[order._id] === undefined;
    const updated = viewOrderHistory && viewOrderHistory[order._id]?.timestamp < new Date(order.updatedAt).getTime();

    return {isNew, updated};
  }

  const canCreateNewOrder = CanUserCreateOrder(user);

  const setOrders = () => {
    const orders = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
          field: "customer",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Order Status"}> </CenterAlignedCell>,
          field: "orderStatus",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Time"}> </CenterAlignedCell>,
          field: "time",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
        },
      ],
      rows: [],
    };

    orderData?.orders?.forEach((order) => {
      // Check if user has rights to delete order
      const {rights} = DetermineUserOrderRights(user, order.status);
      const deleteable = rights?.some(right => right === ORDER_USER_RIGHTS.DELETE);
      const { isNew, updated } = checkOrderStatus(order);
      orders.rows.push({
        id: order?.orderNumber,
        customer: order?.customer?.name,
        //paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
        orderStatus: <span>{ ORDER_STATUS_ICON[order.status] && (<i className={ORDER_STATUS_ICON[order.status].icon}  style={{ color: ORDER_STATUS_ICON[order.status].color }} /> )} {String(order?.status).toUpperCase()}</span>,
        time: new Date(order?.createdAt).toLocaleString(),
        actions: (
          <center>
            <Link
              to={`/admin/orders/${order?._id}`}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>

            { deleteable && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => handleDeleteOrder(order)}
              disabled={isDeletingOrder || orderToBeDeleted !== null }
            >
              <i className="fa fa-trash"></i>
            </button>)}
          </center>
        ),
        note: 
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {isNew && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', width: '100%' }}>New</div>
          )}

          {!isNew && updated && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'orange', width: '100%' }}>Updated</div>
          )}
        </div>,
      });
    });

    return orders;
  };

  const appendOrderOption = (status) => {
    let totalUnviewedNewOrder = 0;
    let totalUnviewedUpdatedOrder = 0;
    let totalCount = totalOrderCount[status] ? totalOrderCount[status] : 0;
    if (status === 'ALL') {
      totalUnviewedNewOrder = (unviewedNewOrder[ORDER_STATUS.PENDING] ? unviewedNewOrder[ORDER_STATUS.PENDING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.CONFIRMED] ? unviewedNewOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.PACKING] ? unviewedNewOrder[ORDER_STATUS.PACKING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.DELIVERY_READY] ? unviewedNewOrder[ORDER_STATUS.DELIVERY_READY] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.DELIVERED] ? unviewedNewOrder[ORDER_STATUS.DELIVERED] : 0);
      totalUnviewedUpdatedOrder = (unviewedUpdatedOrder[ORDER_STATUS.PENDING] ? unviewedUpdatedOrder[ORDER_STATUS.PENDING] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] ? unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.PACKING] ? unviewedUpdatedOrder[ORDER_STATUS.PACKING] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.DELIVERY_READY] ? unviewedUpdatedOrder[ORDER_STATUS.DELIVERY_READY] : 0) + 
                                  (unviewedUpdatedOrder[ORDER_STATUS.DELIVERED] ? unviewedUpdatedOrder[ORDER_STATUS.DELIVERED] : 0);
    } else if (status === ORDER_STATUS.PROCESSING) {
      totalUnviewedNewOrder = (unviewedNewOrder[ORDER_STATUS.CONFIRMED] ? unviewedNewOrder[ORDER_STATUS.CONFIRMED] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.PACKING] ? unviewedNewOrder[ORDER_STATUS.PACKING] : 0) + 
                              (unviewedNewOrder[ORDER_STATUS.DELIVERY_READY] ? unviewedNewOrder[ORDER_STATUS.DELIVERY_READY] : 0);
      totalUnviewedUpdatedOrder = (unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] ? unviewedUpdatedOrder[ORDER_STATUS.CONFIRMED] : 0) +
                                  (unviewedUpdatedOrder[ORDER_STATUS.PACKING] ? unviewedUpdatedOrder[ORDER_STATUS.PACKING] : 0) +
                                  (unviewedUpdatedOrder[ORDER_STATUS.DELIVERY_READY] ? unviewedUpdatedOrder[ORDER_STATUS.DELIVERY_READY] : 0);
    } else {
      totalUnviewedNewOrder = unviewedNewOrder[status] ? unviewedNewOrder[status] : 0;
      totalUnviewedUpdatedOrder = unviewedUpdatedOrder[status] ? unviewedUpdatedOrder[status] : 0;
    }
    let   leftString = `${String(status).toUpperCase()}`;
    const unviewedNewString = totalUnviewedNewOrder > 0 ? `(${totalUnviewedNewOrder} New)` : "";
    const unviewUpdatedString = totalUnviewedUpdatedOrder > 0 ? `(${totalUnviewedUpdatedOrder} Updates)` : "";
    const rightString = `${unviewedNewString}${unviewUpdatedString}`;
    if (totalCount > 0) {
      leftString = leftString + ` (${totalCount})`;
    }
    const optionString = rightString.length > 0 ? `${leftString}\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0${rightString}` : leftString;

    return (
      <option value={status}>
        { optionString }
      </option>
    );
  }

  return (
    <>
      <MetaData title={`${String(orderFilter).toUpperCase()} Orders`} />
      <HeaderAdmin title={`${String(orderFilter).toUpperCase()} Orders`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 my-0">
          <div className="col-5">
            <select
              id="type_field"
              className="form-select"
              name="orderFilter"
              value={orderFilter}
              onChange={(e) => setOrderFilter(e.target.value)}
            >
              {appendOrderOption("ALL")}
              {appendOrderOption(ORDER_STATUS.PENDING)}
              {appendOrderOption(ORDER_STATUS.PROCESSING)}
              {appendOrderOption(ORDER_STATUS.DELIVERED)}
            </select>
          </div>
          <div className="row my-3">
            <h1 className="col-4 col-lg-4 ">{orderData?.orders?.length} Orders</h1>
            <div className="col-3 offset-5">
              {/*New Order Button - rigjht */}
              {canCreateNewOrder && (
              <button 
                onClick={() => {createNewOrderHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Order
              </button>)}             
            </div>
          </div>
          <MDBDataTable
            data={setOrders()}
            className="px-10 myMDBDataListOrderTableRow"
            bordered
            striped
            hover
            noBottomColumns
          />
          <ConfirmDialog message={`Are you sure to delete order #${orderToBeDeleted?.orderNumber}?`} show={orderToBeDeleted !== null} confirm={()=>{confirmDeletingOrder()}} cancel={()=> {showDeleteOrderConfirmDialog(null)}}> </ConfirmDialog>
        </div>
      </AdminLayout>
    </>
  );
};

export default ListOrders;
