import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { PRODUCT_CATEGORIES } from "../../constants/constants.js";

const Filters = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  // remove page number when filtering
  searchParams.delete("page");

  useEffect(() => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Deselect checkboxes if params are not included
    checkboxes.forEach((checkbox) => {
      const checkboxType = checkbox.name;
      const checkboxValue = checkbox.value;
      const value = searchParams.get(checkboxType);
      checkbox.checked = value && checkboxValue === value;
    });
  });

  // Handle Category & Ratings filter
  const handleClick = (checkbox) => {
    const checkboxes = document.getElementsByName(checkbox.name);

    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });

    if (checkbox.checked === false) {
      // Delete filter from query
      if (searchParams.has(checkbox.name)) {
        searchParams.delete(checkbox.name);
        const path = window.location.pathname + "?" + searchParams.toString();
        navigate(path);
      }
    } else {
      // Set new filter value if already there
      if (searchParams.has(checkbox.name)) {
        searchParams.set(checkbox.name, checkbox.value);
      } else {
        // Append new filter
        searchParams.append(checkbox.name, checkbox.value);
      }

      const path = window.location.pathname + "?" + searchParams.toString();
      navigate(path);
    }
  };

  const defaultCheckHandler = (checkboxType, checkboxValue) => {
    const value = searchParams.get(checkboxType);
    if (checkboxValue === value) return true;
    return false;
  };

  return (
    <div className="border p-3 filter">
      <h3>Filters</h3>
      <hr />
      <h5 className="mb-3">Category</h5>

      {PRODUCT_CATEGORIES?.map((category, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="category"
            id={`check${index}`}
            value={category.id}
            defaultChecked={defaultCheckHandler("category", category.name)}
            onClick={(e) => handleClick(e.target)}
          />
          <label className="form-check-label" htmlFor={`check${index}`}>
            {" "}
            {category.name} {category.otherName ? ` - ${category.otherName} ` : ""}
          </label>
        </div>
      ))}

      <hr />
    </div>
  );
};

export default Filters;
