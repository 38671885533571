import React, { useEffect } from "react";
import MetaData from "./layout/MetaData";
import { useSelector } from "react-redux";
import { useGetProductsQuery } from "../redux/api/productsApi";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import Loader from "./layout/Loader";
import Filters from "./layout/Filters";
import CustomPagination from "./layout/CustomPagination";
import { PRODUCT_CATEGORIES } from "../constants/constants.js";

import ProductItem from "./product/ProductItem";
import Header from "./layout/Header";
import { useLazyCustomerLogoutQuery } from "../redux/api/customerAuthApi";
import { useCustomerGetMeQuery } from "../redux/api/customerApi";

const Home = () => {
    useCustomerGetMeQuery();
    const { customer, loading: loadingCustomer } = useSelector((state) => state.customerAuth);
    // Get params
    let [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const keyword = searchParams.get("keyword") || "";
    const category = searchParams.get("category");
    const params = { page, keyword };
    category !== null && (params.category = category);

    const { data, isLoading, error, isError } = useGetProductsQuery(params);
    const [customerLogout] = useLazyCustomerLogoutQuery();
    

    useEffect(() => {
        if (error) {
            toast.error(error.data?.message);
            if (error.status === 401 || error.status === 423) {
                customerLogout();
            }    
        }
    }, [isError]);

    if (loadingCustomer || isLoading || isError || !data?.products) {
        return <Loader />;
    }

    const columnSize = 3;
    const matchedCategory = PRODUCT_CATEGORIES.find((cat) => cat.id === category);
    const categoryName = matchedCategory?.name || "All Products";

    return (
        <>
            <MetaData title={"Buy Asian Produce Online"} />
            <Header searchBar={true} />
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mt-5">
                        <Filters />
                    </div>
                    <div className="col-md-9">
                        <h1 id="products_heading" className="text-secondary">
                            {categoryName}
                        </h1>
                        <h3 id="products_heading" className="text-secondary">
                            {keyword ? `${data?.filteredProductsCount} Products found with keyword: ${keyword}`
                                    : `${data?.filteredProductsCount} Products found`}
                        </h3>

                        <section id="products" className="mt-5">
                            <div className="row">
                                {data.products?.map((product) => (
                                    <ProductItem key={product._id} product={product} customer={customer} columnSize={columnSize} />
                                ))}
                            </div>
                        </section>

                        <CustomPagination 
                            resPerPage={Number(data.resPerPage)} 
                            filteredProductsCount={Number(data.filteredProductsCount)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


export default Home;