import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../layout/Loader";
import { USER_ROLES } from "../../constants/constants.js";
import { useAdminGetMeQuery } from "../../redux/api/adminApi";
import { useLazyAdminGetInProgressOrdersQuery } from "../../redux/api/orderAdminApi";
import { useLazyAdminGetInProgressPurchasesQuery } from "../../redux/api/purchaseAdminApi";
import { CanUserViewOrder, CanUserViewPurchase } from "../admin/AdminActionEligibilities";
import { useLazyAdminReadLogsQuery } from "../../redux/api/adminApi";

import { updateUserUnviewedOrders, updateUserUnviewedPurchases } from "../../redux/features/adminSlice";

export const ProtectedAdminRoute = ({ children, role }) => {
  const dispatch = useDispatch();

  const { isLoading } = useAdminGetMeQuery();
  const { isAuthenticatedAdmin, loadingAdmin, user, logTimestamp, needReloadingLogs } = useSelector((state) => state.adminAuth);
  const { inProgressOrderData } = useSelector((state) => state.inProgressOrders);
  const { inProgressPurchaseData } = useSelector((state) => state.inProgressPurchases);

  const [adminReadLogs] = useLazyAdminReadLogsQuery();
  const [AdminGetInProgressOrdersQuery ] = useLazyAdminGetInProgressOrdersQuery(undefined, { refetchOnMountOrArgChange: true });
  const [AdminGetInProgressPurchasesQuery ] = useLazyAdminGetInProgressPurchasesQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isAuthenticatedAdmin && user && CanUserViewOrder(user) && CanUserViewPurchase(user)) {
      AdminGetInProgressOrdersQuery();
      AdminGetInProgressPurchasesQuery();
      if (needReloadingLogs) {
        adminReadLogs({timestamp: logTimestamp});
      }
    }
  }, [isAuthenticatedAdmin, user]);

  useEffect(() => {
    if (user && inProgressOrderData && inProgressOrderData.orders) {
      const orderStatsHash = {};
      inProgressOrderData.orders.forEach(order => {
        const timestamp = new Date(order.updatedAt).getTime();
        orderStatsHash[order._id] = {timestamp, status: order.status};
      });
      dispatch(updateUserUnviewedOrders(orderStatsHash));
    }

    if (user && inProgressPurchaseData && inProgressPurchaseData.purchases) {
      const purchaseStatsHash = {};
      inProgressPurchaseData.purchases.forEach(purchase => {
        const timestamp = new Date(purchase.updatedAt).getTime();
        purchaseStatsHash[purchase._id] = {timestamp, status: purchase.status};
      });
      dispatch(updateUserUnviewedPurchases(purchaseStatsHash));
    }
  }, [inProgressOrderData, inProgressPurchaseData, user]);

  if (loadingAdmin || isLoading) return <Loader />;

  if (!user || !isAuthenticatedAdmin || (!role.includes(USER_ROLES.ALL) && !role.includes(user.role))) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};
