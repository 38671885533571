import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import _ from "lodash";

import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminGetUserDetailsQuery,
  useAdminUpdateUserMutation,
} from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, USER_STATUS, USER_ROLES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CanUpdateUser } from "../AdminActionEligibilities";

const UpdateUser = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data, isLoading, error: errorLoadingUserDetail } = useAdminGetUserDetailsQuery(params?.id);

  const [adminUpdateUser, { error, isSuccess, isLoading: isUpdatingUser }] = useAdminUpdateUserMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [clonedUserData, setClonedUserData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (data?.user) {
      const aUser = data?.user;
      console.log(aUser )
      setName(aUser.name);
      setStatus(aUser.status);
      setRole(aUser.role);
      setEmail(aUser.email);
      setPhone(aUser.phone);

      setClonedUserData(
      {
        name: aUser.name, 
        status: aUser.status, 
        role: aUser.role,
        email: aUser.email,
        phone: aUser.phone,
      });
    }
  }, [data]);

  useEffect(() => {
    const newUserData = {
      name,
      status,
      role,
      email,
      phone,
    };
    setFormChanged(!_.isEqual(newUserData, clonedUserData));
  }, [name, status, role, email, phone, clonedUserData]);

  useEffect(() => {
    if (errorLoadingUserDetail) {
      toast.error(errorLoadingUserDetail?.data?.message);
      if (errorLoadingUserDetail.status === 401) {
        adminLogout();
      } else if (errorLoadingUserDetail.status === 404) {
        navigate("/admin/users");
      }
    }
  }, [errorLoadingUserDetail]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }

    if (isSuccess) {
      toast.success("User Updated");
      navigate("/admin/users");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (formChanged) {
      adminUpdateUser({ id: params?.id, body: {
        name,
        status,
        role,
        email,
        phone,
      } });
    }
  };

  if (isLoading || loadingAdmin) {
    return <Loader />;
  } 

  const canUpdateUser = CanUpdateUser(user);

  return (
    <>
      <MetaData title={"User Details"} />
      <HeaderAdmin title={"User Details"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.USERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    disabled={!canUpdateUser}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={USER_STATUS.ACTIVE}>{String(USER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={USER_STATUS.INACTIVE}>{String(USER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="role_field" className="form-label">
                    Role
                  </label>
                  <select
                    id="role_field"
                    className="form-select"
                    name="role"
                    value={role}
                    disabled={!canUpdateUser}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value={USER_ROLES.ACCOUNTANT}>{String(USER_ROLES.ACCOUNTANT).toUpperCase()}</option>
                    <option value={USER_ROLES.WAREHOUSE}>{String(USER_ROLES.WAREHOUSE).toUpperCase()}</option>
                    <option value={USER_ROLES.SALE}>{String(USER_ROLES.SALE).toUpperCase()}</option>
                    <option value={USER_ROLES.DRIVER}>{String(USER_ROLES.DRIVER).toUpperCase()}</option>
                    <option value={USER_ROLES.ADMIN}>{String(USER_ROLES.ADMIN).toUpperCase()}</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  readOnly={!canUpdateUser}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                {/* Email */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="email_field" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    readOnly={!canUpdateUser}
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />  
                </div>
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    readOnly={!canUpdateUser}
                    value={phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => {setPhone(e.target.value) }}
                  />
                </div>
              </div>
              
              { canUpdateUser && (<button disabled={!formChanged || isUpdatingUser} type="submit" className="btn update-btn w-100 py-2">
                Update
              </button>)}
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateUser;
