import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminApi } from "./adminApi.js";
import { orderAdminApi } from "./orderAdminApi.js";
import { productsAdminApi } from "./productsAdminApi.js";
import { setIsAuthenticatedAdmin, setAdmin } from "../features/adminSlice.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const adminAuthApi = createApi({
  reducerPath: "adminAuthApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1/admin"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  endpoints: (builder) => ({
    adminRegister: builder.mutation({
      query(body) {
        return {
          url: "/register",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(adminApi.endpoints.adminGetMe.initiate(null, { forceRefetch: true }));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    adminLogin: builder.mutation({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
          credentials: 'include',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Log cookies after login mutation is fulfilled
          console.log('Cookies after login:', document.cookie);
          await dispatch(adminApi.endpoints.adminGetMe.initiate(null, { forceRefetch: true }));
          dispatch(orderAdminApi.util.invalidateTags(["Order", "Inventory"]));
          dispatch(productsAdminApi.util.invalidateTags(["Product"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    adminLogout: builder.query({
      query: () => "/logout",
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setIsAuthenticatedAdmin(false));
          dispatch(setAdmin(null));
          // invalidate all caches
          dispatch(orderAdminApi.util.invalidateTags(["Inventory"]));
          dispatch(orderAdminApi.util.invalidateTags(["Order"]));
        } catch (error) {
          console.log(error);
        }
      }
    }),  
    adminQuickbooksAuthorize: builder.query({
      query: () => `/quickbooks/authorize?timestamp=${new Date().getTime()}`
    }),  
    adminQuickbooksLogout: builder.query({
      query: () => "/quickbooks/logout",
    }),  
  }),
});

export const { useAdminLoginMutation, useAdminRegisterMutation, useLazyAdminLogoutQuery, useLazyAdminQuickbooksAuthorizeQuery, useLazyAdminQuickbooksLogoutQuery } = adminAuthApi;
