
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ADMIN_MENU_ITEMS } from "../../constants/constants.js";

const SideMenu = ({ menuItems, activeMenu }) => {
  const {unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase} = useSelector((state) => state.adminAuth);

  const [newIndicator, setNewIndicator] = useState({});
  const [updateIndicator, setUpdateIndicator] = useState({});

  useEffect(() => {
    const totalNewOrder = Object.values(unviewedNewOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalNewPurchase = Object.values(unviewedNewPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setNewIndicator(
      {
        ...newIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalNewOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalNewPurchase,
      }
    )

    const totalUpdatedOrder = Object.values(unviewedUpdatedOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalUpdatedPurchase = Object.values(unviewedUpdatedPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setUpdateIndicator(
      {
        ...updateIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalUpdatedOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalUpdatedPurchase,
      }
    )
  }, [unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase]);

  return (
    <div className="list-group mt-5 pl-4">
      
      {menuItems?.map((menuItem, index) => (
        <Link
          key={index}
          to={menuItem.link}
          className={`fw-bold list-group-item list-group-item-action ${
            menuItem.name === activeMenu ? "active" : ""
          }`}
          aria-current={
            menuItem.name === activeMenu ? "true" : "false"
          }
        >
          <div className="menu-item d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i className={`${menuItem.icon} fa-fw pe-2`} style={{marginRight: '4px'}}></i> {menuItem.name}
            </div>
            <div className="d-flex align-items-center">
              {newIndicator && newIndicator[menuItem.id] !== null && newIndicator[menuItem.id] !== undefined && newIndicator[menuItem.id] > 0 && (
                <span className="new-items-indicator">{`${newIndicator[menuItem.id]} New`}</span>
              )}
              
              {updateIndicator && updateIndicator[menuItem.id] !== null && updateIndicator[menuItem.id] !== undefined && updateIndicator[menuItem.id] > 0 && (
                <span className="updated-items-indicator">{`${updateIndicator[menuItem.id]} Updates`}</span>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SideMenu;
