import React from "react";
import { Route } from "react-router-dom";

import { ProtectedCustomerRoute } from "../auth/ProtectedCustomerRoute";

import ProductDetails from "../product/ProductDetails";
import CustomerLogin from "../auth/CustomerLogin";
import Register from "../auth/Register";
import CustomerProfile from "../customer/CustomerProfile";
import UpdateCustomerProfile from "../customer/UpdateCustomerProfile";
import UploadCustomerAvatar from "../customer/UploadCustomerAvatar";
import UpdateCustomerPassword from "../customer/UpdateCustomerPassword";
import ForgotPassword from "../auth/CustomerForgotPassword";
import ResetPassword from "../auth/CustomerResetPassword";
import Cart from "../cart/Cart";
import Shipping from "../cart/Shipping";
import ConfirmOrder from "../cart/ConfirmOrder";
import MyOrders from "../order/MyOrders";
import OrderDetails from "../order/OrderDetails";
import MyHistoryOrders from "../order/MyHistoryOrders";
import HistoryOrderDetails from "../order/HistoryOrderDetails";
import Home from "../Home";
import { CustomerInvoice, HistoryCustomerInvoice } from "../customer/CustomerInvoice";

const userRoutes = () => {
  return (
    <>
      <Route path="/home" element={<ProtectedCustomerRoute><Home /></ProtectedCustomerRoute>} />
      <Route path="/products/:id" element={<ProtectedCustomerRoute><ProductDetails /> </ProtectedCustomerRoute>} />
      <Route path="/" element={<CustomerLogin />} />
      <Route path="/register" element={<Register />} />

      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />

      <Route path="/me/profile" element={<ProtectedCustomerRoute><CustomerProfile /></ProtectedCustomerRoute>}/>
      <Route path="/me/update_profile"element={<ProtectedCustomerRoute><UpdateCustomerProfile /></ProtectedCustomerRoute>}/>
      <Route path="/me/upload_avatar" element={<ProtectedCustomerRoute><UploadCustomerAvatar /></ProtectedCustomerRoute>}/>
      <Route path="/me/update_password" element={<ProtectedCustomerRoute><UpdateCustomerPassword /></ProtectedCustomerRoute>}/>
      <Route path="/me/orders" element={<ProtectedCustomerRoute><MyOrders /></ProtectedCustomerRoute>}/>
      <Route path="/me/orders/:id" element={<ProtectedCustomerRoute><OrderDetails /></ProtectedCustomerRoute>}/>
      <Route path="/me/historyorders" element={<ProtectedCustomerRoute><MyHistoryOrders /></ProtectedCustomerRoute>}/>
      <Route path="/me/historyorders/:id" element={<ProtectedCustomerRoute><HistoryOrderDetails /></ProtectedCustomerRoute>}/>

      <Route path="/cart" element={<ProtectedCustomerRoute><Cart /></ProtectedCustomerRoute>} />
      <Route path="/shipping" element={<ProtectedCustomerRoute><Shipping /></ProtectedCustomerRoute>}/>
      <Route path="/confirm_order" element={<ProtectedCustomerRoute><ConfirmOrder /></ProtectedCustomerRoute>}/>
      <Route path="/invoice/order/:id" element={<ProtectedCustomerRoute><CustomerInvoice /></ProtectedCustomerRoute>}/>
      <Route path="/invoice/historyorder/:id" element={<ProtectedCustomerRoute><HistoryCustomerInvoice/></ProtectedCustomerRoute>}/>
    </>
  );
};

export default userRoutes;
