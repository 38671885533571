import React from "react";
import UserLayout from "../layout/UserLayout";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import Header from "../layout/Header";

const CustomerProfile = () => {
  const { customer } = useSelector((state) => state.customerAuth);
  return (
    <>
      <MetaData title={"Your Profile"} />
      <Header />
      <div className="container">
        <UserLayout title="Profile">
          <div className="row justify-content-around mt-5 user-info">
            <div className="col-12 col-md-3">
              <figure className="avatar avatar-profile">
                <img
                  className="rounded-circle img-fluid"
                  src={
                    customer?.avatar ? customer?.avatar?.url : "/images/default_avatar.jpg"
                  }
                  alt={customer?.name}
                />
              </figure>
            </div>

            <div className="col-12 col-md-5">
              <h4>Full Name</h4>
              <p>{customer?.name}</p>

              <h4>Email Address</h4>
              <p>{customer?.email}</p>

              <h4>Joined On</h4>
              <p>{customer?.createdAt?.substring(0, 10)}</p>
            </div>
          </div>
        </UserLayout>
      </div>
    </>
  );
};

export default CustomerProfile;
