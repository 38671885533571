import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setInventoryDataOrdersByProduct, setLoadingInventoryDataOrdersByProduct, setInprogressOrderData } from "../features/inProgressOrdersSlice.js";
import { updateUserUnviewedOrders, setAdmin } from "../features/adminSlice.js";
import { ORDER_STATUS } from "../../constants/constants.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const orderAdminApi = createApi({
  reducerPath: "orderAdminApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1/admin"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Order", /*"OrderDelivered",*/ "OrderFinished", "Inventory"],
  endpoints: (builder) => ({
    // New order
    adminCreateNewOrder: builder.mutation({
      query(body) {
        return {
          url: "/orders/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order"],
    }),
    // Order details
    adminOrderDetails: builder.query({
      query: ({ id, extraParams}) => `/orders/${id}?extraParams=${extraParams}`,
      providesTags: (result, error, id) => [{ type: 'Order', id }],
    }),
    // Dashboard sales
    adminGetDashboardSales: builder.query({
      query: ({ startDate, endDate }) => `/getsalesinrange/?startDate=${startDate}&endDate=${endDate}`,
    }),
    // Get in-progress orders
    adminGetInProgressOrders: builder.query({
      query: () => `/ordersinprogress`,
      providesTags: ["Order"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let collectionTimestamp = 0;
          const { data } = await queryFulfilled;
          const orderStatsHash = {};
          // Convert to hash map - key is product id
          const ordersByProduct = data.orders.reduce((acc, order) => {
            const timestamp = new Date(order.updatedAt).getTime();
            collectionTimestamp = Math.max(collectionTimestamp, timestamp);
            orderStatsHash[order._id] = {timestamp, status: order.status};
            const orderId = order._id;
            const customer = order.customer;
            if (order.orderItems) {
              order.orderItems.forEach(item => {
                const productId = item.product.toString();
                if (!acc[productId]) {
                  acc[productId] = {total: 0, inProgressTotal: 0, orders: []}; 
                }
                acc[productId].orders.push({ orderId, customer, price: item.finalPrice, quantity: item.finalQuantity, status: order.status});
                acc[productId].total += item.finalQuantity;
                if (order.status !== ORDER_STATUS.DELIVERED) {
                  acc[productId].inProgressTotal += item.finalQuantity;
                }
              });
            }
            return acc;
          }, {});
          dispatch(setInprogressOrderData(data));
          dispatch(setInventoryDataOrdersByProduct({ ordersByProduct, collectionTimestamp }));
          dispatch(setLoadingInventoryDataOrdersByProduct(false));
          dispatch(updateUserUnviewedOrders(orderStatsHash));
        } catch (error) {
            dispatch(setLoadingInventoryDataOrdersByProduct(false));
            console.log(error);
        }
      },
    }),
    // Get finished orders
    adminGetFinishedOrders: builder.query({
      query: () => `/ordersfinished`,
      providesTags: ["OrderFinished"],
    }),
    // Update order
    adminUpdateOrder: builder.mutation({
      query({ id, body }) {
        //console.log("Updating order ...", body);
        return {
          url: `/orders/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order", "Inventory"],
    }),
    // Deliver order - same endpoint as above, just different tag
    adminDeliverOrder: builder.mutation({
      query({ id, body }) {
        //console.log("Updating order ...", body);
        return {
          url: `/orders/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }, "Order", "Inventory"],
    }),    
    // Transfer order to Quickbooks
    adminTransferToQuickbooks: builder.mutation({
      query({ id, body }) {
        return {
          url: `/orders/${id}/transfertoquickbooks`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }],
    }),
    // Delete order
    adminDeleteOrder: builder.mutation({
      query(id) {
        //console.log('Removing order ...', id)
        return {
          url: `/orders/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Get orders by product Id
    adminGetOrdersByProduct: builder.query({
      query: (id) => `/ordersinprogress/product/${id}`,
      transformResponse: (result) => result.orders,
      providesTags: ["Order"],
    }),
    // Update orders by product Id
    adminUpdateOrdersByProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/ordersinprogress/product/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Remove order item by product Id
    adminRemoveOrderItemByProduct: builder.mutation({
      query({ productId, orderId }) {
        return {
          url: `/ordersinprogress/product/${productId}/remove/${orderId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Mark order as viewed
    adminMarkOrderAsViewed: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `/orders/${orderId}/markasviewed`,
          method: "PUT",
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          if (result && result.data?.data) {
            dispatch(setAdmin(result.data.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    adminSendOrderEmail: builder.mutation({
      query({ orderId, body }) {
        return {
          url: `/orders/${orderId}/email`,
          method: "POST",
          body,
        };
      },
    }),
    getDashboardSales: builder.query({
      query: ({ startDate, endDate }) => `/getordersinrange/?startDate=${startDate}&endDate=${endDate}`,
    }),
  }),
});

export const {
  useAdminCreateNewOrderMutation,
  useAdminOrderDetailsQuery,
  useLazyAdminGetDashboardSalesQuery,
  useAdminUpdateOrderMutation,
  useAdminDeliverOrderMutation,
  useAdminTransferToQuickbooksMutation,
  useAdminDeleteOrderMutation,

  useLazyAdminGetInProgressOrdersQuery,
  useLazyAdminGetFinishedOrdersQuery,

  useAdminGetOrdersByProductQuery,
  useAdminUpdateOrdersByProductMutation,
  useAdminRemoveOrderItemByProductMutation,

  useAdminMarkOrderAsViewedMutation,

  useAdminSendOrderEmailMutation,
  useLazyGetDashboardSalesQuery,
} = orderAdminApi;
