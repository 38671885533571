import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../../layout/MetaData";

import AdminLayout from "../../layout/AdminLayout";
import { useAdminCreateVendorMutation } from "../../../redux/api/adminApi";
import { validateVendorProfileForm } from "../../../utils/validators";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, USER_STATUS } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";

const CreateVendor = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(USER_STATUS.ACTIVE);

  const navigate = useNavigate();

  const { loadingAdmin } = useSelector((state) => state.adminAuth);

  const [adminCreateVendor, { error, isSuccess, isLoading: creatingVendor }] = useAdminCreateVendorMutation(undefined, { refetchOnMountOrArgChange: true });
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Vendor Created");
      navigate("/admin/vendors");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const newVendor = {
      name,
      email,
      phone,
      address,
      status,
    };

    const { formIsValid, formErrors } = validateVendorProfileForm(newVendor);
    if (formIsValid ) {
      adminCreateVendor({ body: newVendor });
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  if (loadingAdmin) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"Create Vendor"} />
      <HeaderAdmin title={"Create Vendor"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.VENDORS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={USER_STATUS.ACTIVE}>{String(USER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={USER_STATUS.INACTIVE}>{String(USER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name (*)
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                {/* Email */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="email_field" className="form-label">
                    Email (*)
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />  
                </div>
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number (*)
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => {setPhone(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="name_field" className="form-label">
                    Address
                  </label>
                  <input
                    type="address"
                    id="address_field"
                    className="form-control"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>                
              </div>
              <button disabled={creatingVendor} type="submit" className="btn update-btn w-100 py-2">
                Create
              </button>
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default CreateVendor;
