import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../layout/Loader";
import { useCustomerGetMeQuery } from "../../redux/api/customerApi";

export const ProtectedCustomerRoute = ({ children }) => {
  const { isLoading } = useCustomerGetMeQuery();
  const { isAuthenticated, loading } = useSelector((state) => state.customerAuth);

  if (loading || isLoading) return <Loader />;

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};
