
// Hard coded product categories for faster searching
export const PRODUCT_CATEGORIES = [
  { name: "Chinese Vegetable", id: "66457712f1831932b1fcfd4f", otherName: "國菜" },
  { name: "Vegetable", id: "66457792f1831932b1fcfd51", otherName: "外國菜" },
  { name: "Herb", id: "66544dd77818660d81987716", otherName: "香菜" },
  { name: "Fruit", id: "66458f437a853ef78a3319e0", otherName: "水果類" },
  { name: "Mushroom", id: "66497c509fdb6992b978a5c1", otherName: "菇類" },
  { name: "Root", id: "66544dc97818660d81987715", otherName: "根類"},
  { name: "Sprout", id: "6665bce8b91ff4c41b0f3d95", otherName: "發芽"},
  { name: "Squash", id: "6665bd24b91ff4c41b0f3d96", otherName: "瓜類"},
  { name: "Egg, Tofu, Noodles", id: "6665bbe4b91ff4c41b0f3d94", otherName: "蛋豆腐粉麵"}
];

// Hard coded US states for faster searching
export const US_STATES = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" }
];

export const PAYMENT_TYPE = {
  COD: 'cod',
  CARD: 'card',
  BANK: 'bank',
  WALLET: 'wallet',
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const ORDER_STATUS = {
  PROCESSING: 'processing', // generic for orders that are not delivered nor finished
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  PACKING: 'packing',
  DELIVERY_READY: 'ready to deliver',
  DELIVERED: 'delivered',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
};

export const ORDER_STATUS_ICON = {
  [ORDER_STATUS.PROCESSING]: { icon: 'fas fa-spinner', color: 'black'},  // generic for orders that are not delivered nor finished
  [ORDER_STATUS.PENDING]: { icon: 'fas fa-pencil', color: 'orange'},
  [ORDER_STATUS.CONFIRMED]: { icon: 'fas fa-check-circle', color: 'green'},
  [ORDER_STATUS.PACKING]: { icon: 'fas fa-box-open', color: 'blue'},
  [ORDER_STATUS.DELIVERY_READY]: { icon: 'fas fa-box', color: 'blue'}, 
  [ORDER_STATUS.DELIVERED]: { icon: 'fas fa-truck', color: 'silver'},
  [ORDER_STATUS.FINISHED]: { icon: 'fas fa-check-double', color: 'green'},
  [ORDER_STATUS.CANCELLED]: { icon: 'fas fa-ban', color: 'red'},
};

export const PURCHASE_STATUS = {
  PENDING: 'pending',
  ORDERED: 'ordered', // placed order to vendor but not yet confirmed
  NOT_CONFIRMED: 'notconfirmed', // only for filtering
  CONFIRMED: 'confirmed',
  DELIVERED: 'delivered', // for purchases, delivered means received
  CANCELLED: 'cancelled',
};

export const PURCHASE_STATUS_ICON = {
  [PURCHASE_STATUS.PENDING]: { icon: 'fas fa-pencil', color: 'black'},
  [PURCHASE_STATUS.ORDERED]: { icon: 'fas fa-phone', color: 'orange'},
  [PURCHASE_STATUS.CONFIRMED]: { icon: 'fas fa-check-circle', color: 'green'},
  [PURCHASE_STATUS.DELIVERED]: { icon: 'fas fa-warehouse', color: 'blue'},
  [PURCHASE_STATUS.CANCELLED]: { icon: 'fas fa-ban', color: 'red'},
};

export const USER_ROLES = {
  SUPER_ADMIN: 'superadmin', 
  ADMIN: 'admin', 
  SALE: 'sale', 
  WAREHOUSE: 'warehouse', 
  ACCOUNTANT: 'accountant',
  CUSTOMER: 'customer', // customer is treated in a different schema  
  DRIVER: 'driver',
  ALL: 'all',
};

export const ADMIN_MENU_ITEMS = {
  ORDERS: { id: "orders", name: "Orders", icon: "fas fa-shopping-cart", link: "/admin/orders" },
  PURCHASES: { id: "purchases", name: "Purchases", icon: "fas fa-shopping-bag", link: "/admin/purchases" },
  INVENTORY: {  id: "inventories", name: "Inventories", icon: "fas fa-clipboard-list", link: "/admin/inventories" },
  PRODUCTS: {  id: "products", name: "Products", icon: "fas fa-apple-alt", link: "/admin/products" },
  USERS: {  id: "users", name: "Users", icon: "fas fa-user-tie", link: "/admin/users" },
  CUSTOMERS: {  id: "customers", name: "Customers", icon: "fas fa-user", link: "/admin/customers" },
  PRICE_LIST: {  id: "customersPriceList", name: "Price List", icon: "fas fa-tags", link: "/admin/customerpricelist" },
  VENDORS: {  id: "vendors", name: "Vendors", icon: "fas fa-store", link: "/admin/vendors" },
  DRIVERS: {  id: "drivers", name: "Drivers", icon: "fas fa-truck", link: "/admin/drivers" },
  DASHBOARD: {  id: "dashboard", name: "Dashboard", icon: "fas fa-tachometer-alt", link: "/admin/dashboard" },
};

export const ORDER_USER_RIGHTS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  CONFIRM: 'confirm',
  BUY: 'buy',
  PACK: 'pack',
  FINISH_PACKING: 'finish_packing',
  TRANSFER_TO_QUICKBOOKS: 'transfer_to_quickbooks',
  DELIVER: 'deliver',
};

export const PURCHASE_USER_RIGHTS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  ORDER: 'order',
  CONFIRM: 'confirm',
  RECEIVE: 'receive',
};

export const CUSTOMER_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
  PENDING: 'pending', 
};

export const VENDOR_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
};

export const USER_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
};

export const DELIVERY_TYPE = {
  DELIVER: 'deliver', 
  PICK_UP: 'pick_up', 
};

export const DRIVER_DELIVERY_STATUS = {
  CURRENT: 'current',
  HISTORY: 'history',
};

export const PRODUCT_UNIT = {
  BOX: 'box',
  LBS: 'lbs',
  COUNT: 'count',
  PALLET: 'pallet',
};

export const INVENTORY_CHANGE_TYPE = {
  MANUAL: 'manual', 
  INBOUND: 'inbound', 
  OUTBOUND: 'outbound', 
};

export const MISC_DATA_TAGS = {
  DAILY_ROLLOVER: 'daily_rollover',
};

export const TAX_PERCENTAGE = 0;
export const PRICE_PERCENTAGE_DIFFERENCE_PER_TIER=2; // 2% difference between each tier
export const MAX_CUSTOMER_TIER=3;
export const MAX_NUM_OF_DISPLAYED_LOGS=100;
export const DEFAULT_PRICE_PERCENTAGE_DIFFERENCE_RATE=15;
export const PRODUCT_SEARCH_PAGE_RESULT_PER_PAGE=8;
export const INVENTORY_SEARCH_PAGE_RESULT_PER_PAGE=8;
export const DELIVERY_SEARCH_PAGE_RESULT_PER_PAGE=100;
export const HISTORY_ORDER_SEARCH_PAGE_RESULT_PER_PAGE=1;
export const COMPANY_NAME = "Tom Produce Inc.";
export const COMPANY_ADDRESS_LINE1 = "1995 E 20th St Unit 5 Los Angeles";
export const COMPANY_ADDRESS_LINE2 = "CA 90058, USA"
export const COMPANY_ADDRESS_PHONE = "(626)678-5818";
export const COMPANY_ADDRESS_FAX = "(213)402-3030";
