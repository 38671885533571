import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';

import { Provider } from 'react-redux';
import { store } from './redux/store.js';

// Get the version from environment variables
const version = process.env.REACT_APP_VERSION;

if ('serviceWorker' in navigator) {
  // Unregister existing service workers
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister();
    });
  });

  // Register the new service worker after the page loads
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New update available
              console.log('New content is available; please refresh.');
              alert('A new version of the application is available. The page will now refresh.');
              forceClearCacheAndReload();
            } else {
              // Content is cached for offline use
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    }).catch(error => {
      console.error('Service worker registration failed:', error);
    });
  });
}

// Function to clear cache and reload the page
function forceClearCacheAndReload() {
  if ('caches' in window) {
    caches.keys().then(cacheNames => {
      return Promise.all(
        cacheNames.map(cacheName => {
          return caches.delete(cacheName);
        })
      );
    }).then(() => {
      window.location.reload();
    });
  } else {
    window.location.reload();
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

console.log('App version:', version);