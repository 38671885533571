import React from "react";
import SideMenu from "./SideMenu";
import { ADMIN_MENU_ITEMS } from "../../constants/constants.js";

const AdminLayout = ({ children, title, menuItem }) => {
  const menuItems = [
    ADMIN_MENU_ITEMS.ORDERS,
    ADMIN_MENU_ITEMS.PURCHASES,
    ADMIN_MENU_ITEMS.INVENTORY,
    ADMIN_MENU_ITEMS.PRODUCTS,
    ADMIN_MENU_ITEMS.CUSTOMERS,
    ADMIN_MENU_ITEMS.PRICE_LIST,
    ADMIN_MENU_ITEMS.VENDORS,
    ADMIN_MENU_ITEMS.USERS,
    ADMIN_MENU_ITEMS.DRIVERS,
    ADMIN_MENU_ITEMS.DASHBOARD,
  ];

  return (
    <div>
      <div className="mt-2 mb-4 py-0">
        <h2 className="text-center fw-bolder">{title}</h2>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="custom-sidebar">
        <SideMenu menuItems={menuItems} activeMenu={menuItem} />
      </div>
      <div className="custom-content">{children}</div>
    </div>
    </div>
  );
};

export default AdminLayout;
