import { createSlice } from "@reduxjs/toolkit";
import { ORDER_STATUS } from "../../constants/constants.js";

/*
// Load orders from local storage
let inventoryDataOrdersByProduct = localStorage.getItem("inventoryDataOrdersByProduct");
let loadingInventoryDataOrdersByProduct = true;

if (inventoryDataOrdersByProduct) {
  try {
    inventoryDataOrdersByProduct = JSON.parse(inventoryDataOrdersByProduct);
    loadingInventoryDataOrdersByProduct = false
  } catch (e) {
    console.error("Invalid order JSON:", e);
    inventoryDataOrdersByProduct = null;
  }
}*/

const initialState = {
  inProgressOrderData: null,
  inventoryDataOrdersByProduct: null,
  loadingInventoryDataOrdersByProduct: true,
  inventoryDataOrdersByProductTimestamp: null,
};

export const inProgressOrdersSlice = createSlice({
  initialState,
  name: "inProgressOrdersSlice",
  reducers: {
    setInprogressOrderData(state, action) {
      state.inProgressOrderData = action.payload;    
    },
    setInventoryDataOrdersByProduct(state, action) {
      state.inventoryDataOrdersByProduct = action.payload.ordersByProduct;
      state.inventoryDataOrdersByProductTimestamp = action.payload.collectionTimestamp;
      // Store to local storage
      //localStorage.setItem("inventoryDataOrdersByProduct", JSON.stringify(state.inventoryDataOrdersByProduct));
    },
    setLoadingInventoryDataOrdersByProduct(state, action) {
      state.loadingInventoryDataOrdersByProduct = action.payload;
    },
  },
});

export default inProgressOrdersSlice.reducer;

export const { setInventoryDataOrdersByProduct, setLoadingInventoryDataOrdersByProduct, setInprogressOrderData } = inProgressOrdersSlice.actions;
