import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminGetCustomerDetailsQuery,
  useAdminUpdateCustomerMutation,
} from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS, US_STATES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";

const UpdateCustomer = () => {
  const [name, setName] = useState("");
  const [extraId, setExtraId] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [tier, setTier] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data, isLoading } = useAdminGetCustomerDetailsQuery(params?.id, { refetchOnMountOrArgChange: true });

  const [updateCustomer, { error, isSuccess, isLoadding: isUpdatingCustomer }] = useAdminUpdateCustomerMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [clonedCustomerData, setClonedCustomerData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (data?.customer) {
      const customer = data?.customer;
      setName(customer.name);
      setExtraId(customer.extraId);
      setAddressInfo(customer.addressInfo);
      setStatus(customer.status);
      setEmail(customer.email);
      setTier(customer.tier);

      setClonedCustomerData(
      {
        name: customer.name, 
        email: customer.email, 
        status: customer.status, 
        tier: customer.tier,
        addressInfo: {...customer.addressInfo} 
      });
    }
  }, [data]);

  useEffect(() => {
    const newCustomerData = {
      name,
      extraId,
      email,
      status,
      tier,
      addressInfo,
    };
    setFormChanged(!_.isEqual(newCustomerData, clonedCustomerData));
  }, [name, email, status, tier, addressInfo, clonedCustomerData]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Customer Updated");
      navigate("/admin/customers");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (formChanged) {
      updateCustomer({ id: params?.id, body: {
        name,
        extraId,
        email,
        status,
        tier,
        addressInfo,
      } });
    }
  };

  const updateAddressHandler = (e) => {
    const clonedAddressInfo = { ...addressInfo };
    clonedAddressInfo[e.target.name] = e.target.value;
    setAddressInfo(clonedAddressInfo);
  };

  if (isLoading || loadingAdmin) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"Update Customer"} />
      <HeaderAdmin title={"Update Customer"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.CUSTOMERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
            <div className="mb-3">
              <div className="row">
                <div className="col-4">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={CUSTOMER_STATUS.ACTIVE}>{String(CUSTOMER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.PENDING}>{String(CUSTOMER_STATUS.PENDING).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.INACTIVE}>{String(CUSTOMER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="status_field" className="form-label">
                    Tier
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={tier}
                    onChange={(e) => setTier(e.target.value)}
                  >
                    <option value={1}>Tier 1</option>
                    <option value={2}>Tier 2</option>
                    <option value={3}>Tier 3</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="status_field" className="form-label">
                    Quickbooks ID
                  </label>
                  <input
                    type="name"
                    id="extraId_field"
                    className="form-control"
                    name="extraId"
                    value={extraId}
                    onChange={(e) => setExtraId(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Address */}
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={addressInfo.address}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              <div className="row">
                {/* City */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="city_field" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    id="city_field"
                    className="form-control"
                    name="city"
                    value={addressInfo.city}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>

                {/* State */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="state_field" className="form-label">
                    State
                  </label>
                  <select
                    id="selection_field"
                    className="form-control"
                    name="state"
                    value={addressInfo.state}
                    onChange={(e) => { updateAddressHandler(e) }}
                  >
                    <option value="" disabled>Select a state</option>
                    {US_STATES.map((state) => (
                      <option key={state.abbreviation} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={addressInfo.phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
                {/* Zip Code */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="zipCode_field" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    id="zipCode_field"
                    className="form-control"
                    name="zipCode"
                    value={addressInfo.zipCode}
                    pattern="\d{5}"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a 5 digit zip code');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
              </div>
              {/* Email */}
              <div className="mb-3">
                <label htmlFor="email_field" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value)}}
                />  
              </div>
              <button disabled={!formChanged || isUpdatingCustomer} type="submit" className="btn update-btn w-100 py-2">
                Update
              </button>
            </div>  
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateCustomer;
