import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SalesChart from "../charts/SalesChart";
import { useLazyGetDashboardSalesQuery } from "../../redux/api/orderAdminApi";
import { useLazyAdminGetDashboardPurchasesQuery } from "../../redux/api/purchaseAdminApi";
import { toast } from "react-hot-toast";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import { set } from "lodash";
import HeaderAdmin from "../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS } from "../../constants/constants";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date().setDate(1));
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("Sales");
  const [data, setData] = useState(null);
  const [dataType, setDataType] = useState("Sales");

  const [getDashboardSales, { error: errorLoadingSales, isLoading: isLoadingSales, data: salesData }] = useLazyGetDashboardSalesQuery();
  const [getDashboardPurchase, { error: errorLoadingPurchases, isLoading: isLoadingPurchases, data: purchaseData }] = useLazyAdminGetDashboardPurchasesQuery();

  useEffect(() => {
    if (errorLoadingSales) {
      toast.error(errorLoadingSales?.data?.message);
    }
    if (errorLoadingPurchases) {
      toast.error(errorLoadingPurchases?.data?.message);
    }
  }, [errorLoadingSales, errorLoadingPurchases]);

  useEffect(() => {
    if (salesData) {
      setData(salesData);
      setDataType("Sales");
    } 
  }, [salesData]);

  useEffect(() => {
    if (purchaseData) {
      setData(purchaseData);
      setDataType("Purchases");
    }
  }, [purchaseData]);

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    if (newType === "Sales") {
      setData(salesData);
    } else {
      setData(purchaseData);
    }
    setType(newType);
    setDataType(newType);
  };

  const submitHandler = () => {
    if (type === "Sales") {
      getDashboardSales({
        startDate: new Date(startDate).toISOString(),
        endDate: endDate.toISOString(),
      });
    } else {
      getDashboardPurchase({
        startDate: new Date(startDate).toISOString(),
        endDate: endDate.toISOString(),
      });
    }
    //setData(null);
  };

  return (
    <>
      <MetaData title={"Dashboard"} />
      <HeaderAdmin title={"Dashboard"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DASHBOARD.name}>
        <div className="d-flex justify-content-start align-items-center">
        <div className="mb-3 me-4">
            <label className="form-label d-block">Select Type</label>
            <select value={type} className="form-select" onChange={handleTypeChange}>
              <option value="Sales">Sales</option>
              <option value="Purchases">Purchases</option>
            </select>
          </div>
          <div className="mb-3 me-4 offset-5">
            <label className="form-label d-block">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-block">End Date</label>

            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
            />
          </div>
          <button
            className="btn fetch-btn ms-4 mt-3 px-5"
            onClick={submitHandler}
          >
            Fetch
          </button>
        </div>

        {/* Loader */}
        {(isLoadingSales || isLoadingPurchases) && (
          <div style={{position: 'relative', height: '100%', width: '100%'}}>
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
              <Loader />
            </div>
          </div>
        )}

        <div className="col-11">
          <div className="row pr-4 my-5">
            <div className="col-xl-6 col-sm-12 mb-3">
              <div className="card text-white bg-success o-hidden h-100">
                <div className="card-body">
                  <div className="text-center card-font-size">
                    { data ? 'Total' : ' ' }
                    <br />
                    <b>
                      { data ? (dataType === "Sales" ? `$${data.totalSales?.toFixed(2)}` : `$${data.totalPurchases?.toFixed(2)}`) : ' ' }
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-sm-12 mb-3">
              <div className="card text-white bg-danger o-hidden h-100">
                <div className="card-body">
                  <div className="text-center card-font-size">
                    { data ? (dataType === "Sales" ? 'Orders' : 'Purchases') : '' }
                    <br />
                    <b>{data?.totalNumOrders}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SalesChart salesData={(dataType === "Sales" ? data?.sales : data?.purchases)} type={dataType}/>
        </div>

        <div className="mb-5"></div>
      </AdminLayout>
    </>
  );
};

export default Dashboard;
