
import React, { useState, useEffect, useRef  } from 'react';
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import _ from "lodash";
import Loader from '../Loader';
import { EditableNumberCell, RightAlignedCell } from '../CustomMDBDataTableCell';

export const InventoryUpdatePopup = ({ onClose, product, title, data, visibility, updateFunction }) => {
  const [clonedOrdersData, setClonedOrdersData] = useState({});
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const popupRef = useRef(); // Create a ref

  useEffect(() => {
    if (data) {
      setClonedOrdersData(JSON.parse(JSON.stringify(data)));
    } else {
      setClonedOrdersData({});
    }
    setThereAreValueChanges(false);
  }, [data]);

  // Add this useEffect hook
  useEffect(() => {
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // don't allow click below the popup
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("mouseup", handleClick);
    } else {
      document.removeEventListener("mouseup", handleClick);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClick);
    };
  }, [visibility]);
  
  //--------------------------------- Functions ---------------------------------
  // Update quantity of the orders
  const handleQuantityChange = (value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrdersData.quantity !== value) {
      const newData = {...clonedOrdersData, quantity: Number(value)};
      setClonedOrdersData(newData);
      setThereAreValueChanges(!_.isEqual(newData, data));
    }
  };

  const handleStatusChange = (value) => {
    const newData = {...clonedOrdersData, active: value};
    setClonedOrdersData(newData);
    setThereAreValueChanges(!_.isEqual(newData, data));
  }

  // Update inventory
  const updateHandler = () => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      const inventoryDataToUpdate = {
        quantity: clonedOrdersData.quantity,
        active: clonedOrdersData.active,
      };
      console.log(inventoryDataToUpdate)
      updateFunction?.update({ id: product._id, body: inventoryDataToUpdate });
    }
  };

  const popupStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };

  return(
    <div ref={popupRef} id="popup" class="popupSmall" style={{...popupStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div className="col-2"> 
        </div> 
        <div className="col-8 text-center"><h5>{title}</h5></div>
        <div className="col-2 text-end">
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div style={{ width: '96%', maxHeight: '80vh', overflow: 'overlay'}}>
        <table className="table table-bordered" style={{ width: '100%' }}>
          <tbody>
            <tr style={{width: '100%'}}>
              {/* stock */}
              <td className={'text-start'} style={{width: '50%'}}>
                Stock:
              </td>
              <td className={'text-end'} style={{width: '50%'}}>
                <EditableNumberCell 
                  value={clonedOrdersData.quantity} onChange={(val) => {handleQuantityChange(val)}} color={clonedOrdersData?.quantity !== data?.quantity ? 'red' : 'black'}> 
                </EditableNumberCell>
              </td>
            </tr>
            {/* Status */}
            {/*
            <tr style={{width: '100%'}}> 
              <td className={'text-start'} style={{width: '50%'}}>
                Status:
              </td>
              <td className={'text-end'} style={{width: '50%'}}>
                <select
                  id="status_field"
                  className="form-select"
                  name="status"
                  value={clonedOrdersData?.active}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  <option value={true} style={{color: 'black'}}>Active</option>
                  <option value={false} style={{color: 'red'}}>Inactive</option>
                </select>                
              </td>
            </tr>
            */}
          </tbody>
        </table>
      </div>
      {updateFunction?.updating && ( 
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>)
      }
      {updateFunction && (
        <button onClick={updateHandler} className="btn btn-success" disabled={!thereAreValueChanges || updateFunction?.updating}>
          Update
        </button>
      )}
    </div>
  );
};