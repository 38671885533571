import React,  { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
//import { PDFDocument } from 'pdf-lib';

import { useAdminOrderDetailsQuery } from "../../../redux/api/orderAdminApi";
import Loader from "../../layout/Loader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import PrintInvoice from "../../printings/Invoice";
import HeaderAdmin from "../../layout/HeaderAdmin";
import MetaData from "../../layout/MetaData";

const AdminInvoice = () => {
    const params = useParams();
    const orderId = params?.id;

    const [order, setOrder] = useState(null); // Order details

    const { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError } = useAdminOrderDetailsQuery({ id: orderId, extraParams: 'fullProductDetail,confirmedBy'}, { refetchOnMountOrArgChange: true });
    const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

    useEffect(() => {
        if (user && orderDetails?.order) {
            const order = orderDetails.order;
            setOrder(order);
        }
    }, [user, orderDetails]);

    useEffect(() => {
        if (loadingOrderDetailError) {
          toast.error(loadingOrderDetailError?.data?.message);
        } 
    }, [loadingOrderDetailError]);

    return (
        <>
          <MetaData title={"Order Invoice"} />
          <HeaderAdmin />

          {(loadingAdmin || loadingOrderDetails) && (<Loader/>)}
          {order && (
            <PrintInvoice order={order} />
          )}
        </>
      );
};

export default AdminInvoice;