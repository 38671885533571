import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { generateOrderEmailTemplate } from "../../../utils/utilities";
import { useAdminOrderDetailsQuery, useAdminSendOrderEmailMutation } from "../../../redux/api/orderAdminApi";
import Loader from "../../layout/Loader";
import toast from "react-hot-toast";

const AdminEmailOrderToCustomer = () => {
    const params = useParams();
    const location = useLocation();

    const orderId = params?.id;

    const [adminSendOrderEmail, { error: errorSendingEmail, isLoading: isSendingEmail, isSuccess }] = useAdminSendOrderEmailMutation();
    const { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError } = useAdminOrderDetailsQuery({ id: orderId });

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const [order, setOrder] = useState(null);
    const [clonedEmail, setClonedEmail] = useState(email);
    const [clonedSubject, setClonedSubject] = useState();
    const [clonedBody, setClonedBody] = useState();
    const action = queryParams.get('action');

    useEffect(() => {
        if (orderDetails) {
            setOrder(orderDetails.order);
            const { subject, body } = generateOrderEmailTemplate(orderDetails.order, action);
            setClonedSubject(subject);
            setClonedBody(body);
        }
    }, [orderDetails]);
    
    useEffect(() => {
        if (errorSendingEmail) {
            toast.error(errorSendingEmail?.data?.message);
        } 
    }, [errorSendingEmail]);

    useEffect(() => {
        if (isSuccess) {
            window.close();
        }
    }, [isSuccess]);

    const handleSend = () => {
        adminSendOrderEmail({ orderId, body: { email: clonedEmail, subject: clonedSubject, message: clonedBody, plainText: true } });
    };

    if (!order) {
        return <Loader />;
    }

    return (
        <div>
            {/* Loader */}
            {(isSendingEmail) && (
                <div style={{position: 'relative', height: '100%', width: '100%'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                        <Loader />
                    </div>
                </div>
            )}

            <div style={{ padding: '0 20px' }}>
                <div>
                    <label style={{ color: '#888' }}>To:</label>
                    <input
                        type="email"
                        value={clonedEmail}
                        onChange={(e) => setClonedEmail(e.target.value)}
                        style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #ccc", // Lighter border
                            outline: "none",
                            padding: "10px 0" // Indent
                        }}
                    />
                </div>
                <div>
                    <label style={{ color: '#888' }}>Subject:</label>
                    <input
                        type="text"
                        value={clonedSubject}
                        onChange={(e) => setClonedSubject(e.target.value)}
                        style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #ccc", // Lighter border
                            outline: "none",
                            padding: "10px 0" // Indent
                        }}
                    />
                </div>
                <div>
                    <textarea
                        value={clonedBody}
                        onChange={(e) => setClonedBody(e.target.value)}
                        style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #ccc", // Lighter border
                            outline: "none",
                            resize: "none", // Optional: to prevent resizing
                            height: "calc(100vh - 260px)", // Make textarea taller to take up full page
                            padding: "10px 0" // Indent
                        }}
                    />
                </div>
                <div>
                <button
                    onClick={handleSend}
                    style={{
                        backgroundColor: "#007BFF", // Blue color
                        color: "#fff", // White text
                        padding: "10px 20px", // Wider button
                        border: "none",
                        borderRadius: "5px", // Rounded corners
                        cursor: "pointer" // Pointer cursor on hover
                    }}
                >
                    Send
                </button>
                </div>
            </div>
        </div>
    );
};

export default AdminEmailOrderToCustomer;