/* eslint-disable jsx-a11y/anchor-is-valid */
import React,  { useEffect, useState } from "react";
//import { toast } from "react-hot-toast";
import { toast } from 'react-toastify';
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MetaData from "../../layout/MetaData";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import _  from 'lodash';

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import Loader from "../../layout/Loader";
import { EditableNumberCell, ButtonCell, RightAlignedCell, CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { ORDER_STATUS, ADMIN_MENU_ITEMS, ORDER_USER_RIGHTS, ORDER_STATUS_ICON, DELIVERY_TYPE } from "../../../constants/constants.js";
import { useAdminOrderDetailsQuery, useAdminUpdateOrderMutation, useAdminDeliverOrderMutation, useAdminTransferToQuickbooksMutation, useAdminDeleteOrderMutation, useAdminMarkOrderAsViewedMutation } from "../../../redux/api/orderAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { useAdminGetAllDriversQuery } from "../../../redux/api/adminApi";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import { CalculateOrderSummary, getSellPrice } from "../../../utils/utilities";
import { ConfirmDialog, ConfirmDialogDeliverOrder } from "../ConfirmDialog";
import { ProductSelection } from "../../product/ProductSelection";
import { DetermineUserOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery, useLazyAdminQuickbooksAuthorizeQuery } from "../../../redux/api/adminAuthApi";

const ProcessOrder = () => {
  const params = useParams();
  const orderId = params?.id;
  const navigate = useNavigate();

  // Redux
  const { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError } = useAdminOrderDetailsQuery({ id: orderId }, { refetchOnMountOrArgChange: true });
  const [adminUpdateOrder, { data: updatedOrderData, isLoading: isUpdatingOrder, error: orderUpdateError, isSuccess: orderUpdateSuccess }] = useAdminUpdateOrderMutation();
  const [adminDeliverOrder, { data: deliverOrderData, isLoading: isDeliveringOrder, error: orderDeliverError, isSuccess: orderDeliverSuccess }] = useAdminDeliverOrderMutation();
  const [adminDeleteOrder, { isLoading: isDeletingOrder, error: orderDeleteError, isSuccess: orderDeleteSuccess }] = useAdminDeleteOrderMutation();
  const [adminTransferToQuickbooks, { data: quickbookData, isLoading: isTransferingToQuickbooks, error: quickbooksTransferError, isSuccess: quickbooksTransferSuccess }] = useAdminTransferToQuickbooksMutation();
  const [adminMarkOrderAsViewed] = useAdminMarkOrderAsViewedMutation();

  // state
  const [order, setOrder] = useState(null); 
  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [showingUpdateDeliveredOrderConfirm, showUpdateDeliveredOrderConfirmDialog] = useState(false);
  const [showingDeleteOrderConfirm, showDeleteOrderConfirmDialog] = useState(false);
  const [showingDeliveryConfirm, showDeliveryConfirmDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminAuthorizeQuickbooks, { data: quickbooksAuthorizationData, isLoading: isAuthorizingQuickbooks, error: quickbooksAuthError }] = useLazyAdminQuickbooksAuthorizeQuery();
  const [loggingIntoQuickbooks, setLoggingIntoQuickbooks] = useState(false);
  const [notes, setNotes] = useState();
  const [history, setHistory] = useState("");
  const [tempUpdatedInventory, setTempUpdatedInventory] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState();
  const [deliverType, setDeliverType] = useState();
  const [pallet, setPallet] = useState();

  //const [orderUpdatedTime, setOrderUpdatedTime] = useState();
  let [clonedOrderItems, setclonedOrderItems] = useState([]);

  // Slice data
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);

     // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();
  const {data: allDriverData } = useAdminGetAllDriversQuery();
 
  // Clean up the temp inventory
  useEffect(() => {
    setTempUpdatedInventory({});
  }, [inventory]);

  useEffect(() => {
    if (loadingOrderDetailError) {
      toast.error(loadingOrderDetailError?.data?.message);
      if (loadingOrderDetailError.status === 401) {
        adminLogout();
      } else if (loadingOrderDetailError.status === 404) {
        navigate("/admin/orders");
      }
    } 
  }, [loadingOrderDetailError]);

  useEffect(() => {
    if (user && orderDetails?.order) {
      const order = orderDetails.order;
      setOrder(order);

      // Mark the order as viewed if not viewed
      const timestamp = new Date(order.updatedAt).getTime();
      if (!user.viewOrderHistory || !user.viewOrderHistory[order._id] || user.viewOrderHistory[order._id].timestamp < timestamp) {
        adminMarkOrderAsViewed({ orderId: order._id, body: {timestamp, status: order.status}} );
      }
    }
  }, [user, orderDetails]);
  
  useEffect(() => {
    considerEnableUpdateButton();
  }, [notes]);

  useEffect(() => {
    if (order) {
      if (order.status) {
        setStatus(order.status);
      }
      setNotes(order.notes);
      if (order.history) {
        let historyString = "";
        order.history.forEach((item, index) => {
          historyString += `${index + 1}. ${item} \n`;
        });
        setHistory(historyString);
      }

      if (order.orderItems) {
        setOrderItems(order.orderItems);    
        setclonedOrderItems(JSON.parse(JSON.stringify(order.orderItems)));
      }

      if (order.deliverType) {
        setDeliverType(order.deliverType);
      }

      if (order.driver) {
        setSelectedDriverId(order.driver._id);
      }

      if (order.pallet) {
        setPallet(order.pallet);
      }
    }
  }, [order]);

  // Update (process) order callback
  useEffect(() => {
    if (orderUpdateError) {
      toast.error(orderUpdateError?.data?.message);
    }
    if (orderUpdateSuccess) {
      //toast.success(updatedOrderData?.message);
      const updatedOrder = updatedOrderData?.order;
      if (updatedOrder) {
        setStatus(updatedOrder.status);
        setOrderItems(updatedOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(updatedOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (updatedOrderData?.updatedInventory) {
        setTempUpdatedInventory(updatedOrderData.updatedInventory);
      }
    }
  }, [orderUpdateError, orderUpdateSuccess]);

  // Deliver order callback
  useEffect(() => {
    if (orderDeliverError) {
      toast.error(orderDeliverError?.data?.message);
    }
    if (orderDeliverSuccess) {
      //toast.success(deliverOrderData?.message);
      const deliveredOrder = deliverOrderData?.order;
      if (deliveredOrder) {
        setStatus(deliveredOrder.status);
        setOrderItems(deliveredOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(deliveredOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (deliverOrderData?.updatedInventory) {
        setTempUpdatedInventory(deliverOrderData.updatedInventory);
      }
    }
  }, [orderDeliverError, orderDeliverSuccess]);

  useEffect(() => {
    considerEnableUpdateButton();
  }, [clonedOrderItems, formUpdated]);

  useEffect(() => {
    if (quickbooksTransferError) {
      toast.error(quickbooksTransferError?.data?.message);
    } else if (quickbooksTransferSuccess) {
      if (quickbookData) {
        setOrder(quickbookData.order);
        if (quickbookData?.message) {
          toast.success(quickbookData?.message);
        }
      }
    }
  }, [quickbooksTransferError, quickbooksTransferSuccess]);

  useEffect(() => {
    if (quickbooksAuthorizationData) {
      if (quickbooksAuthorizationData.authorized === true) {
        performActionAfterQuickbooksAuth();
      } else {
        const authUrl = quickbooksAuthorizationData.authUrl;
        if (authUrl) {
          setLoggingIntoQuickbooks(true);
          window.open(authUrl, '', 'width=600,height=400');
        } else {
          toast.error("Authorization failed. Please contact administrator");
        }
      }
    }
  }, [quickbooksAuthorizationData]);

  useEffect(() => {
    if (quickbooksAuthError) {
      toast.error(quickbooksAuthError?.data?.message);
      setLoggingIntoQuickbooks(false);
    }
  }, [quickbooksAuthError]);

  useEffect(() => {
    if (orderDeleteError) {
      toast.error(orderDeleteError?.data?.message);
    } else if (orderDeleteSuccess) {
      //toast.success("Order Removed");
      if (!isLoadingInventory) {
        navigate("/admin/");
      }
    }
  }, [orderDeleteError, orderDeleteSuccess, isLoadingInventory]);

  // Update
  const updateOrderHandler = () => {
    // Clean up the cloned order items
    clonedOrderItems = clonedOrderItems.filter(item => item.name);
    if (isOrderUpdated()) {
      console.log("Updating order ...", clonedOrderItems);
      let thereIsError = false;
      clonedOrderItems.forEach((item, index) => {
        if (item.finalQuantity <= 0) {
          toast.error(`${item.name}'s quantity must be greater than 0.`);
          thereIsError = true;
        }
        if (item.finalPrice === undefined || item.finalPrice < 0) {
          toast.error(`${item.name}'s price is not entered.`);
          thereIsError = true;
        }
      });

      if (!thereIsError) {
        const orderData = appendDelivertyData({ itemList: clonedOrderItems, notes});
        adminUpdateOrder({ id: orderId, body: orderData });
      }
    } 
  };

  // Check if the quickbooks auth is successful or already authorized
  const performActionAfterQuickbooksAuth = () => {
    // Clear the notification
    const quickbooksAction = localStorage.getItem('quickbooksAction');
    console.log("Performing action after quickbooks auth ...", quickbooksAction);

    // Transfer to quickbooks
    if (quickbooksAction === 'transferToQuickbooks') {
      if (!isTransferingToQuickbooks) {
         adminTransferToQuickbooks({ id: orderId });
      }
    }
    localStorage.removeItem('quickbooksAction');
  };

  const handleStorageEvent = (event) => {
    console.log("Handling storage event ...", event.key);
    if (event.key === 'quickbooksAuthSuccess') {
      // If the quickbooks auth is successful, proceed transfering to quickbooks
      if (event.newValue === 'true') {
        performActionAfterQuickbooksAuth();
        //toast.success("Quickbooks authorization successful! Please proceed with the action!");
      }
      localStorage.removeItem('quickbooksAuthSuccess');
      setLoggingIntoQuickbooks(false);
    }
  };

  // Listen for the notification from the new tab
  useEffect(() => {
    window.removeEventListener('storage', handleStorageEvent);
    console.log("Adding event listener for storage event ...");
    window.addEventListener('storage', handleStorageEvent);

    // Cleanup event listener on component unmount
    return () => {
      console.log("Removing event listener for storage event ...");
      window.removeEventListener('storage', handleStorageEvent);
      localStorage.removeItem('quickbooksAction');
      localStorage.removeItem('quickbooksAuthSuccess');
    };
  }, []);

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (isLoadingInventory || loadingOrderDetails || loadingAdmin || user === null || order === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------
  function isOrderUpdated() {
    return order && (!_.isEqual(clonedOrderItems, orderItems) || notes !== order.notes || order.deliverType !== deliverType || (order.deliverType === DELIVERY_TYPE.DELIVER && order.driver?._id !== selectedDriverId) || order.pallet !== pallet);
  }

  function considerEnableUpdateButton() 
  {
    if (order) {
      setThereAreValueChanges(isOrderUpdated());
    }
  }
  
  const handleRemoveItem = (index) => {
    if (clonedOrderItems[index].name) {
      showRemoveItemConfirmDialog(index);
    } else {
      // If the item is new, remove it directly
      confirmRemovingItem(index);
    }
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      clonedOrderItems.splice(index, 1);
      setFormUpdated(!formUpdated);
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Delete order
  const handleDeleteOrder = () => {
    if (!isDeletingOrder) showDeleteOrderConfirmDialog(true);
  }

  // Confirm deleting order
  const confirmDeletingOrder = () => {
    showDeleteOrderConfirmDialog(false);
    if (!isDeletingOrder) adminDeleteOrder(orderId);
  }

  // Process order
  const processOrderHandler = (nextStatus) => {
    if (nextStatus === ORDER_STATUS.DELIVERED) {
      if (!pallet) {
        toast.error("Please enter pallet size!");
      } else if (deliverType === DELIVERY_TYPE.DELIVER && !selectedDriverId) {
        toast.error("Please select driver!");  
      } else {
        showDeliveryConfirmDialog(true);
      }
    } else {
      // There there are chages, update first
      if (thereAreValueChanges) {
        toast.error("There are changes in this order. Please update first");
      } else {
        console.log("clonedOrderItems", clonedOrderItems);
        const allItemsValid = clonedOrderItems.every(item => item.finalPrice !== undefined && item.finalPrice !== null && item.finalQuantity >= 0);
        if (!allItemsValid) {
          toast.error("Please enter price and quantity for all items!");
          return;
        }
        
        const orderData = appendDelivertyData({ status: nextStatus });
        adminUpdateOrder({ id: orderId, body: orderData });
      }
    }
  };

  const appendDelivertyData = (orderData) => {
    if (deliverType && deliverType !== order.deliverType) {
      orderData.deliverType = deliverType;
    }
    if (selectedDriverId && selectedDriverId !== order.driver?._id) {
      orderData.driverId = selectedDriverId;
      orderData.driverName = selectedDriverName;
    }
    if (pallet && pallet !== order.palle) {
      orderData.pallet = pallet;
    }
    return orderData;
  }

  const confirmDeliverOrder = () => {
    showDeliveryConfirmDialog(false);
    const orderData = appendDelivertyData({ status: ORDER_STATUS.DELIVERED });
    adminDeliverOrder({ id: orderId, body: orderData });
  }

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrderItems[index].finalPrice !== value) {
      clonedOrderItems[index].finalPrice = Number(value);
      setFormUpdated(!formUpdated);
    }
  };
    
  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrderItems[index].finalQuantity !== value) {
      clonedOrderItems[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  // Add item row
  const addItemRowHandler = () => {
    clonedOrderItems.push({});
    setclonedOrderItems(clonedOrderItems);
    setFormUpdated(!formUpdated);
  };

  // Transfer to Quickbooks
  const transferToQuickbooksHandler = () => {
    if (!isTransferingToQuickbooks) {
      if (thereAreValueChanges) {
        toast.error("There are changes in this order. Please update first");
      } else {
        localStorage.setItem('quickbooksAction', 'transferToQuickbooks');
        adminAuthorizeQuickbooks();
      }
    }
  };

  //  --- Print order ---
  const handlePrintOptionSelect = (option) => {
    switch (option) {
      case 'PackingSlip':
        printPackingSlip();
        break;
      case 'Invoice':
        printInvoice();
        break;
      default:
    }
  };

  const printPackingSlip = () => {
    window.open('/admin/orders/' + orderId + '/packingslip', '_blank');
  };

  const printInvoice = () => {
    window.open('/admin/orders/' + orderId + '/invoice', '_blank');
  };

  const processEmailCustomerHandler = () => {
    window.open('/admin/orders/' + orderId + `/email?email=${order.customer.email}&orderNumber=${order.orderNumber}&customerName=${order.customer.name}&orderStatus=${order.status}`, '', 'width=800,height=600');
  };

  // --------------------------------------

  const {units, estPallet, itemTotal, tax, total} = CalculateOrderSummary(clonedOrderItems, inventory);

  // Determine user rights
  const {rights} = DetermineUserOrderRights(user, status);
  const editable = rights?.some(right => right === ORDER_USER_RIGHTS.UPDATE);
  const addable = rights?.some(right => right === ORDER_USER_RIGHTS.ADD);
  const deleteable = rights?.some(right => right === ORDER_USER_RIGHTS.DELETE);

  const quickbooksTransferred = order?.quickbooksTransfer === true;
  const finishedOrder = status === ORDER_STATUS.DELIVERED || status === ORDER_STATUS.FINISHED || status === ORDER_STATUS.CANCELLED;
  const MDBDTableClassname = finishedOrder ? "myMDBDataFinishedOrderTableRow" : "myMDBDataProcessOrderTableRow";
  const selectedDriverName = allDriverData?.find(driver => driver._id === selectedDriverId)?.name;
  
  const setOrders = () => {
    const items = {
      columns: [
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Quantity"}> </CenterAlignedCell>,
          field: "quantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Item Total"}> </CenterAlignedCell>,
          field: "itemTotal",
          sort: "asc",
        },
      ],
      rows: [],
    };  

    if (!finishedOrder) {
      items.columns.push(
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Stock"}> </CenterAlignedCell>,
          field: "stock",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Inbound"}> </CenterAlignedCell>,
          field: "incommingQuantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Outbound"}> </CenterAlignedCell>,
          field: "outcommingQuantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Balance"}> </CenterAlignedCell>,
          field: "balance",
          sort: "asc",
        });
    }


    // Clone the inventory object
    let filteredInventory = { ...inventory };
    // Remove entries that are already in clonedOrderItems
    // Only allow adding new items 
    clonedOrderItems.forEach(item => {
        if (item.product in filteredInventory) {
            delete filteredInventory[item.product];
        }
    });

    if (finishedOrder && !editable) {
      clonedOrderItems?.forEach((item) => {
        const quantity = item.finalQuantity;
        const price = item.finalPrice;
        //const priceString = price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const itemTotal = parseFloat((Number(price) * Number(quantity)).toFixed(2));
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });      
        items.rows.push({
          className: MDBDTableClassname,
          name: item.name,
          price: <RightAlignedCell value={price} > </RightAlignedCell>,
          quantity: <RightAlignedCell value={quantity} > </RightAlignedCell>,
          itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
        });
      });
    } else {
      clonedOrderItems?.forEach((item, index) => {
        const productId = item.product;
        const matchingIndex = orderItems.findIndex(item => item.product === productId);
        const product = inventory[productId];
        const extraInfo = product ? '' : ' (Product removed/inactive)';
        let stock = product ? (product.quantity ? product.quantity : 0) : undefined;
        if (product && productId && tempUpdatedInventory[productId] && tempUpdatedInventory[productId].stockInc) {
          stock += tempUpdatedInventory[productId].stockInc;
        }
        const quantity = item.finalQuantity;
        const incommingQuantity = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId]?.inProgressTotal : 0);
        const outcommingQuantity = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId]?.inProgressTotal : 0);
        let totalOutQty;
        let balance;
        // Existing item
        if (matchingIndex >= 0) {
          totalOutQty = outcommingQuantity + quantity - orderItems[matchingIndex].finalQuantity
        } else {
          // New item
          if (quantity !== undefined && outcommingQuantity !== undefined) {
            totalOutQty = outcommingQuantity + quantity;
          }
        }
        
        if (stock !== undefined && totalOutQty !== undefined) {
          balance = stock + incommingQuantity - totalOutQty;
        }
        const price = item.finalPrice;
        const itemTotal = Number(price) * Number(quantity);
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
        let priceColor, quantityColor;

        if (matchingIndex >= 0) {
          if (item?.finalPrice !== orderItems[matchingIndex].finalPrice) {
            priceColor = "red";
          }
          if (item?.finalQuantity !== orderItems[matchingIndex].finalQuantity) {
            quantityColor = "red";
          }
        } else {
          quantityColor = priceColor = "red";
        }
        
        items.rows.push({
          className: MDBDTableClassname,
          name: matchingIndex >= 0 ? <div>
                                      <strong>{item.name}</strong>
                                      <div style={{ fontSize: '0.8em', color: '#888' }}>{product ? product.product.sku : extraInfo}</div>
                                    </div>
                                  : ProductSelection({ productList: filteredInventory, 
                                                    defaultItem: { ...item, sku: (product ? product.product?.sku : undefined) },
                                                    onChange: (product) => {
                                                      const { price : calculatedPrice } = getSellPrice(product, order.customer.tier);
                                                      Object.assign(clonedOrderItems[index], {
                                                        product: product._id,
                                                        name: product.name,
                                                        initQuantity: 1,
                                                        finalQuantity: 1,
                                                        listedPrice: calculatedPrice,
                                                        finalPrice: calculatedPrice, 
                                                      });
                                                      setFormUpdated(!formUpdated);
                                                    }
                                                  }),
          price: <EditableNumberCell readOnly={!editable} value={price} onChange={(val) => {handlePriceChange(index, val)}} color={priceColor}> </EditableNumberCell>,
          quantity: <EditableNumberCell readOnly={!editable} value={quantity} onChange={(val) => {handleQuantityChange(index, val)}} color={quantityColor}> </EditableNumberCell>,
          itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
          stock: <RightAlignedCell value={stock !== undefined ? stock : '-'} > </RightAlignedCell>,
          incommingQuantity: <RightAlignedCell value={incommingQuantity} > </RightAlignedCell>,
          outcommingQuantity: <RightAlignedCell value={totalOutQty} > </RightAlignedCell>,
          balance:  <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance} ></RightAlignedCell> </span>,
          actions: (<center>
                      {editable && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {handleRemoveItem(index);}} > </ButtonCell>)}                 
                    </center>),
        });
      });
    }
    return items;
  };

  return (
    <>
      <MetaData title={"Process Order"} />
      <HeaderAdmin title={"Process Order"}/>
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 col-lg-11 my-0 offset-lg-0">
          {/* Order # & Delete Order button */}
          <div className="row">
            <div className="col-3"/>
            {/* Order # */}
            <div className="col-7">
              <h4 className="my-1 text-center"> 
                Order#: <strong>{order.orderNumber} </strong>
                {quickbooksTransferred && (
                  <img
                    src="/images/quickbooks_icon.png"
                    alt="QuickBooks Icon"
                    style={{ height: '1em', verticalAlign: 'middle' }}
                  />
                )}

                <div className="ms-4 dropdown">
                  <button
                    type="button"
                    id="dropDownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className={"fas fa-print"}/>
                  </button>
                  <div
                    className="dropdown-menu w-100"
                    aria-labelledby="dropDownMenuButton"
                  >
                    <Link to="#"  className="dropdown-item" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); handlePrintOptionSelect('PackingSlip'); }}>Packing Slip</Link>
                    <Link to="#"  className="dropdown-item" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); handlePrintOptionSelect('Invoice'); }}>Invoice</Link>
                  </div>
                </div>
              </h4>
            </div>
            {/* Remove Order */}
            <div className="col-2 text-end">
              {deleteable && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} disabled={isDeletingOrder || showingDeleteOrderConfirm} label={'Delete Order'} action={(e) => {handleDeleteOrder();}} width={'100%'} > </ButtonCell>)}  
            </div>
          </div>
          
          {/* Customer */}
          <div className="row my-1">
            <div className="col-2">
              <h5>{`Customer: `}</h5>
            </div>
            <div className="col-7">
              <h5><strong>{order.customer?.name}</strong> - (Phone: {order.customer?.addressInfo?.phone})</h5>      
            </div>  
            <div className="col-2 text-end offset-1">
              {/* Process Order Button */}
               {rights?.some(right => right === ORDER_USER_RIGHTS.CONFIRM) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Confirm'} action={()=>{processOrderHandler(ORDER_STATUS.CONFIRMED)}} disabled={isUpdatingOrder || isTransferingToQuickbooks} width={'100%'} >  </ButtonCell>}
               {rights?.some(right => right === ORDER_USER_RIGHTS.PACK) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Pack'} action={()=>{processOrderHandler(ORDER_STATUS.PACKING)}} disabled={isUpdatingOrder || isTransferingToQuickbooks} width={'100%'}>  </ButtonCell>}
               {rights?.some(right => right === ORDER_USER_RIGHTS.FINISH_PACKING) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Finish Packing'} action={()=>{processOrderHandler(ORDER_STATUS.DELIVERY_READY)}} disabled={isUpdatingOrder || isTransferingToQuickbooks} width={'100%'}>  </ButtonCell>}  
               {rights?.some(right => right === ORDER_USER_RIGHTS.DELIVER) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Deliver'} action={()=>{processOrderHandler(ORDER_STATUS.DELIVERED)}} disabled={isDeliveringOrder || isTransferingToQuickbooks} width={'100%'}>  </ButtonCell>}
               {rights?.some(right => right === ORDER_USER_RIGHTS.TRANSFER_TO_QUICKBOOKS) && !quickbooksTransferred && <ButtonCell buttonType={"btn btn-success ms-2"} label={'Transfer to Quickbooks'} action={()=>{transferToQuickbooksHandler()}} disabled={isDeliveringOrder || isTransferingToQuickbooks} width={'100%'}>  </ButtonCell>}
            </div>
          </div>

          {/* Status & Transfer to Quickbooks button */}
          <div className="row my-1">
            <div className="col-2">
              <h5><span>{`Status: `}  </span> </h5>
            </div>
            <div className="col-8">
              <h5>
                <strong style={{ color: ORDER_STATUS_ICON[status]?.color }}>{`${String(status).toUpperCase()} `}</strong>
                { ORDER_STATUS_ICON[status] && (<i className={ORDER_STATUS_ICON[status]?.icon}  style={{ color: ORDER_STATUS_ICON[status].color }} /> )}
              </h5>      
            </div>
            <div className="col-2 text-end">
            <ButtonCell buttonType={"btn btn-success ms-2"} label={'Email Customer'} action={()=>{processEmailCustomerHandler()}} width={'100%'} >  </ButtonCell>
            </div>
          </div>

          {/* Handler */}
          <div className="row my-1">
            {/* Created by */}
            {order.createdBy && (
              <div className="col-4">
                <h5><span>{'Created by: '}<strong>{order.createdBy}</strong></span> </h5>
              </div>
            )}

            {/* Confirmed by */}
            { order.confirmedBy && (
              <div className="col-4">
                <h5><span>{'Confirmed by: '}<strong>{order.confirmedBy}</strong></span> </h5>
              </div>
            )}

            {/* Packing by */}
            {order.packedBy && order.status === ORDER_STATUS.PACKING && (
              <div className="col-4">
                <h5><span>{'Packing by: '}<strong>{order.packedBy}</strong></span> </h5>
              </div>            
            )}         
            {order.packedBy && order.status !== ORDER_STATUS.PACKING && (
              <div className="col-4">
                <h5><span>{'Packed by: '}<strong>{order.packedBy}</strong></span> </h5>
              </div>            
            )} 
          </div>

          {/* Loader */}
          {(isUpdatingOrder || isDeliveringOrder || isDeletingOrder || isTransferingToQuickbooks || isAuthorizingQuickbooks) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          {/* Pallet size */}
          {(order.status === ORDER_STATUS.DELIVERY_READY || order.status === ORDER_STATUS.DELIVERED) && (
            <div className="row my-1">
              <div className="col-2">
                <h5>Pallet Size: </h5>
              </div>
              <div className="col-2">
                <input type="number" className="form-control" value={pallet} onChange={(e) => {setPallet(Number(e.target.value)); setFormUpdated(!formUpdated);}} disabled={!editable}/>
              </div>
            </div>
          )}
           
          {/* Shipping options */}
          {(order.status === ORDER_STATUS.DELIVERY_READY || order.status === ORDER_STATUS.DELIVERED) && (
            <div className="row my-1">
              <div className="col-2">
                  <h5>Shipping: </h5>
                </div>
                <div className="col-2">
                  <select className="form-select" value={deliverType} onChange={(e) => { setDeliverType(e.target.value); setFormUpdated(!formUpdated); }} disabled={!editable}>
                    {Object.entries(DELIVERY_TYPE).map(([key, value]) => (
                      <option key={key} value={value}>{String(value).toUpperCase()}</option>
                    ))}
                  </select>
                </div>
            </div>
          )}
          {(order.status === ORDER_STATUS.DELIVERY_READY || order.status === ORDER_STATUS.DELIVERED) && deliverType === DELIVERY_TYPE.DELIVER && (
            <div className="row my-1">
              <div className="col-2">
                <h5>Driver: </h5>
              </div>
              <div className="col-2">
                <select className="form-select"  defaultValue="" value={selectedDriverId} onChange={(e) => { setSelectedDriverId(e.target.value); setFormUpdated(!formUpdated); }} disabled={!editable}>
                  <option value="" disabled="true">Select Driver</option>
                  {allDriverData && allDriverData.map(driver => (
                    <option key={driver._id} value={driver._id}>{driver.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}       

          {/* Order Items */}
          <MDBDataTable
            data={setOrders()}
            className={`my-1 px-10 ${MDBDTableClassname}`}
            bordered
            striped
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items found"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
          />
          <div className="row my-0">
            <div className="col-7 col-lg-7 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => { setNotes(e.target.value); }}
              />
              <label htmlFor="history_field">History</label>
              <textarea
                className="form-control"
                value={history}
                wrap="soft"
                style={{ 
                  height: '200px', 
                  overflow: 'auto',
                  whiteSpace: 'pre'
                }}
                readOnly
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-1">
              {/* Add Item Button */}
              {addable && (
              <div className="my-4">
                <button 
                    disabled={isUpdatingOrder || isDeliveringOrder || isTransferingToQuickbooks || !editable || !addable}
                    onClick={() => {addItemRowHandler();}} 
                    className="btn btn-primary"
                    style={{width: '100%'}}>
                    Add Item
                </button>
              </div>
              )}
              {/* Update Button */}
              {editable && (
              <div className="my-4">
                <button 
                    disabled={!thereAreValueChanges || isUpdatingOrder || isDeliveringOrder || isTransferingToQuickbooks || !editable}
                    onClick={() => {order.status === ORDER_STATUS.DELIVERED ? showUpdateDeliveredOrderConfirmDialog(true) : updateOrderHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Update
                </button>
              </div>)}

              {/* Order Summary */} 
              <div>
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="order-summary-values">
                      {units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="order-summary-values">
                      {estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="order-summary-values">
                      $
                      {itemTotal?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="order-summary-values">
                      $
                      {tax?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="order-summary-values">
                      $
                      {total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog message={`Are you sure to remove ${clonedOrderItems[itemIndexToRemove] ? clonedOrderItems[itemIndexToRemove].name :"row"}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to delete this order?`} show={showingDeleteOrderConfirm} confirm={()=>{confirmDeletingOrder()}} cancel={()=> {showDeleteOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to update a delivered order? Make sure to update on Quickbooks app too!`} show={showingUpdateDeliveredOrderConfirm} confirm={()=>{updateOrderHandler(); showUpdateDeliveredOrderConfirmDialog(false); }} cancel={()=> {showUpdateDeliveredOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialogDeliverOrder 
                       title={`Deliver Order #${order.orderNumber}`}
                       show={showingDeliveryConfirm} 
                       deliverType={deliverType}
                       selectedDriver={selectedDriverName}
                       pallet={pallet}
                       confirm={()=>{confirmDeliverOrder()}} 
                       cancel={()=> {showDeliveryConfirmDialog(false)}}> 
        </ConfirmDialogDeliverOrder>
      </AdminLayout>
    </>
  );
};

export default ProcessOrder;
