import React from "react";
import MetaData from "../layout/MetaData";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CalculateOrderSummary } from "../../utils/utilities";
import CheckoutSteps from "./CheckoutSteps";
import { useCreateNewOrderMutation } from "../../redux/api/orderApi";
import Header from "../layout/Header";

import { PAYMENT_TYPE, PAYMENT_STATUS } from "../../constants/constants.js";
import { useLazyCustomerLogoutQuery } from "../../redux/api/customerAuthApi";

const ConfirmOrder = () => {
  const { customer } = useSelector((state) => state.customerAuth);
  const { cartItems, shipInfo } = useSelector((state) => state.cart);
  const { itemTotal, shipping, tax, total, estPallet } = CalculateOrderSummary(cartItems);

  const [createNewOrder, { isLoading, error, isSuccess }] = useCreateNewOrderMutation();
  const navigate = useNavigate();
  const [customerLogout] = useLazyCustomerLogoutQuery();
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401 || error?.status === 423) {
        customerLogout();
      }
    }

    if (isSuccess) {
      navigate("/me/orders?order_success=true");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    // Create COD Order
    const orderData = {
      shipInfo,
      itemList: cartItems,
      paymentInfo: {
        paymentType: PAYMENT_TYPE.COD,
        status: PAYMENT_STATUS.PENDING,
      },
    };
    createNewOrder(orderData);
  };

  
  return (
    <>
      <MetaData title={"Confirm Order Info"} />
      <Header />
      <div className="container">
        <CheckoutSteps shipping confirmOrder />
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-8 mt-5 order-confirm"  key="shipInfo">
            <h4 className="mb-3">Shipping Info</h4>
            <p>
              <b>Name:</b> {customer?.name}
            </p>
            <p>
              <b>Phone:</b> {shipInfo?.phone}
            </p>
            <p className="mb-4">
              <b>Address:</b><br/> {shipInfo?.address} <br/> {shipInfo?.city} <br/> {shipInfo?.state}{", "} {shipInfo?.zipCode} {/*shipInfo?.country*/}
            </p>

            <hr />
            <h4 className="mt-4">Your Cart Items:</h4>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price per Unit</th>
                  <th scope="col">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {cartItems?.map((item) => (
                  <tr key={item?.product}>
                    {item.image ? (
                      <img
                        src={item.image}
                        alt=""
                        height="45"
                        width="45"
                      />
                    ) : (
                      <div style={{ height: '45px', width: '45px' }}></div>
                    )}
                    <td>
                      <Link to={`/product/${item.product}`}>{item?.name}</Link>
                    </td>
                    <td>
                      {item?.finalQuantity} (units)
                    </td>
                    <td>
                      {item.finalPrice !== undefined ? (
                        <p>${item.finalPrice}</p>
                      ) : (
                        <p>Call</p>
                      )}
                    </td>
                    <td>
                      {item.finalPrice !== undefined && (
                        <p>
                          <b>${(item?.finalQuantity * item.finalPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="col-12 col-lg-3 my-4"  key="CartInfo">
            <div id="order_summary">
              <h4>Order Summary</h4>
              <hr />
              <p>
                Subtotal:{" "}
                <span className="order-summary-values">{itemTotal !== undefined ? `$${itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}</span>
              </p>
              <p>
                Shipping:{" "}
                <span className="order-summary-values">{shipping !== undefined  ? `$${shipping.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}</span>
              </p>
              <p>
                Tax: <span className="order-summary-values">{tax !== undefined  ? `$${tax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''} </span>
              </p>

              <hr />

              <p>
                Total: <span className="order-summary-values">{total !== undefined ? `$${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'CALL'}</span>
              </p>

              {
                estPallet && (
                  <>
                    <hr />
                    <p>
                      Est. size:{" "}
                      <span className="order-summary-values">
                        {estPallet.toFixed(2)} (Pallet)
                      </span>
                    </p>
                  </>
                )
              }
            </div>
            <div>
              <button
                id="checkout_btn"
                className="btn btn-primary w-100"
                onClick={(e) => {submitHandler(e)}}
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
