import { MAX_CUSTOMER_TIER, ORDER_STATUS, PRICE_PERCENTAGE_DIFFERENCE_PER_TIER, TAX_PERCENTAGE } from "../constants/constants.js";
import { toast } from 'react-toastify';
//import nodemailer from "nodemailer";

// {units, estPallet, itemTotal, tax, shipping, total}
export const CalculateOrderSummary = (cartItems, inventory) => {
    let units = 0;//  cartItems?.reduce((acc, item) => acc + item?.finalQuantity, 0);
    let itemTotal = 0; // cartItems?.reduce((acc, item) => acc + item?.finalQuantity * item.finalPrice, 0);

    let estPallet = 0;
    let thereIsItemWithoutPrice = false;

    cartItems?.forEach((item) => {
      if (item.product && item.finalQuantity) {
        units += item.finalQuantity;
        if (item.finalPrice === undefined) {
          thereIsItemWithoutPrice = true;
        } else {
          itemTotal += item.finalQuantity * item.finalPrice;
        }

        if (estPallet !== -1 ) {
          const palletSizePerUnit = item.palletSize ? item.palletSize : inventory[item.product]?.product.palletSize;
          if (palletSizePerUnit) {
            estPallet += palletSizePerUnit * item.finalQuantity;
          } else {
            estPallet = -1;
          }
        }
      }
    });
    if (thereIsItemWithoutPrice) {
      itemTotal = undefined;
    } else {
      itemTotal = parseFloat(itemTotal.toFixed(2));
    }
    const tax =  itemTotal ? parseFloat(((itemTotal * TAX_PERCENTAGE) / 100.0).toFixed(2)) : undefined;
    const shipping = itemTotal ? 0 : undefined;
    const total = itemTotal ? itemTotal + tax + shipping : undefined;

    return {
        units, 
        estPallet: estPallet === -1 ? undefined : estPallet, 
        itemTotal, 
        tax, 
        shipping, 
        total,
    };
}

export function getSellPrice(product, tier) {
  if (!tier) {
    return product.price;
  }
  if (tier >= MAX_CUSTOMER_TIER) {
    tier = MAX_CUSTOMER_TIER;
  }
  if (tier < 1) {
    tier = 1;
  }
  const price = product.price === undefined ? undefined : parseFloat((Math.round((product.price - (product.price * (tier - 1) * PRICE_PERCENTAGE_DIFFERENCE_PER_TIER / 100.0)) * 10) / 10).toFixed(2));
  const dollars = product.price === undefined ? undefined : Math.floor(price);
  const cents = product.price === undefined ? undefined : ((price % 1).toFixed(2)).slice(2);

  return { price, dollars, cents };
}

// {units, estPallet, itemTotal, tax, shipping, total}
export const CalculatePurchaseSummary = (items, inventory) => {
  let units = 0;
  let itemTotal = 0;

  let estPallet = 0;

  items?.forEach((item) => {
    if (item.product && item.finalQuantity && item.finalPrice) {
      units += item.finalQuantity;
      itemTotal += item.finalQuantity * item.finalPrice;

      if (estPallet !== -1 ) {
        const palletSizePerUnit = item.palletSize ? item.palletSize : inventory[item.product]?.product.palletSize;
        if (palletSizePerUnit) {
          estPallet += palletSizePerUnit * item.finalQuantity;
        } else {
          estPallet = -1;
        }
      }
    }
  });

  itemTotal = parseFloat(itemTotal.toFixed(2));

  const tax = parseFloat(((itemTotal * TAX_PERCENTAGE) / 100.0).toFixed(2));
  const shipping = 0;
  const total = itemTotal + tax + shipping;

  return {
      units, 
      estPallet: estPallet === -1 ? undefined : estPallet, 
      itemTotal, 
      tax, 
      shipping, 
      total,
  };
}

export const AlertMessage = (data) => {
    toast("my message not shown", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          fontSize: '1.5em', // This will make the text size larger
        }
    });
}

// Generate email template to send to customer
export const generateOrderEmailTemplate = (order, action) => {
  const orderStatus = order.status;
  const orderId = order._id;  
  const orderNumber = order.orderNumber;
  const customerName = order.customer.name;
  
  if (!action) {
    switch (orderStatus) {
      case ORDER_STATUS.PENDING:
        action = "created";
        break;
      case ORDER_STATUS.CONFIRMED:
        action = "confirmed";
        break;
      case ORDER_STATUS.PROCESSING:
        action = "processed";
        break;
      case ORDER_STATUS.PACKING:
        action = "packed";
        break;
      case ORDER_STATUS.DELIVERED:
        action = "delivered";
        break;
      default:
        action = "updated";
    } 
  }

  const orderUrl = process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' ? `${process.env.REACT_APP_FRONTEND_URL_TEST}/me/orders/${orderId}` : `${process.env.REACT_APP_FRONTEND_URL_LIVE}/me/orders/${orderId}`;

  const body = `
    Hello ${customerName},

    Your order #${orderNumber} has been ${action}.

    You can view your order details and status by clicking the link below:
    ${orderUrl}

    If you have any questions, feel free to reply to this email.

    Thank you for shopping with us.
  
    Tom Produce Team,
  `;

  const subject = `Order with Tom Produce: #${orderNumber}`;

  return { subject, body }
}
