import React, { useEffect, useState } from "react";
//import { countries } from "countries-list";
import { useDispatch, useSelector } from "react-redux";
import { shipInfo } from "../../redux/features/cartSlice";
import { useNavigate } from "react-router-dom";
import MetaData from "../layout/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import Header from "../layout/Header";
import { US_STATES } from  "../../constants/constants.js";

const Shipping = () => {
  //const countriesList = Object.values(countries);

  const { customer } = useSelector((state) => state.customerAuth);
  const addressInfo = customer?.addressInfo;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [address, setAddress] = useState(addressInfo.address || "");
  const [city, setCity] = useState(addressInfo.city || "");
  const [state, setState] = useState(addressInfo.state || "");
  const [zipCode, setZipCode] = useState(addressInfo.zipCode || "");
  const [phone, setPhone] = useState(addressInfo.phone || "");
  //const [country, setCountry] = useState("United States");
  
  const submiHandler = (e) => {
    e.preventDefault();

    dispatch(shipInfo({ address, city, phone, zipCode, state }));
    navigate("/confirm_order");
  };

  return (
    <>
      <MetaData title={"Shipping Info"} />
      <Header />
      <div className="container">
        <CheckoutSteps shipping />

        <div className="row wrapper mb-5">
          <div className="col-10 col-lg-5">
            <form className="shadow rounded bg-body" onSubmit={submiHandler}>
              <h2 className="mb-4">Shipping Info</h2>
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="city_field" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  id="city_field"
                  className="form-control"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="city_field" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  id="city_field"
                  className="form-control"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="state_field" className="form-label">
                  State
                </label>
                <select
                  id="selection_field"
                  className="form-control"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="" disabled>Select a state</option>
                  {US_STATES.map((state) => (
                    <option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="zip_code_field" className="form-label">
                  Zip Code
                </label>
                <input
                  type="number"
                  id="zip_code_field"
                  className="form-control"
                  name="zipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone_field" className="form-label">
                  Phone No
                </label>
                <input
                  type="tel"
                  id="phone_field"
                  className="form-control"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
  { /*            
              <div className="mb-3">
                <label htmlFor="country_field" className="form-label">
                  Country
                </label>
                <select
                  id="country_field"
                  className="form-select"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  {countriesList?.map((country) => (
                    <option key={country?.name} value={country?.name}>
                      {country?.name}
                    </option>
                  ))}
                </select>
              </div>
  */}
              <button id="shipping_btn" type="submit" className="btn w-100 py-2">
                CONTINUE
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
