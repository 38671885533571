import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";

import { orderAdminApi } from "../../redux/api/orderAdminApi";
import { purchaseAdminApi } from "../../redux/api/purchaseAdminApi";
import { productsAdminApi } from "../../redux/api/productsAdminApi";
import { inventoriesAdminApi } from "../../redux/api/inventoriesAdminApi";
import { addLog } from "../../redux/features/adminSlice";

export const DatabaseTrackingStreamAdmin = () => {
  const { isAuthenticatedAdmin } = useSelector((state) => state.adminAuth);
    const dispatch = useDispatch();

    const allCollections = ['Order', 'Purchase', 'Inventory', 'Product', 'Log'];
    let socket;

    const connectSocket = () => {
      const URI = process.env.HOST_ENV === 'LOCAL' ? `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST_LOCAL}` : (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' ? `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST}` : `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_LIVE}`);
      socket = socketIOClient(URI, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        withCredentials: true,
        transports: ["websocket"],
      });

      allCollections.forEach(collection => {
        socket.off(`${collection}`);
      });

      allCollections.forEach(collection => {
        socket.on(`${collection}`, data => {
          // Handle the change here
          if (collection === 'Log') {
            toast.success(data?.note);
            dispatch(addLog(data));
            dispatch(productsAdminApi.util.invalidateTags(['Logs']));
          } else if (collection === 'Product') {
            dispatch(productsAdminApi.util.invalidateTags(['AdminProduct']));
          } else if (collection === 'Inventory') {
            dispatch(inventoriesAdminApi.util.invalidateTags(['Inventory']));
          } else if (collection === 'Order') {
            dispatch(orderAdminApi.util.invalidateTags([collection]));
          } else {
            dispatch(purchaseAdminApi.util.invalidateTags([collection]));
          }
        });
      });
    };

    const disconnectSocket = () => {
      if (socket) {
        allCollections.forEach(collection => {
          socket.off(`${collection}`);
        });
        socket.disconnect();
      }
    };    

    useEffect(() => {
      if (isAuthenticatedAdmin) {
        // Connect the socket when the component mounts
        connectSocket();
      
        // Disconnect the socket when the component unmounts
        return disconnectSocket;
      }
    }, [isAuthenticatedAdmin]);

    // Automatically disconnect and reconnect the socket when the page loses and regains focus
    useEffect(() => {
      if (isAuthenticatedAdmin) {
        const handleVisibilityChange = () => {
          if (document.hidden) {
            disconnectSocket();
          } else {
            connectSocket();
          }
        };
        window.addEventListener('visibilitychange', handleVisibilityChange);

        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }
    }, [isAuthenticatedAdmin]);
    return (<></>);
};
