import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useAdminCreateCustomerMutation } from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS, US_STATES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { validateUserPassword, validateCustomerProfileForm } from "../../../utils/validators";

const UpdateCustomer = () => {
  const [name, setName] = useState("");
  const [extraId, setExtraId] = useState("");
  const [addressInfo, setAddressInfo] = useState({state: "California"});
  const [status, setStatus] = useState(CUSTOMER_STATUS.ACTIVE);
  const [email, setEmail] = useState("");
  const [tier, setTier] = useState(1);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  const [adminCreateCustomer, { error, isSuccess, isLoading: creatingCustomer }] = useAdminCreateCustomerMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Customer Updated");
      navigate("/admin/customers");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const customer = {
      name,
      extraId, 
      email,
      status,
      tier,
      addressInfo,
      password,
      confirmPassword,
    };
    const { formIsValid, formErrors } = validateCustomerProfileForm(customer);
    const { passwordIsValid, passwordErrors } = validateUserPassword(customer);

    if (formIsValid && passwordIsValid) {
      adminCreateCustomer({ body:  customer});
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  const updateAddressHandler = (e) => {
    const clonedAddressInfo = { ...addressInfo };
    clonedAddressInfo[e.target.name] = e.target.value;
    setAddressInfo(clonedAddressInfo);
  };

  if (loadingAdmin) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"New Customer"} />
      <HeaderAdmin title={"New Customer"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.CUSTOMERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
            <div className="mb-3">
              <div className="row">
                <div className="col-4">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={CUSTOMER_STATUS.ACTIVE}>{String(CUSTOMER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.PENDING}>{String(CUSTOMER_STATUS.PENDING).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.INACTIVE}>{String(CUSTOMER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="name_field" className="form-label">
                    Tier
                  </label>
                  <select
                    id="tier_field"
                    className="form-select"
                    name="tier"
                    value={tier}
                    onChange={(e) => setTier(e.target.value)}
                  >
                    <option value={1}>Tier 1</option>
                    <option value={2}>Tier 2</option>
                    <option value={3}>Tier 3</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="name_field" className="form-label">
                    Quickbook ID
                  </label>
                  <input
                    type="name"
                    id="extraId_field"
                    className="form-control"
                    name="extraId"
                    value={extraId}
                    onChange={(e) => setExtraId(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Address */}
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={addressInfo.address}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              <div className="row">
                {/* City */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="city_field" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    id="city_field"
                    className="form-control"
                    name="city"
                    value={addressInfo.city}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>

                {/* State - California default*/}
                <div className="col-6 col-lg-6">
                  <label htmlFor="state_field" className="form-label">
                    State
                  </label>
                  <select
                    id="selection_field"
                    className="form-control"
                    name="state"
                    value={addressInfo.state}
                    defaultValue={US_STATES[4].name} 
                    onChange={(e) => { updateAddressHandler(e) }}
                  >
                    <option value="" disabled>Select a state</option>
                    {US_STATES.map((state) => (
                      <option key={state.abbreviation} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={addressInfo.phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
                {/* Zip Code */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="zipCode_field" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    id="zipCode_field"
                    className="form-control"
                    name="zipCode"
                    value={addressInfo.zipCode}
                    pattern="\d{5}"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a 5 digit zip code');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
              </div>
              {/* Email */}
              <div className="mb-3">
                <label htmlFor="email_field" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value)}}
                />  
              </div>

              <div className="row">
                {/* Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="password_field" className="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password_field"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}         
                  />
                  <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                    Password must be at least 8 characters long
                  </p>
                </div>

                {/* Confirm Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}    
                  />
                </div>
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>
              <button disabled={creatingCustomer} type="submit" className="btn update-btn w-100 py-2">
                Create
              </button>
            </div>  
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateCustomer;
