import React, { useEffect, useState,  } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MetaData from "../../layout/MetaData";

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ConfirmDialog } from "../ConfirmDialog";
import {
  useAdminDeletePurchaseMutation,
} from "../../../redux/api/purchaseAdminApi";
import { setAdminOptions } from "../../../redux/features/adminOptionsSlice";
import { ADMIN_MENU_ITEMS, PURCHASE_STATUS, PURCHASE_STATUS_ICON, PURCHASE_USER_RIGHTS } from "../../../constants/constants.js";
import { CanUserCreatePurchase, DetermineUserOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";

const ListPurchases = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [purchaseToBeDeleted, showDeletePurchaseConfirmDialog] = useState(null);

  // Redux
  const { loadingAdmin, user, viewPurchaseHistory, unviewedNewPurchase, unviewedUpdatedPurchase } = useSelector((state) => state.adminAuth);
  const { inProgressPurchaseData } = useSelector((state) => state.inProgressPurchases);
  const { adminOptions } = useSelector((state) => state.adminOptions);

  const [adminDeletePurchase, { isLoading: isDeletingPurchase, error: purchaseDeleteError, isSuccess: purchaseDeleteSuccess }] = useAdminDeletePurchaseMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [purchaseData, setPurchaseData] = useState(null);

  const [totalPurchaseCount, setTotalPurchaseCount] = useState({});

  // Default view to pending orders if there is no previous selection
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultView = searchParams.get('defaultView');

  const [purchaseFilter, setPurchaseFilter] = useState("ALL");
  const [errorLoadingPurchase, setErrorLoadingPurchase] = useState(null);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false); 

  const assignPurchaseData = () => {
    const newAdminOptions = {...adminOptions, allPurchasesStatusFilter: purchaseFilter};
    dispatch(setAdminOptions(newAdminOptions));

    const pendingCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.PENDING).length || 0;
    const orderedCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.ORDERED).length || 0;
    const confirmedCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.CONFIRMED).length || 0;
    const deliveredCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.DELIVERED).length || 0;
    const allCount = inProgressPurchaseData?.purchases?.length || 0;

    setTotalPurchaseCount({
      [PURCHASE_STATUS.PENDING]: pendingCount,
      [PURCHASE_STATUS.ORDERED]: orderedCount,
      [PURCHASE_STATUS.CONFIRMED]: confirmedCount,
      [PURCHASE_STATUS.DELIVERED]: deliveredCount,
      ALL: allCount,
    });

    switch (purchaseFilter) {
      case PURCHASE_STATUS.PENDING:
        const pendingPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.PENDING)};
        setPurchaseData(pendingPurchaseData);
        break;
      case PURCHASE_STATUS.ORDERED:
        const orderedPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.ORDERED)};
        setPurchaseData(orderedPurchaseData);
        break;
      case PURCHASE_STATUS.CONFIRMED:
        const confirmedPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.CONFIRMED)};
        setPurchaseData(confirmedPurchaseData);
        break;
      case PURCHASE_STATUS.DELIVERED:
        const deliveredPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.DELIVERED)};
        setPurchaseData(deliveredPurchaseData);
        break;
      case "ALL":
        setPurchaseData(inProgressPurchaseData);
        break
      default:
        break;
    }
  }

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  
  useEffect(() => {
    assignPurchaseData();
  }, [inProgressPurchaseData]);

  useEffect(() => {
    // Save selection
    const newAdminOptions = {...adminOptions, allPurchaseStatusFilter: purchaseFilter};
    dispatch(setAdminOptions(newAdminOptions));
    assignPurchaseData();
  }, [purchaseFilter]);


  useEffect(() => {
    if (errorLoadingPurchase) {
      toast.error(errorLoadingPurchase?.data?.message);
      if (errorLoadingPurchase.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingPurchase]);

  useEffect(() => {
    if (purchaseDeleteError) {
      toast.error(purchaseDeleteError?.data?.message);
    }
    /*
    if (purchaseDeleteSuccess) {
      toast.success("Purchase Removed");
    }*/
  }, [purchaseDeleteError, purchaseDeleteSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the purchase, user are not loaded yet, show the loader
  if (user === null || loadingAdmin || purchaseData === null) {
    return <Loader />;
  }

  //--------------------------------- Functions ---------------------------------

  // Delete purchase
  const handleDeletePurchase = (purchase) => {
    if (!isDeletingPurchase && purchase !== null) showDeletePurchaseConfirmDialog(purchase);
  }

  // Confirm deleting purchase
  const confirmDeletingPurchase = () => {
    if (!isDeletingPurchase && purchaseToBeDeleted !== null) {
      adminDeletePurchase(purchaseToBeDeleted._id);
      showDeletePurchaseConfirmDialog(null);
    }
  }

  // Create new purchase
  const createNewPurchaseHandler = () => {
    // Redirect to create new purchase page
    navigate("/admin/purchases/new");
  }

  const navigateToPurchasesPerVendorPage = (vendorId) => {
    if (vendorId) {
      navigate(`/admin/vendors/${vendorId}/purchases`);
    }
  }

  const checkPurchaseStatus = (purchase) => {
    const isNew =  viewPurchaseHistory && viewPurchaseHistory[purchase._id] === undefined;
    const updated = viewPurchaseHistory && viewPurchaseHistory[purchase._id]?.timestamp < new Date(purchase.updatedAt).getTime();

    return {isNew, updated};
  }

  const canCreateNewPurchase = CanUserCreatePurchase(user);

  const setPurchases = () => {
    const purchases = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Vendor"}> </CenterAlignedCell>,
          field: "vendor",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Purchase Status"}> </CenterAlignedCell>,
          field: "purchaseStatus",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Time"}> </CenterAlignedCell>,
          field: "time",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
        },
      ],
      rows: [],
    };

    purchaseData?.purchases?.forEach((purchase) => {
      // Check if user has rights to delete purchase
      const {rights} = DetermineUserOrderRights(user, purchase.status);
      const deleteable = rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE);
      const { isNew, updated } = checkPurchaseStatus(purchase);

      purchases.rows.push({
        id: purchase.purchaseNumber,
        vendor: (            
          <div key={purchase._id}  onClick={() => navigateToPurchasesPerVendorPage(purchase.vendor?._id)}>
            <text style={{color: 'blue'}}>{purchase.vendor?.name}</text>
          </div>
        ),
        purchaseStatus: <span>{ PURCHASE_STATUS_ICON[purchase.status] && (<i className={PURCHASE_STATUS_ICON[purchase.status].icon}  style={{ color: PURCHASE_STATUS_ICON[purchase.status].color }} /> )} {String(purchase?.status).toUpperCase()}</span>,
        time: new Date(purchase?.createdAt).toLocaleString(),
        actions: (
          <center>
            <Link
              to={`/admin/purchases/${purchase?._id}`}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>

            { deleteable && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => handleDeletePurchase(purchase)}
              disabled={isDeletingPurchase || purchaseToBeDeleted !== null }
            >
              <i className="fa fa-trash"></i>
            </button>)}
          </center>
        ),
        note: 
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {isNew && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', width: '100%' }}>New</div>
          )}

          {!isNew && updated && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'orange', width: '100%' }}>Updated</div>
          )}
        </div>,
      });
    });

    return purchases;
  };

  const appendPurchaseOption = (status) => {
    let totalUnviewedNewPurchase = 0;
    let totalUnviewedUpdatedPurchase = 0;
    let totalCount = 0;
    if (status === "ALL") {
      Object.keys(unviewedNewPurchase).forEach((key) => {
        totalUnviewedNewPurchase += unviewedNewPurchase[key];
      });
      Object.keys(unviewedUpdatedPurchase).forEach((key) => {
        totalUnviewedUpdatedPurchase += unviewedUpdatedPurchase[key];
      });
      totalCount = totalPurchaseCount["ALL"] ? totalPurchaseCount["ALL"] : 0;
    } else {
      totalCount = totalPurchaseCount[status]
      totalUnviewedNewPurchase = unviewedNewPurchase[status] ? unviewedNewPurchase[status] : 0;
      totalUnviewedUpdatedPurchase = unviewedUpdatedPurchase[status] ? unviewedUpdatedPurchase[status] : 0
    }
    let leftString = `${String(status).toUpperCase()}`;
    const unviewedNewString = totalUnviewedNewPurchase > 0 ? `(${totalUnviewedNewPurchase} New)` : "";
    const unviewUpdatedString = totalUnviewedUpdatedPurchase > 0 ? `(${totalUnviewedUpdatedPurchase} Updates)` : "";
    const rightString = `${unviewedNewString}${unviewUpdatedString}`;
    if (totalCount > 0) {
      leftString = leftString + ` (${totalCount})`;
    }
    const optionString = rightString.length > 0 ? `${leftString}\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0${rightString}` : leftString;

    return (
      <option value={status}>
        { optionString }
      </option>
    );
  }

  return (
    <>
      <MetaData title={"All Purchases"} />
      <HeaderAdmin title={"All Purchases"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PURCHASES.name}>
        <div id = "purchase_table" className="col-11 col-lg-11 my-0 offset-lg-0">
          <div className="col-5">
            <select
              id="type_field"
              className="form-select"
              name="purchaseFilter"
              value={purchaseFilter}
              onChange={(e) => setPurchaseFilter(e.target.value)}
            >
              {appendPurchaseOption("ALL")}
              {appendPurchaseOption(PURCHASE_STATUS.PENDING)}
              {appendPurchaseOption(PURCHASE_STATUS.ORDERED)}
              {appendPurchaseOption(PURCHASE_STATUS.CONFIRMED)}
              {appendPurchaseOption(PURCHASE_STATUS.DELIVERED)}
            </select>
          </div>
          <div className="row my-3">
            <h1 className="col-4 col-lg-4 ">{purchaseData?.purchases?.length} Purchases</h1>
            <div className="col-3 col-lg-3 offset-lg-5">
              {/*New purchase Button - rigjht */}
              {canCreateNewPurchase && (
              <button 
                onClick={() => {createNewPurchaseHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Purchase
              </button>)}             
            </div>
          </div>
          <MDBDataTable
            data={setPurchases()}
            className="px-10 myMDBDataListPurchaseTableRow"
            bordered
            striped
            hover
            noBottomColumns
          />
          <ConfirmDialog message={`Are you sure to delete purchase #${purchaseToBeDeleted?.purchaseNumber}?`} show={purchaseToBeDeleted !== null} confirm={()=>{confirmDeletingPurchase()}} cancel={()=> {showDeletePurchaseConfirmDialog(null)}}> </ConfirmDialog>
        </div>
      </AdminLayout>
      {isLoadingPurchase && <Loader />}
    </>
  );
};

export default ListPurchases;
