import { createSlice } from "@reduxjs/toolkit";
import { PURCHASE_STATUS } from "../../constants/constants.js";

/*
// Load purchases from local storage
let inventoryDataPurchasesByProduct = localStorage.getItem("inventoryDataPurchasesByProduct");
let loadingInventoryDataPurchasesByProduct = true;

if (inventoryDataPurchasesByProduct) {
  try {
    inventoryDataPurchasesByProduct = JSON.parse(inventoryDataPurchasesByProduct);
    loadingInventoryDataPurchasesByProduct = false
  } catch (e) {
    console.error("Invalid purchase JSON:", e);
    inventoryDataPurchasesByProduct = null;
  }
}*/

const initialState = {
  inProgressPurchaseData: null,
  inventoryDataPurchasesByProduct: null,
  loadingInventoryDataPurchasesByProduct: true,
  inventoryDataPurchasesByProductTimestamp: null,
};

export const inProgressPurchasesSlice = createSlice({
  initialState,
  name: "inProgressPurchasesSlice",
  reducers: {
    setInprogressPurchaseData(state, action) {
      state.inProgressPurchaseData = action.payload;    
    },
    setInventoryDataPurchasesByProduct(state, action) {
      state.inventoryDataPurchasesByProduct = action.payload.purchasesByProduct;
      state.inventoryDataPurchasesByProductTimestamp = action.payload.collectionTimestamp;
      // Store to local storage
      // localStorage.setItem("inventoryDataPurchasesByProduct", JSON.stringify(state.inventoryDataPurchasesByProduct));
    },
    setLoadingInventoryDataPurchasesByProduct(state, action) {
      state.loadingInventoryDataPurchasesByProduct = action.payload;
    },
  },
});

export default inProgressPurchasesSlice.reducer;

export const { setInventoryDataPurchasesByProduct, setLoadingInventoryDataPurchasesByProduct, setInprogressPurchaseData } = inProgressPurchasesSlice.actions;
