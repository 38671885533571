import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useAdminUpdatePasswordMutation } from "../../../redux/api/adminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";

import AdminProfileLayout from "../../layout/AdminProfileLayout";
import MetaData from "../../layout/MetaData";
import { validateUserPassword } from "../../../utils/validators";
import HeaderAdmin from "../../layout/HeaderAdmin";

const UpdateAdminPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const [updatePassword, { isLoading, error, isSuccess }] = useAdminUpdatePasswordMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }

    if (isSuccess) {
      toast.success("Password Updated. Please login again!");
      adminLogout();
      navigate("/admin/");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const user = {  
      password,
      confirmPassword,
    };
    const { passwordIsValid, passwordErrors } = validateUserPassword(user);

    if (passwordIsValid) {
      const adminData = {
        oldPassword,
        password,
        confirmPassword,
      };
      updatePassword(adminData);
    } else {
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  return (
    <>
      <MetaData title={"Update Password"} />
      <HeaderAdmin />
      <div className="container">
        <AdminProfileLayout title="Update Password">
          <div className="row wrapper">
            <div className="col-10 col-lg-8">
              <form className="shadow rounded bg-body" onSubmit={submitHandler}>
                <h2 className="mb-4">Update Password</h2>
                <div className="mb-3">
                  <label htmlFor="old_password_field" className="form-label">
                    Old Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="old_password_field"
                    className="form-control"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="new_password_field" className="form-label">
                    New Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="new_password_field"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                </span>

                <button
                  type="submit"
                  className="btn update-btn w-100"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update Password"}
                </button>
              </form>
            </div>
          </div>
        </AdminProfileLayout>
      </div>
    </>
  );
};

export default UpdateAdminPassword;
