import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setInventoryDataPurchasesByProduct, setLoadingInventoryDataPurchasesByProduct, setInprogressPurchaseData } from "../features/inProgressPurchasesSlice.js";
import { PURCHASE_STATUS } from "../../constants/constants.js";
import { setAdmin } from "../features/adminSlice.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const purchaseAdminApi = createApi({
  reducerPath: "purchaseAdminApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1/admin"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Purchase", "PurchaseFinished", "Inventory"],
  endpoints: (builder) => ({
    // New Purchase
    adminCreateNewPurchase: builder.mutation({
      query(body) {
        return {
          url: "/purchases/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase"],
    }),
    // Purchase details
    adminPurchaseDetails: builder.query({
      query: (id) => `/purchases/${id}`,
      providesTags: (result, error, id) => [{ type: 'Purchase', id }],
      transformResponse: (result) => result.purchase,
    }),
    // Dashboard purchases
    adminGetDashboardPurchases: builder.query({
      query: ({ startDate, endDate }) => `/getpurchasesinrange/?startDate=${startDate}&endDate=${endDate}`,
    }),
    // Get in-progress Purchases
    adminGetInProgressPurchases: builder.query({
      query: () => `/purchasesinprogress`,
      providesTags: ["Purchase"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {  
        try {
            const { data } = await queryFulfilled;
            const purchaseStatsHash = {};
            let collectionTimestamp = 0;
            // Convert to hash map - key is product id
            const purchasesByProduct = data.purchases.reduce((acc, purchase) => {
              const timestamp = new Date(purchase.updatedAt).getTime();
              collectionTimestamp = Math.max(timestamp, collectionTimestamp);
              purchaseStatsHash[purchase._id] = {timestamp, status: purchase.status};
              const purchaseId = purchase._id;
              const vendor = purchase.vendor;
              if (purchase.purchaseItems) {
                purchase.purchaseItems.forEach(item => {
                  const productId = item.product.toString();
                  if (!acc[productId]) {
                    acc[productId] = {total: 0, inProgressTotal: 0, purchases: []}; 
                  }
                  acc[productId].purchases.push({ purchaseId, vendor, price: item.finalPrice, quantity: item.finalQuantity, status: purchase.status});
                  acc[productId].total += item.finalQuantity;
                  if (purchase.status !== PURCHASE_STATUS.DELIVERED) {
                    acc[productId].inProgressTotal += item.finalQuantity;
                  }
                });
              }
              return acc;
            }, {});
            dispatch(setInprogressPurchaseData(data));
            dispatch(setInventoryDataPurchasesByProduct({purchasesByProduct, collectionTimestamp}));
            dispatch(setLoadingInventoryDataPurchasesByProduct(false));
        } catch (error) {
            dispatch(setLoadingInventoryDataPurchasesByProduct(false));
            console.log(error);
        }
      },
    }),
    // Get finished purchases
    adminGetFinishedPurchases: builder.query({
      query: () => `/purchasesfinished`,
      providesTags: ["PurchaseFinished"],
    }),
    // Get purchases by vendor Id - inProgress and delivered
    adminGetPurchasesByVendor: builder.query({
      query: (id) => `/vendors/${id}/purchases`,
      providesTags: ["Purchase"],
    }),
    // Update Purchase
    adminUpdatePurchase: builder.mutation({
      query({ id, body }) {
        //console.log("Updating Purchase ...", body);
        return {
          url: `/purchases/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase"],
    }),
    // Receive Purchase
    adminReceivePurchase: builder.mutation({
      query({ id }) {
        //console.log("Updating Purchase ...", body);
        return {
          url: `/purchases/${id}/receive`,
          method: "PUT",
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase", "Inventory"],
    }),
    // Delete purchase
    adminDeletePurchase: builder.mutation({
      query(id) {
        //console.log('Removing Purchase ...', id)
        return {
          url: `/purchases/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Purchase"],
    }),
    // Get purchases by product Id
    adminGetPurchasesByProduct: builder.query({
      query: (id) => `/purchasesinprogress/product/${id}`,
      transformResponse: (result) => result.purchases,
      providesTags: ["Purchase"],
    }),
    // Update purchases by product Id
    adminUpdatePurchasesByProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/purchasesinprogress/product/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Purchase"],
    }),
    // Remove purchase item by product Id
    adminRemovePurchaseItemByProduct: builder.mutation({
      query({ productId, purchaseId }) {
        return {
          url: `/purchasesinprogress/product/${productId}/remove/${purchaseId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Purchase"],
    }),
    // Mark purchase as viewed
    adminMarkPurchaseAsViewed: builder.mutation({
      query({ purchaseId, body }) {
        return {
          url: `/purchases/${purchaseId}/markasviewed`,
          method: "PUT",
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        console.log("Started", args);
        try {
          const result = await queryFulfilled;
          if (result && result.data?.data) {
            dispatch(setAdmin(result.data.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useAdminCreateNewPurchaseMutation,
  useAdminPurchaseDetailsQuery,
  useLazyAdminGetDashboardPurchasesQuery,
  useAdminUpdatePurchaseMutation,
  useAdminReceivePurchaseMutation,
  useAdminDeletePurchaseMutation,

  useLazyAdminGetInProgressPurchasesQuery,
  useLazyAdminGetFinishedPurchasesQuery,

  useAdminGetPurchasesByProductQuery,
  useAdminGetPurchasesByVendorQuery,
  useAdminUpdatePurchasesByProductMutation,
  useAdminRemovePurchaseItemByProductMutation,

  useAdminMarkPurchaseAsViewedMutation,
} = purchaseAdminApi;
