import { createSlice } from "@reduxjs/toolkit";

/*
// Load inventory from local storage
let inventory = localStorage.getItem("inventory");
let loadingInventory = true;

if (inventory) {
  try {
    inventory = JSON.parse(inventory);
    loadingInventory = false;
  } catch (e) {
    console.error("Invalid inventory JSON:", e);
    inventory = null;
  }
}
*/

const initialState = {
  inventory: null,
  loadingInventory: true,
};

export const inventorySlice = createSlice({
  initialState,
  name: "inventorySlice",
  reducers: {
    setInventory(state, action) {
      state.inventory = action.payload;
      // Store to local storage
      //localStorage.setItem("inventory", JSON.stringify(state.inventory));
    },
    setLoadingInventory(state, action) {
      state.loadingInventory = action.payload;
    },
    updateInventory(state, action) {
      const { id, body } = action.payload;
      const inventory = state.inventory[id];
      if (inventory) {
        state.inventory[id] = { ...inventory, ...body };
      }
      //localStorage.setItem("inventory", JSON.stringify(state.inventory));
    }
  },
});

export default inventorySlice.reducer;

export const { setInventory, setLoadingInventory, updateInventory } = inventorySlice.actions;
