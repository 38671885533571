import React,  { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";

import MetaData from "../../layout/MetaData";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import Loader from "../../layout/Loader";
import { EditableNumberCell, ButtonCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { ADMIN_MENU_ITEMS, PURCHASE_STATUS, VENDOR_STATUS } from "../../../constants/constants.js";
import { useAdminCreateNewPurchaseMutation } from "../../../redux/api/purchaseAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { useLazyAdminGetPriceListQuery } from "../../../redux/api/priceListAdminApi";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import { CalculatePurchaseSummary } from "../../../utils/utilities";
import { ConfirmDialog } from "../ConfirmDialog";
import { ProductSelection } from "../../product/ProductSelection";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { VendorSelection } from "../../vendor/VendorSelection";
import { useAdminGetAllVendorsQuery } from '../../../redux/api/adminApi';

const CreatePurchase = () => {
  const navigate = useNavigate();

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);
  const [ adminGetPriceListQuery, { data: vendorPriceListData, isLoading: isLoadingVendorPriceListData } ] = useLazyAdminGetPriceListQuery();

  // state
  const [formUpdated, setFormUpdated] = useState(false);
  const [showingCancelNewPurchaseConfirm, showCancelNewPurchaseConfirmDialog] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [purchase, setPurchase] = useState({itemList: []});
  const [notes, setNotes] = useState("");
  const [vendorPriceHash, setVendorPriceHash] = useState({});
  const [reloadingVendorPriceData, setReloadingVendorPriceData] = useState(false);

  // Hooks
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminCreateNewPurchase, {data: createdPurchase, isLoading: isCreatingNewPurchase, error: purchaseCreateError, isSuccess: purchaseCreateSuccess }] = useAdminCreateNewPurchaseMutation();
  const { data: vendorsData, isLoading: isLoadingVendor } = useAdminGetAllVendorsQuery({params: {status: VENDOR_STATUS.ACTIVE}, refetchOnMountOrArgChange: true});
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();

  useEffect(() => {
    if (vendorsData) {
      setVendors(vendorsData);
    }
  }, [vendorsData]);

  useEffect(() => {
    if (vendorPriceListData) {
      const priceHash = {};
      vendorPriceListData.forEach((item) => {
        priceHash[item.product] = item.price;
      });

      // Update the prices of the items in the purchase
      purchase.itemList?.forEach((item) => {
        const productId = item.product;
        const price  = priceHash ? priceHash[productId] : undefined;
        item.finalPrice = item.initPrice = price;
      });

      setVendorPriceHash(priceHash);
    }
    setReloadingVendorPriceData(false);
  }, [vendorPriceListData]);    

  useEffect(() => {
    if (purchaseCreateError) {
      toast.error(purchaseCreateError?.data?.message);
      if (purchaseCreateError.status === 401) {
        adminLogout();
      }
    }
    else if (purchaseCreateSuccess && createdPurchase) {
      const purchaseId = createdPurchase.purchase._id;
      navigate(`/admin/purchases/${purchaseId}`);
    }
  }, [purchaseCreateError, purchaseCreateSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the Purchase, inventory, user are not loaded yet, show the loader
   if (isLoadingVendor || isLoadingInventory  || loadingAdmin || user === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------
  // Remove item
  const removeItem = (index) => {
    if (index >= 0) {
      purchase.itemList.splice(index, 1);
      setPurchase(purchase);
      setFormUpdated(!formUpdated);
    }
  }

  // Cancel
  const handleCancelNewPurchase = () => {
    showCancelNewPurchaseConfirmDialog(true);
  };

  // Confirm cancel new Purchase
  const confirmCancelNewPurchase = () => {
    showCancelNewPurchaseConfirmDialog(false);
    navigate("/admin/purchases");
  }

  // Creating Purchase
  const createNewPurchaseHandler = () => {
    if (purchase.vendor === undefined) {
      toast.error("Please select a vendor.");
      return;
    }

    // Check if there is item with no price
    const incompleteItem = purchase.itemList?.find(item => item.name && (item.finalPrice === undefined || item.finalPrice === ""));
    if (incompleteItem) {
      toast.error(`${incompleteItem.name} has no price or not in vendor's Price List.`);
      return;
    }
    // Clean up the cloned Purchase items
    const filteredList = purchase.itemList.filter(item => item.name && item.finalPrice >= 0 && item.finalQuantity);

    // Check if the Purchase is empty
    if (filteredList.length === 0) {
      toast.error("Please add items to the purchase.");
      return;
    }

    adminCreateNewPurchase({ vendorId: purchase.vendor, itemList: filteredList, notes });
  };

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && purchase.itemList[index].finalPrice !== value) {
      purchase.itemList[index].finalPrice = Number(value);
      setFormUpdated(!formUpdated);
    }
  };
    
  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && purchase.itemList[index].finalQuantity !== value) {
      purchase.itemList[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  // Set product
  const setProduct = (index, product) => {
    const price = vendorPriceHash && vendorPriceHash[product._id] ? parseFloat(vendorPriceHash[product._id].toFixed(2)) : "";
    Object.assign(purchase.itemList[index], {
      product: product._id,
      name: product.name,
      initQuantity: 1,
      finalQuantity: 1,
      initPrice: price,
      finalPrice: price,
    });
    setFormUpdated(!formUpdated);
  }

  // Handle vendor select
  const handleVendorSelect = (vendor) => {
    if (purchase.vendor !== vendor._id) {
      // Get the price list of the selected vendor. Will update prices after receiving the price list
      adminGetPriceListQuery(vendor._id);
      purchase.vendor = vendor._id; 
      // reset prices for now
      purchase.itemList?.forEach((item) => {
        item.finalPrice = item.initPrice = "";
      });
      setFormUpdated(!formUpdated);
      setReloadingVendorPriceData(true);
    }
  }

  // Add item row
  const addItemRowHandler = () => {
    if (purchase.vendor === undefined) {
      toast.error("Please select a vendor.");
      return;
    }
    purchase.itemList.push({});
    setFormUpdated(!formUpdated);
  };

  const {units, estPallet, itemTotal, tax, total} = CalculatePurchaseSummary(purchase.itemList, inventory);
  const standardColumnWidth = '8vw';
  const setPurchases = () => {
    const items = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: '20vw',
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Quantity",
          field: "quantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Item Total",
          field: "itemTotal",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Action",
          field: "actions",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Stock",
          field: "stock",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Inbound",
          field: "incommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Outbound",
          field: "outcommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: "Balance",
          field: "balance",
          sort: "asc",
          width: standardColumnWidth,
        }
      ],
      rows: [],
    };  

    // Clone the inventory object
    let filteredInventory = { ...inventory };
    // Remove entries that are already in purchase.itemList
    // Only allow adding new items 
    purchase.itemList?.forEach(item => {
        if (item.product in filteredInventory) {
            delete filteredInventory[item.product];
        }
    })

    purchase.itemList?.forEach((item, index) => {
      const productId = item.product;
      const product = productId && inventory ? inventory[productId] : undefined;
      const stock = product?.quantity;
      const quantity = item.finalQuantity;
      const incommingQuantity = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId]?.inProgressTotal : 0);
      const outcommingQuantity = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId]?.inProgressTotal : 0);

      let totalInQty;
      let balance;
      // Existing item
      if (quantity !== undefined && incommingQuantity !== undefined) {
        totalInQty = incommingQuantity + quantity;
      }
      if (totalInQty !== undefined && stock !== undefined) {
        balance = stock - outcommingQuantity + totalInQty;
      }
      const price = item.finalPrice;
      //const priceString = price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const itemTotal =Number(price) * Number(quantity);
      const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
      
      items.rows.push({
        className: 'myMDBDataProcessOrderTableRow',
        name: ProductSelection({ productList: filteredInventory, defaultItem: { ...item, sku: (product ? product.product.sku : "")} , onChange: (product) => {
                setProduct(index, product);
              }}),
        price: <EditableNumberCell  value={price} onChange={(val) => {handlePriceChange(index, val)}} > </EditableNumberCell>,
        quantity: <EditableNumberCell  value={quantity} onChange={(val) => {handleQuantityChange(index, val)}}> </EditableNumberCell>,
        itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
        stock: <RightAlignedCell value={stock} > </RightAlignedCell>,
        incommingQuantity: <RightAlignedCell value={totalInQty} > </RightAlignedCell>,
        outcommingQuantity: <RightAlignedCell value={outcommingQuantity} > </RightAlignedCell>,
        balance: <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance} ></RightAlignedCell> </span>,
        actions: <center>
                  <ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {removeItem(index);}} > </ButtonCell>                
                </center>,
      });
    });

    return items;
  };

  return (
    <>
      <MetaData title={"Create Purchase"} />
      <HeaderAdmin title={"Create Purchase"} />
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.PURCHASES.name}>
        <div id = "purchase_table" className="col-11 col-lg-11 my-0 offset-0">
          <div className="row my-0 ">

            <div className="col-10 col-lg-10 my-0 offset-1 text-center">
              <h1>Create New Purchase</h1>
            </div>
            <div className="col-1 col-lg-1 my-0 offset-0">
              {/* Cancel Purchase Button - right */}
              <ButtonCell buttonType={"btn btn-outline-danger ms-2"} disabled={showingCancelNewPurchaseConfirm} label={'Cancel'} action={(e) => {handleCancelNewPurchase();}} > </ButtonCell>
            </div>
          </div>
          <h5 className="my-1 text-left"> Vendor: {VendorSelection({ vendors , onChange: (vendor)=> {handleVendorSelect(vendor)}})}</h5>
          {/* Loader */}
          {(isLoadingVendorPriceListData || reloadingVendorPriceData) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          {/* Purchase Items */}
          <MDBDataTable
            data={setPurchases()}
            className="px-10 myMDBDataProcessOrderTableRow"
            bordered
            striped
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items added"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
          />
          <div className="row my-0">
            <div className="col-6 col-lg-6 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-2">
              {/* Add Item Button */}
              <div className="my-4">
                <button 
                    disabled={isCreatingNewPurchase}
                    onClick={() => {addItemRowHandler();}} 
                    className="btn btn-primary"
                    style={{width: '100%'}}>
                    Add Item
                </button>
              </div>
              {/* Confirm Create Purchase */}
              <div className="my-4">
                <button 
                    disabled={isCreatingNewPurchase || purchase.itemList.length === 0}
                    onClick={() => {createNewPurchaseHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Create Purchase
                </button>
              </div>

              {/* Purchase Summary */} 
              <div>
                <div id="purchase_summary">
                  <h4>Purchase Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="purchase-summary-values">
                      {units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="purchase-summary-values">
                      {estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="purchase-summary-values">
                      $
                      {itemTotal?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="purchase-summary-values">
                      $
                      {tax?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="purchase-summary-values">
                      $
                      {total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog message={`Are you sure to cancel?`} show={showingCancelNewPurchaseConfirm} confirm={()=>{confirmCancelNewPurchase()}} cancel={()=> {showCancelNewPurchaseConfirmDialog(false)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default CreatePurchase;
