import React, { useEffect, useState,  } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import MetaData from "../../layout/MetaData";

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ConfirmDialog } from "../ConfirmDialog";
import {
  useAdminDeletePurchaseMutation,
  useAdminGetPurchasesByVendorQuery,
} from "../../../redux/api/purchaseAdminApi";
import { useAdminGetVendorDetailsQuery } from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, PURCHASE_STATUS, PURCHASE_STATUS_ICON, PURCHASE_USER_RIGHTS } from "../../../constants/constants.js";
import { CanUserCreatePurchase, DetermineUserPurchaseRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";

const ListPurchasesPerVendor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const vendorId = params?.id;

  console.log("vendorId: ", vendorId)

  const [purchaseToBeDeleted, showDeletePurchaseConfirmDialog] = useState(null);

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data: purchaseData, isLoading: isLoadingPurchase, error: errorLoadingPurchase } = useAdminGetPurchasesByVendorQuery(vendorId, { refetchOnMountOrArgChange: true });
  const { data: vendorData, isLoading: isLoadingVendor } = useAdminGetVendorDetailsQuery(vendorId, { refetchOnMountOrArgChange: true });
  const [adminDeletePurchase, { isLoading: isDeletingPurchase, error: purchaseDeleteError, isSuccess: purchaseDeleteSuccess }] = useAdminDeletePurchaseMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();

  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    if (vendorData?.vendor) {
      setVendor(vendorData.vendor);
    }
  }, [vendorData]);

  useEffect(() => {
    if (errorLoadingPurchase) {
      toast.error(errorLoadingPurchase?.data?.message);
      if (errorLoadingPurchase.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingPurchase]);

  useEffect(() => {
    if (purchaseDeleteError) {
      toast.error(purchaseDeleteError?.data?.message);
    }

    if (purchaseDeleteSuccess) {
      toast.success("Purchase Removed");
    }
  }, [purchaseDeleteError, purchaseDeleteSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the purchase, inventory, user are not loaded yet, show the loader
  if (user === null || isLoadingVendor || loadingAdmin || isLoadingPurchase || vendor === null) {
    return <Loader />;
  }

  //--------------------------------- Functions ---------------------------------

  // Delete purchase
  const handleDeletePurchase = (purchase) => {
    if (!isDeletingPurchase && purchase !== null) showDeletePurchaseConfirmDialog(purchase);
  }



  // Confirm deleting purchase
  const confirmDeletingPurchase = () => {
    if (!isDeletingPurchase && purchaseToBeDeleted !== null) {
      adminDeletePurchase(purchaseToBeDeleted._id);
      showDeletePurchaseConfirmDialog(null);
    }
  }

  // Create new purchase
  const createNewPurchaseHandler = () => {
    // Redirect to create new purchase page
    navigate(`/admin/purchases/new/${vendorId}`);
  }

  const canCreateNewPurchase = CanUserCreatePurchase(user);

  const setPurchases = () => {
    const purchases = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Decription"}> </CenterAlignedCell>,
          field: "description",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Purchase Status"}> </CenterAlignedCell>,
          field: "purchaseStatus",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Time"}> </CenterAlignedCell>,
          field: "time",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Invoice"}> </CenterAlignedCell>,
          field: "invoice",
          sort: "asc",
        },        
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },  
      ],
      rows: [],
    };

    purchaseData?.purchases?.forEach((purchase) => {
      // Check if user has rights to delete purchase
      const {rights} = DetermineUserPurchaseRights(user, purchase.status);
      const deleteable = rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE);
      const updateable = rights?.some(right => right === PURCHASE_USER_RIGHTS.UPDATE);
      const receiveable =   rights?.some(right => right === PURCHASE_USER_RIGHTS.RECEIVE);

      purchases.rows.push({
        id: purchase.purchaseNumber,
        vendor: purchase?.vendor?.name,
        purchaseStatus: <span>{ PURCHASE_STATUS_ICON[purchase.status] && (<i className={PURCHASE_STATUS_ICON[purchase.status].icon}  style={{ color: PURCHASE_STATUS_ICON[purchase.status].color }} /> )} {String(purchase?.status).toUpperCase()}</span>,
        time: new Date(purchase?.createdAt).toLocaleString(),
        actions: (
          <center>
            { updateable && (
              <Link
                to={`/admin/purchases/${purchase?._id}`}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-pencil"></i>
              </Link>)
            }
            { deleteable && (
              <button
                className="btn btn-outline-danger ms-2"
                onClick={() => handleDeletePurchase(purchase)}
                disabled={isDeletingPurchase || purchaseToBeDeleted !== null }
              >
                <i className="fa fa-trash"></i>
              </button>)
            }
            { /*receiveable && (
              <button
                className="btn btn-outline-success ms-2"
                onClick={() => handleReceivePurchase(purchase)}
                disabled={isDeletingPurchase || purchaseToBeDeleted !== null }
              >
                <i className="fa fa-truck"></i>
              </button>)
            */}
          </center>
        ),
      });
    });

    return purchases;
  };

  return (
    <>
      <MetaData title={`Purchases - ${vendor.name}`} />
      <HeaderAdmin title={`Purchases - ${vendor.name}`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PURCHASES.name}>
        <div id = "purchase_table" className="col-11 col-lg-11 my-0 offset-lg-0">
          <div className="row my-3">
            <h1 className="col-4 col-lg-4 ">{purchaseData?.purchases?.length} Purchases</h1>
            <div className="col-3 col-lg-3 offset-lg-5">
              {/*New purchase Button - rigjht */}
              {canCreateNewPurchase && (
              <button 
                onClick={() => {createNewPurchaseHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Purchase
              </button>)}             
            </div>
          </div>
          <MDBDataTable
            data={setPurchases()}
            className="px-10 myMDBDataListPurchaseTableRow"
            bordered
            striped
            hover
            noBottomColumns
          />
          <ConfirmDialog message={`Are you sure to delete purchase #${purchaseToBeDeleted?.purchaseNumber}?`} show={purchaseToBeDeleted !== null} confirm={()=>{confirmDeletingPurchase()}} cancel={()=> {showDeletePurchaseConfirmDialog(null)}}> </ConfirmDialog>
        </div>
      </AdminLayout>
      {isLoadingPurchase && <Loader />}
    </>
  );
};

export default ListPurchasesPerVendor;
