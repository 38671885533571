import Select from 'react-select';

export const CustomerSelection = ({ customers, onChange }) => {
    const options = customers?.map(vendor => ({
        value: vendor,
        label: vendor.name,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: 'green',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 5 }),
    };

    return (
        <div>
            <Select 
                onChange={(selectedOption) => {onChange(selectedOption.value)}}
                options={options} 
                isSearchable
                styles={customStyles}
                menuPortalTarget={document.body} // Add this line
                menuPosition={'fixed'} // Add this line
                maxMenuHeight={'50vh'}
            /> 
        </div>
    );
};