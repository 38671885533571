import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { useSelector } from "react-redux";

import AdminLayout from "../../layout/AdminLayout";

import {
  useAdminDeleteVendorMutation,
  useAdminGetAllVendorsQuery,
} from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanDeleteVendor, CanUserCreateVendor } from "../AdminActionEligibilities";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";

const ListVendors = () => {
  const navigate = useNavigate();
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery();
  const { data, isLoading, error } = useAdminGetAllVendorsQuery();
  const [adminDeleteVendor, { data: deletedVendorData, error: deleteError, isLoading: isDeleteLoading, isSuccess: deletedVendorSuccess }] = useAdminDeleteVendorMutation();
  const [showingDeleteConfirmVendorIndex, showDeleteVendorConfirmDialog] = useState(-1);

  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (deletedVendorSuccess) {
      toast.success(`Vendor ${deletedVendorData.vendorName} deleted`);
    }
  }, [error, deleteError, deletedVendorSuccess]);

  const confirmDeletingVendor = () => {
    if (showingDeleteConfirmVendorIndex >= 0) {
      adminDeleteVendor(data[showingDeleteConfirmVendorIndex]._id);
      showDeleteVendorConfirmDialog(-1);
    }
  }

  const setVendors = () => {
    const vendorsTable = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Phone"}> </CenterAlignedCell>,
          field: "phone",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    const canDelete = CanDeleteVendor(user);

    data?.forEach((vendor, index) => {
      vendorsTable.rows.push({
        id: index + 1,
        name: vendor.name,
        phone: <CenterAlignedCell value={vendor.phone}> </CenterAlignedCell>,
        status: String(vendor.status).toUpperCase(),
        actions: (
          <center>
            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <Link
                to={`/admin/vendors/${vendor._id}`}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>  

            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <Link
                to={`/admin/vendors/${vendor._id}/pricelist`}
                className="btn btn-outline-info"
              >
                <i className="fa fa-tag"></i>
              </Link>
            </div>  

            {canDelete && (
            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <button
                className="btn btn-outline-danger ms-2"
                onClick={() => showDeleteVendorConfirmDialog(index)}
                disabled={isDeleteLoading}
              >
                <i className="fa fa-trash"></i>
              </button>
              </div> 
            )}
          </center>
        ),
      });
    });

    return vendorsTable;
  };

  // Create new vendor
  const createNewVendorHandler = () => {
    // Redirect to create new vendor page
    navigate("/admin/vendors/new");
  }

  if (isLoading || loadingAdmin) return <Loader />;

  const canCreateVendor = CanUserCreateVendor(user);
  return (
    <>
      <MetaData title={"All Vendors"} />
      <HeaderAdmin title={"All Vendors"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.VENDORS.name}>
        <div id = "vendor_table" className="col-12 col-lg-11">
          <div className="row my-3 mx-2">
            <h1 className="col-4 col-lg-4 ">{data?.length} Vendors</h1>
            <div className="col-3 col-lg-3 offset-lg-5">
              {/*New Order Button - rigjht */}
              {canCreateVendor && (
              <button 
                onClick={() => {createNewVendorHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Vendor
              </button>)}             
            </div>
          </div>
          <MDBDataTable 
            data={setVendors()} 
            className="px-3" 
            bordered striped hover 
            noBottomColumns
          />
        </div>
        <ConfirmDialog message={`Are you sure to delete this vendor, ${showingDeleteConfirmVendorIndex >=0 ? data[showingDeleteConfirmVendorIndex].name : ""} ?`} show={showingDeleteConfirmVendorIndex >= 0} confirm={()=>{confirmDeletingVendor()}} cancel={()=> {showDeleteVendorConfirmDialog(-1)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ListVendors;
