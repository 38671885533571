import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';   // Import createApi and fetchBaseQuery from the toolkit/query/react module
import { getBackendURI, getQueryCorsMode } from './helper.js';

export const productsApi = createApi({   // Create a new API using the createApi function
    reducerPath: 'productsApi',   // Define a unique reducer path for the API
    baseQuery: fetchBaseQuery(
        { 
            baseUrl: getBackendURI('/api/v1'),
            mode: getQueryCorsMode(),
            credentials: 'include', // Include cookies in the request
        }),   // Define the base query using the fetchBaseQuery function
    tagTypes: ["Product"],   // Define the tag types for the API
    endpoints: (builder) => ({   // Define the endpoints for the API
        getProducts: builder.query({
            query: (params) => ({
              url: "/products",
              params: {
                page: params?.page,
                keyword: params?.keyword,
                category: params?.category,
              },
            }),
            providesTags: ["Product"],   // Define the tags provided by the endpoint
        }),
        getProductDetails: builder.query({   // Define a query endpoint to fetch product details
            query: (id) => `/products/${id}`,   // Define the query function to fetch product details
            providesTags: ["Product"],   // Define the tags provided by the endpoint
        }),
    }),
});

export const { 
    useGetProductsQuery,
    useGetProductDetailsQuery
 } = productsApi;