import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_NAME} from "../../constants/constants.js";

const PrintInvoice = ({order}) => {
  const MIN_ROWS = 10; // Set your desired minimum number of rows

  const { shipInfo, orderItems, orderNumber } = order;
  const customer = order?.customer;

  const handleDownload = () => {
    const input = document.getElementById("order_invoice");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
  
      const pdf = new jsPDF();
      const margin = 10; // Set your desired margin
  
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
  
      pdf.addImage(imgData, "PNG", margin, margin, pdfWidth, 0);
      pdf.save(`invoice_${order?._id}.pdf`);
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const filledOrderItems = [...orderItems];
  while (filledOrderItems.length < MIN_ROWS) {
    filledOrderItems.push({}); // Add empty objects to fill the rows
  }
  const formattedDeliveredAt = order.deliveredAt ? new Date(order.deliveredAt).toLocaleDateString() : new Date(order.createdAt).toLocaleDateString();
  const formattedPhoneNumber = formatPhoneNumber(customer?.addressInfo?.phone);

  return (
      <div className="container">
        <div className="order-invoice my-5">
          <div className="row d-flex justify-content-center mb-5">
            <button className="btn btn-success col-3" onClick={() => {handleDownload()}}>
              <i className="fa fa-print"></i>&nbsp;Download Invoice
            </button>
          </div>
          {/* Invoice */}
          <div id="order_invoice" className="p-3 border border-secondary" style={{ marginTop: '40px' }}>
            <header className="clearfix">
              {/* Top area */}
              <div className="d-flex justify-content-between align-items-center">
                {/* Company logo */}
                <div id="logo" className="text-center" style={{width: '15%', marginTop: '-15px'}}>
                  <img src="/images/TomProduce_logo.jpg" alt="Company Logo" style={{ height: '13vh', width: 'auto' }}/>
                </div>
                {/* Company info */}
                <div id="company" className="text-left" style={{width: '55%'}}>
                  <div style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#333' }}>{COMPANY_NAME}</div>
                  <div>
                    {COMPANY_ADDRESS_LINE1},
                    <br />
                    {COMPANY_ADDRESS_LINE2},
                  </div>
                  <div>{COMPANY_ADDRESS_PHONE}</div>
                  <div>
                    <a href="mailto:sales@tomproduce.com">sales@tomproduce.com</a>
                  </div>
                </div>
                {/* Invoice info */}
                <div className="text-right"  style={{width: '30%'}}>
                  <h1 style={{ textAlign: 'right' }}>INVOICE</h1>
                  <table className="table table-bordered text-center" style={{ tableLayout: 'fixed', width: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '50%' }}>Date</th>
                        <th style={{ width: '50%' }}>Invoice #</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{new Date().toLocaleDateString()}</td>
                        <td>{orderNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* End of Top area */}
              {/* Customer area */}
              <div className="row">
                <div className="col-5">
                  <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000' }}>Bill To</th></tr>
                    </thead>
                    <tbody>
                      <tr><td style={{ border: 'none' , fontSize: '1.2em', paddingLeft: '8px', paddingRight: '8px'  }}>{customer?.name}</td></tr>
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>{customer?.addressInfo?.address}</td></tr>
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>{customer?.addressInfo?.city},{" "}{customer?.addressInfo?.state}{" "}{customer?.addressInfo?.zipCode}</td></tr>                      
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>{formattedPhoneNumber}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-5 offset-2">
                  <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr><th style={{ paddingLeft: '20px', borderBottom: '1px solid #000' }}>Ship To</th></tr>
                    </thead>
                    <tbody>
                      <tr><td style={{ border: 'none' , fontSize: '1.2em', paddingLeft: '8px', paddingRight: '8px'  }}>{customer?.name}</td></tr>
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>{shipInfo?.address}</td></tr>
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>{shipInfo?.city},{" "}{shipInfo?.state}{" "}{shipInfo?.zipCode}</td></tr>                      
                      <tr><td style={{ border: 'none' , paddingLeft: '8px', paddingRight: '8px'  }}>&nbsp;</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* End of Customer area */}
              {/* Extra info */}
              <table className="table table-bordered centered-table">
                <thead>
                  <tr>
                    <th>P.O. Number</th>
                    <th>Terms</th>
                    <th>Rep</th>
                    <th>Ship</th>
                    <th>Via</th>
                    <th>F.O.B.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>{order.confirmedBy ? order.confirmedBy : ''}</td>
                    <td>{formattedDeliveredAt}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              {/* End of Extra info */}

              {/* Order items */}
              <main>
              <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse', padding: '8px'}}>
                <thead>
                  <tr>
                    <th style={{ width: '8%',  border: '1px solid #000', textAlign: 'center', padding: '10px'}}>Quantity</th>
                    <th style={{ width: '25%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Item Code</th>
                    <th style={{ width: '37%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Description</th>
                    <th style={{ width: '15%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Price Each</th>
                    <th style={{ width: '15%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {filledOrderItems.map((item, index) => (
                    <tr key={index} style={{ border: 'none' }}>
                      {item.finalQuantity !== undefined ? (
                        <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                          {item.finalQuantity}
                        </td>
                      ) : (
                        <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                          &nbsp;
                        </td>
                      )} 
                      <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                        {item.name ? `${item.product.sku}-${item.name}` : ' '}
                      </td>
                      <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px'}}>
                        {item.product?.description}
                      </td>
                      <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                        {item.finalPrice}
                      </td>
                      <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                        {item && item.finalPrice !== undefined && item.finalQuantity !== undefined ? ((item.finalPrice * item.finalQuantity)?.toFixed(2)) : ' '}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td colspan="3" style={{border: '1px solid #000', paddingLeft: '10px', paddingRight: '10px', fontSize: '0.8em'}}>
                      The perishable agricultural commodities listed on this invoice are sold subject to the statutory trust authorized by section 5(c) of the Perishable Agricultural Commodities Act, 1930 (7 U.S.C. 499e(c)). 
                      The seller of these commodities retains a trust claim over these commodities, all inventories of food or other products derived from these commodities, and any receivables or proceeds from the sale of 
                      these commodities until full payment is received.
                    </td>
                    <td colSpan="2" style={{ border: '1px solid #000', fontSize: '1.5em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px'}}>
                      <b>TOTAL</b>
                      <span style={{ float: 'right' }}>${order?.totalAmount}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </main>
            </header>
          </div>
          {/* End of Invoice */}
        </div>
      </div>
  );
}
export default PrintInvoice;
