import { configureStore } from "@reduxjs/toolkit";
import { productsApi } from "./api/productsApi.js";
import { productsAdminApi } from "./api/productsAdminApi.js"; 
import { inventoriesAdminApi } from "./api/inventoriesAdminApi.js"; 
import { customerAuthApi } from "./api/customerAuthApi.js";
import { customerApi } from "./api/customerApi.js";
import { adminAuthApi } from "./api/adminAuthApi.js";
import { adminApi } from "./api/adminApi.js";
import { orderApi } from "./api/orderApi.js";
import { orderAdminApi } from "./api/orderAdminApi.js";
import { purchaseAdminApi } from "./api/purchaseAdminApi.js";
import { priceListAdminApi } from "./api/priceListAdminApi.js";
import customerReducer from "./features/customerSlice.js";
import adminReducer from "./features/adminSlice.js";
import adminOptionsReducer from "./features/adminOptionsSlice.js";
import cartReducer from "./features/cartSlice.js";
import inventorySlice from "./features/inventorySlice.js";
import inProgressOrdersSlice from "./features/inProgressOrdersSlice.js";
import inProgressPurchasesSlice from "./features/inProgressPurchasesSlice.js";

export const store = configureStore({
    devTools: process.env.REACT_APP_NODE_ENV !== 'PRODUCTION', // Conditionally enable Redux DevTools
    reducer: {
        adminAuth: adminReducer,
        customerAuth: customerReducer,
        inventory: inventorySlice,
        inProgressOrders: inProgressOrdersSlice,
        inProgressPurchases: inProgressPurchasesSlice,
        cart: cartReducer,
        adminOptions: adminOptionsReducer,
        [productsApi.reducerPath]: productsApi.reducer, 
        [productsAdminApi.reducerPath]: productsAdminApi.reducer, 
        [inventoriesAdminApi.reducerPath]: inventoriesAdminApi.reducer,
        [customerAuthApi.reducerPath]: customerAuthApi.reducer,
        [customerApi.reducerPath]: customerApi.reducer,
        [adminAuthApi.reducerPath]: adminAuthApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,        
        [orderApi.reducerPath]: orderApi.reducer,
        [orderAdminApi.reducerPath]: orderAdminApi.reducer,
        [purchaseAdminApi.reducerPath]: purchaseAdminApi.reducer,
        [priceListAdminApi.reducerPath]: priceListAdminApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false, // Disables the immutableCheck middleware
            serializableCheck: true, // 
        }).concat([
            productsApi.middleware, 
            productsAdminApi.middleware, 
            inventoriesAdminApi.middleware,
            customerAuthApi.middleware, 
            customerApi.middleware,
            adminAuthApi.middleware, 
            adminApi.middleware,            
            orderApi.middleware,
            orderAdminApi.middleware,
            purchaseAdminApi.middleware,
            priceListAdminApi.middleware,
        ]),   // Add the middleware to the store
});