import { useEffect } from 'react';

const CheckAppVersion = () => {
  useEffect(() => {
    async function checkAppVersion() {
        try {
            const response = await fetch('/api/version', { cache: 'no-store' });
            const data = await response.json();
            const currentVersion = data.version;
            const storedVersion = localStorage.getItem('appVersion');

            console.log('Current version:', currentVersion, storedVersion);
            if (storedVersion !== currentVersion) {
                clearAllCaches();
                localStorage.setItem('appVersion', currentVersion);
                window.location.reload(true); // Use window.location.reload instead of location.reload
            }
        } catch (error) {
            console.error('Error checking app version:', error);
        }
    }

    async function clearAllCaches() {
        // Clear all caches
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map(cacheName => caches.delete(cacheName))
          );
          console.log('All caches cleared.');
        }
      
        // Force a hard reload of the page
        window.location.reload(true);
    }

    checkAppVersion();
  }, []);

  return null;
};

export default CheckAppVersion;