import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import HeaderAdmin from "../../layout/HeaderAdmin";
import AdminLayout from "../../layout/AdminLayout";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector } from "react-redux";

import { useAdminGetDriverDetailsQuery, useAdminGetDriverDeliveriesQuery, useAdminUpdateDriverDeliveryMutation } from "../../../redux/api/adminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanUpdateDriver } from "../AdminActionEligibilities";
import { CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";

import { ADMIN_MENU_ITEMS, DRIVER_DELIVERY_STATUS, USER_ROLES } from "../../../constants/constants.js";
import CustomPagination from "../../layout/CustomPagination";

const ListDriverDeliveries = () => {
  // Get URL params
  const driverId = useParams().id;
  const navigate = useNavigate();

  // Get search params
  let [searchParams] = useSearchParams();
  const filter = searchParams.get("filter") || DRIVER_DELIVERY_STATUS.CURRENT;
  const page = searchParams.get("page") || 1;
  const params = { page };

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: driverData, isLoading: isLoadingDriverDetails, error: errorLoadingDriverDetails } = useAdminGetDriverDetailsQuery(driverId);
  const { data: driverDeliveriesData, isLoading: isLoadingDriverDeliveries, error: errorLoadingDriverDeliveries } = useAdminGetDriverDeliveriesQuery({driverId, filter, params});
  const [adminUpdateDelivery, { isLoading: isUpdatingDriverDelivery, error: errorUpdatingDriverDelivery }] = useAdminUpdateDriverDeliveryMutation();

  const [deliveries, setDeliveries] = useState([]);
  const [clonedDeliveries, setClonedDeliveries] = useState([]);
  const [deliveryToUpdate, setDeliveryToUpdate] = useState(null);

  useEffect(() => {
    if (errorLoadingDriverDeliveries) {
      toast.error(errorLoadingDriverDeliveries?.message);
      if (errorLoadingDriverDeliveries?.status === 401) {
        adminLogout();
      }
    }

    if (errorUpdatingDriverDelivery) {
      toast.error(errorUpdatingDriverDelivery?.data?.message);
    }

  }, [errorLoadingDriverDeliveries, errorUpdatingDriverDelivery]);

  useEffect(() => {
    if (errorLoadingDriverDetails) {
      toast.error(errorLoadingDriverDetails?.data?.message);
      if (errorLoadingDriverDetails.status === 401) {
        adminLogout();
      }
    }
    else if (errorLoadingDriverDeliveries) {
      toast.error(errorLoadingDriverDeliveries?.data?.message);
      if (errorLoadingDriverDeliveries.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingDriverDetails, errorLoadingDriverDeliveries]);

  useEffect(() => {
    if (driverDeliveriesData?.deliveries) {
      setDeliveries(driverDeliveriesData.deliveries);
      setClonedDeliveries(JSON.parse(JSON.stringify(driverDeliveriesData.deliveries)));
    }
  }, [driverDeliveriesData]);

  function confirmUpdatingDelivery(deliveryId) {
    const index = clonedDeliveries.findIndex((delivery) => delivery._id === deliveryId);
    adminUpdateDelivery({deliveryId: clonedDeliveries[index]._id, body: {paymentId: clonedDeliveries[index].paymentId, amount: clonedDeliveries[index].amount}});
    setDeliveryToUpdate(null);
  }

  function handleFilterChange(filters) {
    const { filter, startDate, endDate } = filters;
    const searchParams = new URLSearchParams();
  
    if (filter !== searchParams.get("filter")) {
      // Reset page when filter changes
      setDeliveries([]);
      setClonedDeliveries([]);

      if (filter) {
        searchParams.set("filter", filter);
      }
      if (startDate) {
        searchParams.set("startDate", startDate);
      }
      if (endDate) {
        searchParams.set("endDate", endDate);
      }
      // Reset to page 1 for new filters
      searchParams.set("page", 1); 
      
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }

  function handlePaymentIdChange(index, value) {
    const newDeliveries = [...clonedDeliveries];
    newDeliveries[index].paymentId = value;
    setClonedDeliveries(newDeliveries);
  }

  function handleAmountChange(index, value) {
    const newDeliveries = [...clonedDeliveries];
    newDeliveries[index].amount = value;
    setClonedDeliveries(newDeliveries);
  }

  const canUpdate = CanUpdateDriver(user);

  const setDeliveriesData = () => {
    const deliveriesTable = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
        },
        {
          label: <CenterAlignedCell value={"Order #"}> </CenterAlignedCell>,
          field: "orderNumber",
        },
        {
          label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
          field: "customerName",
        },
        {
          label: <CenterAlignedCell value={"Pallet"}> </CenterAlignedCell>,
          field: "pallet",
        },
        {
          label: <CenterAlignedCell value={"Date"}> </CenterAlignedCell>,
          field: "date",
        },
        {
          label: <CenterAlignedCell value={"Amount"}> </CenterAlignedCell>,
          field: "amount",
        },   
        {
          label: <CenterAlignedCell value={"Payment #"}> </CenterAlignedCell>,
          field: "paymentId",
        },        
        {
          label: <CenterAlignedCell value={"Action"}> </CenterAlignedCell>,
          field: "action",
        },     
      ],
      rows: [],
    };

    clonedDeliveries?.forEach((delivery, index) => {
      const row = {
        id: <RightAlignedCell value={index + 1} />,
        orderNumber: <RightAlignedCell value={delivery.orderNumber} />,
        customerName: delivery.customer,
        pallet: <CenterAlignedCell value={delivery.pallet} />,
        date: <CenterAlignedCell value={new Date(delivery.date).toLocaleString()} />,
        paymentId: <EditableNumberCell value={delivery.paymentId} color={clonedDeliveries[index].paymentId === deliveries[index].paymentId ? 'black' : 'red'} type={"text"} onChange={(val) => {handlePaymentIdChange(index, val)}}/>,
        amount: <EditableNumberCell value={delivery.amount} color = {clonedDeliveries[index].amount === deliveries[index].amount ? 'black' : 'red'} onChange={(val) => {handleAmountChange(index, val)}}/>,
      };
      
      if (canUpdate && filter === DRIVER_DELIVERY_STATUS.CURRENT) {
        row.action = (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              className="btn btn-primary"
              disabled={clonedDeliveries[index].amount === deliveries[index].amount && clonedDeliveries[index].paymentId === deliveries[index].paymentId}
              onClick={() => {
                setDeliveryToUpdate(delivery._id);
              }}
            >
              Update
            </button>
          </div>
        );
      }
      deliveriesTable.rows.push(row);
    });
    return deliveriesTable;
  };

  if (isLoadingDriverDetails || loadingAdmin) return <Loader />;

  let deliveryToUpdateString = "";

  if (deliveries) {
    deliveryToUpdateString = deliveryToUpdate ? `#${deliveries.find((delivery) => delivery._id === deliveryToUpdate).orderNumber}` : "";
  }

  return (
    <>
      <MetaData title={"Driver Deliveries"} />
      <HeaderAdmin title={"Driver Deliveries"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
      <div className="col-12">
        <div className="row my-4">
          <div className="col-4 col-lg-4">
            <h1>{driverData.driver.name}</h1>
          </div>
        </div>  
        <div className="row col-11">
          <div className="col-2">
            <select
              id="type_field"
              className="form-select"
              name="filter"
              value={filter}
              onChange={(e) => handleFilterChange({ filter: e.target.value} )}
            >
              <option value={DRIVER_DELIVERY_STATUS.CURRENT}>CURRENT</option>
              <option value={DRIVER_DELIVERY_STATUS.HISTORY}>HISTORY</option>
            </select>
          </div>
        </div>
        <div className="col-10">
          {/* Loader */}
          {(isLoadingDriverDeliveries || isUpdatingDriverDelivery) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable 
            data={setDeliveriesData()} 
            className="px-3 myMDBDataListDriverDeliveriesTableRow" 
            bordered striped hover 
            noBottomColumns
            paging={false}
          />
        </div>
        {/* Pagination - only on Finished*/}
        {filter === DRIVER_DELIVERY_STATUS.HISTORY && driverDeliveriesData && driverDeliveriesData.resPerPage < driverDeliveriesData.totalDeliveryCount && (
          <div className="col-10">
            <CustomPagination 
              resPerPage={Number(driverDeliveriesData.resPerPage)} 
              filteredProductsCount={Number(driverDeliveriesData.totalDeliveryCount)}
            />
          </div>
        )}
      
        <ConfirmDialog message={`Are you sure to update delivery for order ${deliveryToUpdateString}?`} show={deliveryToUpdate !== null} confirm={()=>{confirmUpdatingDelivery(deliveryToUpdate)}} cancel={()=> {setDeliveryToUpdate(null)}}> </ConfirmDialog>
      </div>
      </AdminLayout>
    </>
  );
};

export default ListDriverDeliveries;
