
import React, { useState, useEffect, useRef  } from 'react';
import { ORDER_STATUS, ORDER_STATUS_ICON, ORDER_USER_RIGHTS, PURCHASE_STATUS, PURCHASE_USER_RIGHTS } from "../../../constants/constants.js";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import _, { has, set } from "lodash";
import { useLazyAdminGetVendorProductPriceListQuery } from "../../../redux/api/priceListAdminApi";
import { DetermineUserOrderRights, DetermineUserPurchaseRights } from "../../admin/AdminActionEligibilities";
import Loader from '../Loader';
import { ButtonCell, EditableNumberCell, RightAlignedCell } from '../CustomMDBDataTableCell';

import { VendorSelection } from "../../vendor/VendorSelection";
import { getSellPrice } from '../../../utils/utilities';
import { CustomerSelection } from '../../customer/CustomerSelection';
import { ConfirmDialog } from '../../admin/ConfirmDialog';
import { toast } from 'react-toastify';

export const InventoryInOutBoundPopup = ({ onClose, product, title, data, dataTimestamp, vendors, customers, visibility, updateFunction, removeItemFunction, user, column }) => {
  const [adminGetVendorProductPriceListQuery, { data: vendorProductPriceListData, isLoading: loadingProductPriceListData }] = useLazyAdminGetVendorProductPriceListQuery();

  const [clonedDataTimestamp, setClonedDataTimestamp] = useState(0);
  const [clonedOrdersData, setClonedOrdersData] = useState([]);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const popupRef = useRef(); // Create a ref

  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [vendorProductPriceHash, setVendorProductPriceHash] = useState({});
  
  useEffect(() => {
    if (data) {
      console.log("dataTimestamp", dataTimestamp, clonedDataTimestamp)
      if (dataTimestamp !== clonedDataTimestamp) {
        console.log("dataTimestamp", dataTimestamp)
        setClonedDataTimestamp(dataTimestamp);
        setClonedOrdersData(JSON.parse(JSON.stringify(data)));
      }
    } else {
      setClonedOrdersData([]);
    }
    setThereAreValueChanges(false);
  }, [data]);

  useEffect(() => { 
    if (visibility === 'visible' && column === 'inbound') {
      adminGetVendorProductPriceListQuery(product._id);
    }
  }, [visibility]);

  // Add this useEffect hook
  useEffect(() => {
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // don't allow click below the popup
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("mouseup", handleClick);
    } else {
      document.removeEventListener("mouseup", handleClick);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClick);
    };
  }, [visibility]);
  
  useEffect(() => {
    if (vendorProductPriceListData) {
      const priceHash = {};
      vendorProductPriceListData.forEach((item) => {
        priceHash[item.vendor] = item.price;
      });

      setVendorProductPriceHash(priceHash);

      // Update the price new purchase order
      clonedOrdersData.forEach((item, index) => {
        if (item.vendor && (!item.price || item.price === "")) {
          item.price = priceHash[item.vendor._id];
        }
      });
    }
  
    setFormUpdated(!formUpdated);
  }, [vendorProductPriceListData]);
  //--------------------------------- Functions ---------------------------------
  // Update quantity of the orders
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrdersData[index].quantity !== value) {
      clonedOrdersData[index].quantity = Number(value);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };

  // Update price of the orders
  const handlePriceChange = (index, value) => {
    //console.log("handlePriceChange", value, clonedOrdersData[index].price, data[index].price)
    if (valueIsANonNegativeNumber(value) && clonedOrdersData[index].price !== value) {
      clonedOrdersData[index].price = Number(value);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
      setFormUpdated(!formUpdated);
    }
  };  

  const addItem = () => {
    const newItem = {
      status: ORDER_STATUS.PENDING,
      price: "",
    };
    clonedOrdersData.push(newItem);
    setFormUpdated(!formUpdated);
    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
  }

  const getVendorCustomerName = (index) => {
    if (index >= 0) {
      return column === 'inbound' ? clonedOrdersData[index].vendor?.name : clonedOrdersData[index].customer?.name;
    }
  }

  const removeItem = (index) => {
    if (getVendorCustomerName(index)) {
      showRemoveItemConfirmDialog(index);
    } else {
      // If the item is new, remove it directly
      confirmRemovingItem(index);
    }
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      if (column === 'inbound') {
        removeItemFunction?.remove({ productId: product._id, purchaseId: clonedOrdersData[index].purchaseId });
      } else {
        removeItemFunction?.remove({ productId: product._id, orderId: clonedOrdersData[index].orderId });
      }
      clonedOrdersData.splice(index, 1);
      setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));

      // Trigger remove query
      
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Handle vendor select
  const handleVendorSelect = (index, vendor) => {
    clonedOrdersData[index].vendor = vendor;

    // default value
    clonedOrdersData[index].quantity = 1;
    // load price
    clonedOrdersData[index].price =  vendorProductPriceHash && vendorProductPriceHash[vendor._id] ? vendorProductPriceHash[vendor._id] : "";

    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
    setFormUpdated(!formUpdated);
  }

  const handleCustomerSelect = (index, customer) => {
    clonedOrdersData[index].customer = customer;
    // default value
    clonedOrdersData[index].quantity = 1;
    clonedOrdersData[index].price = getSellPrice(product, customer.tier).price;
    setThereAreValueChanges(!_.isEqual(clonedOrdersData, data));
    setFormUpdated(!formUpdated);
  }

  // Clone the inventory object
  let filteredVendor = [...vendors];
  let filteredCustomer = [...customers];
  // Remove vendors that already in the list
  if (column === 'inbound') {
    clonedOrdersData.forEach((order) => {
      filteredVendor = filteredVendor.filter(vendor => vendor._id !== order.vendor?._id);
    });
  } else if (column === 'outbound') {
    clonedOrdersData.forEach((order) => {
      filteredCustomer = filteredCustomer.filter(customer => customer._id !== order.customer?._id);
    });
  }  

  // Update orders
  const updateHandler = () => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      const ordersDataToUpdate = [];
      let hasError = false;
      clonedOrdersData?.forEach((order, index) => {
        if (!order.price || !order.quantity) {
          toast.error(`Please enter price and quantity for ${column === 'inbound' ? order.vendor.name : order.customer.name}`);
          hasError = true;
        }
        // Only push to update the changed order
        if ((clonedOrdersData[index].customer !== undefined || clonedOrdersData[index].vendor !== undefined) && !_.isEqual(clonedOrdersData[index], data[index])) {
          ordersDataToUpdate.push({
            orderId: order.orderId,
            purchaseId: order.purchaseId,
            finalPrice: order.price,
            finalQuantity: order.quantity,
            customer: order.customer,
            vendor: order.vendor
          });
        }
      });
      if (!hasError && ordersDataToUpdate.length > 0 && updateFunction) {
        updateFunction.update({ id: product._id, body: ordersDataToUpdate });
      }
    }
  };

  const popupStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };
  const total = () => {
    let total = 0;
    clonedOrdersData.forEach(item => {
      total += item.quantity ? item.quantity : 0;
    });
    return total;
  }

  const canUpdate = updateFunction !== null;

  return(
    <div ref={popupRef} id="popup" class="popup" style={{...popupStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div className="col-2"> 
          {updateFunction && (
            <button onClick={updateHandler} className="btn btn-success" disabled={!thereAreValueChanges || updateFunction?.updating}>
              Update
            </button>
          )}
        </div> 
        <div className="col-8 text-center"><h5>{title}</h5></div>
        <div className="col-2 text-end">
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div style={{ width: '96%', maxHeight: '80vh', overflow: 'overlay'}}>
        <table className="table table-bordered" style={{ width: '100%' }}>
          <tbody>
            { clonedOrdersData && data && (
                clonedOrdersData.map((item, index) => {
                  // Check user rights
                  let editable = false;
                  let deletable = false;
                  if (column === 'inbound') {
                    const {rights} = DetermineUserPurchaseRights(user, item.status);
                    editable = canUpdate && rights?.some(right => right === PURCHASE_USER_RIGHTS.UPDATE);           
                    deletable = canUpdate && rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE);               
                  } else {
                    const {rights} = DetermineUserOrderRights(user, item.status);
                    editable = canUpdate && rights?.some(right => right === ORDER_USER_RIGHTS.UPDATE);
                    deletable = canUpdate && rights?.some(right => right === ORDER_USER_RIGHTS.DELETE);
                  }
                  const productOwner = column === 'inbound' ? item.vendor : item.customer;
                  const name = productOwner && productOwner.name ? `${index + 1}. ${productOwner?.name}` : undefined;
                  const price = item.price;

                  return(
                    <tr key={index} style={{width: '100%'}} >
                      {/* Status */}
                      <td className={'text-center'} style={{width: '5%'}}>
                        {column === 'outbound' ? (
                          <span  >
                            { ORDER_STATUS_ICON[item.status] && (<i className={ORDER_STATUS_ICON[item.status].icon}  style={{ color: ORDER_STATUS_ICON[item.status].color }} /> )}
                          </span>
                        ) : (
                          <span  >
                            { ORDER_STATUS_ICON[item.status] && (<i className={ORDER_STATUS_ICON[item.status].icon}  style={{ color: ORDER_STATUS_ICON[item.status].color }} /> )}
                          </span>
                        )
                        }
                      </td>

                      {/* Name */}
                        { name ? (
                            <td className={'text-start'} style={{width: '60%'}}>
                              {name}
                            </td>             
                        ):(
                          <td className={'text-start'} style={{width: '60%'}}>
                            {column === 'inbound' && (
                              VendorSelection({ vendors: filteredVendor , onChange: (vendor)=> handleVendorSelect(index, vendor)})
                            )}
                            {column === 'outbound' && (
                              CustomerSelection({ customers: filteredCustomer , onChange: (customer)=> handleCustomerSelect(index, customer)})
                            )}
                          </td>
                        )}

                      {/* Price */}
                      <td className={'text-end'} style={{width: '15%'}}>
                        <EditableNumberCell 
                          readOnly={!editable} 
                          value={price} onChange={(val) => {handlePriceChange(index, val)}} color={item.price !== data[index]?.price ? 'red' : 'black'}> 
                        </EditableNumberCell>
                      </td>
                      {/* Quantiy */}
                      <td className={'text-end'} style={{width: '15%'}}>
                        {editable ? 
                          (<EditableNumberCell 
                            readOnly={!editable} 
                            value={item.quantity} onChange={(val) => {handleQuantityChange(index, val)}} color={item.quantity !== data[index]?.quantity ? 'red' : 'black'}> 
                          </EditableNumberCell>
                          ):(
                            <RightAlignedCell value={item.quantity} />
                          )
                        }
                      </td>
                      {/* Action - remove - only can remove pending purchases*/}
                      <td className={'text-center'} style={{width: '5%'}}>
                        <span  >
                          {deletable && (
                            <ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {removeItem(index);}} > </ButtonCell>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              )
            }
          </tbody>
        </table>
      </div>
      {loadingProductPriceListData && (
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>
      )}
      <div className="row col-12 my-2">
        <div className="col-5"></div>
        <div className="col-2 text-center">
          {canUpdate && (
            <button onClick={addItem} className="btn btn-primary" disabled={updateFunction?.updating}>
              +
            </button>
          )}
        </div>
        <div className="col-5 text-end">
          <h5>Total Units: {total()}</h5>
        </div>
      </div>
      {(updateFunction?.updating || removeItemFunction?.removing) && 
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>
      }
      <ConfirmDialog message={`Are you sure to remove ${getVendorCustomerName(itemIndexToRemove)}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
    </div>
  );
};