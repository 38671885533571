import React from 'react';
import Select, { components  } from 'react-select';

export const ProductSelection = ({ productList, defaultItem, onChange }) => {
    const defaultOption = {
        value: defaultItem?.product,
        label: defaultItem && defaultItem.product ? `${defaultItem.name} (${defaultItem.sku})` : 'Select a product',
        sku: defaultItem?.sku,
    };
    const options = Object.entries(productList).map(([id, product]) => ({
        value: id,
        label: product.product.name,
        sku: product.product.sku,
        isDisabled: product.product.isActive === false,
    }));
    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: 'green',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
    };
    const DropdownIndicator = () => null;
    // Custom filter function - filter by both name and sku
    const filterOption = (option, inputValue) => {
        const { label, sku } = option.data;
        return (
            label?.toLowerCase().includes(inputValue.toLowerCase()) ||
            sku?.includes(inputValue.toLowerCase())
        );
    };

    // Custom Option component
    const CustomOption = (props) => {
        const { isDisabled, data } = props;
        return (
            <components.Option {...props} isDisabled={isDisabled}>
                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '100%', 
                    color: isDisabled ? '#ccc' : 'inherit' 
                }}>
                    <strong style={{ fontSize: '0.9em' }}>{data.label}</strong>
                    <div style={{ fontSize: '0.75em', color: isDisabled ? '#ccc' : '#888' }}>{data.sku}</div>
                </div>
            </components.Option>
        );
    };

    // Custom SingleValue component
    const CustomSingleValue = ({
        children,
        selectProps,
        ...props
    }) => {
        return (
            <components.SingleValue {...props}>
                {children}
            </components.SingleValue>
        );
    };

    return (
        <div>
            <Select 
                value={defaultOption}
                onChange={(selectedOption) => {onChange(productList[selectedOption.value].product);}}
                options={options} 
                isSearchable
                styles={customStyles}
                maxMenuHeight={'50vh'}
                components={{
                    DropdownIndicator,
                    Option: CustomOption,
                    SingleValue: CustomSingleValue
                }}
                filterOption={filterOption}
            />
            
        </div>
    );
};