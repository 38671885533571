import React, { useEffect, useState } from "react";
import { useCustomerRegisterMutation } from "../../redux/api/customerAuthApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import Header from "../layout/Header";

import { US_STATES } from  "../../constants/constants.js";
import { validateCustomerProfileForm, validateUserPassword } from "../../utils/validators";

const Register = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    addressInfo: {
      address: "",
      city: "",
      phone: "",
      state: "California",
      zipCode: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const { email, password, confirmPassword, name, addressInfo } = user;

  const navigate = useNavigate();

  const [register, { isLoading, error, isSuccess }] = useCustomerRegisterMutation();

  //const { isAuthenticated } = useSelector((state) => state.customerAuth);

  useEffect(() => {
    if (isSuccess) {
      navigate("/home");
    }

    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error, isSuccess]);


  const submitHandler = (e) => {
    e.preventDefault();

    const { formIsValid, formErrors } = validateCustomerProfileForm(user);
    const { passwordIsValid, passwordErrors } = validateUserPassword(user);

    if (formIsValid && passwordIsValid) {
      const signUpData = {
        name,
        addressInfo,
        email,
        password,
      };
      register(signUpData);
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const updateAddressHandler = (e) => {
    const clonedAddressInfo = { ...addressInfo };
    clonedAddressInfo[e.target.name] = e.target.value;
    setUser({ ...user, addressInfo: clonedAddressInfo });
  };

  return (
    <>
      <MetaData title={"Register"} />
      <Header noVerification={true} />
      <div className="container">
        <div className="row wrapper">
          <div className="col-10 col-lg-5">
            <form className="shadow rounded bg-body" onSubmit={submitHandler}>
              <h2 className="mb-4">Register</h2>
              {/* Name */}
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={onChange}
                />
              </div>
              {/* Address */}
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={addressInfo.address}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>
              {/* City */}
              <div className="mb-3">
                <label htmlFor="city_field" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  id="city_field"
                  className="form-control"
                  name="city"
                  value={addressInfo.city}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              {/* State */}
              <div className="mb-3">
                <label htmlFor="state_field" className="form-label">
                  State
                </label>
                <select
                  id="selection_field"
                  className="form-control"
                  name="state"
                  value={addressInfo.state}
                  onChange={(e) => { updateAddressHandler(e) }}
                >
                  <option value="" disabled>Select a state</option>
                  {US_STATES.map((state) => (
                    <option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Phone Number */}
              <div className="mb-3">
                <label htmlFor="phone_field" className="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone_field"
                  className="form-control"
                  name="phone"
                  value={addressInfo.phone}
                  pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                  onInvalid={(e) => {
                    e.target.setCustomValidity('Please enter a valid phone number');
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity('');
                  }}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>
              {/* Zip Code */}
              <div className="mb-3">
                <label htmlFor="zipCode_field" className="form-label">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zipCode_field"
                  className="form-control"
                  name="zipCode"
                  value={addressInfo.zipCode}
                  pattern="\d{5}"
                  onInvalid={(e) => {
                    e.target.setCustomValidity('Please enter a 5 digit zip code');
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity('');
                  }}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              {/* Email */}
              <div className="mb-3">
                <label htmlFor="email_field" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChange}
                />  
              </div>

              {/* Password */}
              <div className="mb-3">
                <label htmlFor="password_field" className="form-label">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password_field"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChange}         
                />
                <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                  Password must be at least 8 characters long
                </p>
              </div>

              {/* Confirm Password */}
              <div className="mb-3">
                <label htmlFor="confirm_password_field" className="form-label">
                  Confirm Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirm_password_field"
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                />
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>

              <button
                id="register_button"
                type="submit"
                className="btn w-100 py-2"
                disabled={isLoading}
              >
                {isLoading ? "Registering ..." : "REGISTER"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
