import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import MetaData from "../layout/MetaData";
import { useOrderDetailsQuery } from "../../redux/api/orderApi";
import { ORDER_STATUS, PAYMENT_TYPE, PAYMENT_STATUS } from "../../constants/constants.js";
import Header from "../layout/Header";
import { ButtonCell, EditableNumberCell } from "../layout/CustomMDBDataTableCell.jsx";
import { ConfirmDialog } from "../admin/ConfirmDialog.jsx";
import { useDeleteOrderMutation, useUpdateOrderMutation } from "../../redux/api/orderApi";
import _ from "lodash";
import { valueIsANonNegativeNumber } from "../../utils/validators.js";

const OrderDetails = () => {
  const navigate = useNavigate();

  const { customer } = useSelector((state) => state.customerAuth);
  const params = useParams();
  const { data, isLoading, error } = useOrderDetailsQuery({id: params?.id}, { refetchOnMountOrArgChange: true });
  const [deleteOrder, { isLoading: isDeletingOrder, error: errorDeletingOrder, isSuccess: orderDeleteSuccess }] = useDeleteOrderMutation();
  const [updateOrder, { data: updatedOrderData, isLoading: isUpdatingOrder, isSuccess: orderUpdateSuccess, error: orderUpdateError }] = useUpdateOrderMutation();

  const [order , setOrder] = useState({});
  const [clonedOrderItems, setClonedOrderItems] = useState([]);  
  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [showingDeleteOrderConfirm, showDeleteOrderConfirmDialog] = useState(false);
  const [showingUpdateOrderConfirm, showUpdateOrderConfirmDialog] = useState(false);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [notes, setNotes] = useState("");
  const [formUpdated, setFormUpdated] = useState(false);
  const { orderNumber, shipInfo, orderItems, paymentInfo, totalAmount, status } = order;

  const isPaid = paymentInfo?.status === "paid" ? true : false;

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (orderUpdateError) {
      toast.error(orderUpdateError?.data?.message);
    }
  }, [error, orderUpdateError]);

  useEffect(() => {
    if (data?.order) {
      setOrder(data.order);
      if (data.order.orderItems) {
        setClonedOrderItems(JSON.parse(JSON.stringify(data.order.orderItems)));
      }
    }
  }, [data]);

  // Update (process) order callback
  useEffect(() => {
    if (orderUpdateSuccess && updatedOrderData && updatedOrderData.orderItems) {
      setClonedOrderItems(JSON.parse(JSON.stringify(updatedOrderData.orderItems)));
      setThereAreValueChanges(false);
      toast.success("Order updated successfully", updatedOrderData);
    }
  }, [orderUpdateSuccess]);

  useEffect(() => {
    if (errorDeletingOrder) {
      toast.error(errorDeletingOrder?.data?.message);
    } else if (orderDeleteSuccess) {
      navigate("/me/orders/");
    }
  }, [errorDeletingOrder, orderDeleteSuccess]);

  // ----------- Functions -----------------
  function isOrderUpdated() {
    return order && (!_.isEqual(clonedOrderItems, orderItems));
  }

  function considerEnableUpdateButton() 
  {
    if (order) {
      setThereAreValueChanges(isOrderUpdated());
    }
  }

  // Remove an item
  const handleRemoveItem = (index) => {
    if (clonedOrderItems[index].name) {
      showRemoveItemConfirmDialog(index);
    } else {
      // If the item is new, remove it directly
      confirmRemovingItem(index);
    }
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      clonedOrderItems.splice(index, 1);
      considerEnableUpdateButton();
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Delete order
  const handleDeleteOrder = () => {
    if (!isDeletingOrder) showDeleteOrderConfirmDialog(true);
  }

  // Confirm deleting order
  const confirmDeletingOrder = () => {
    showDeleteOrderConfirmDialog(false);
    if (!isDeletingOrder) {
      deleteOrder(order._id);
    }
  }

  const confirmUpdatingOrder = () => {
    if (isOrderUpdated()) {
      updateOrder({ id: order._id, body: { itemList: clonedOrderItems } });
    }
  }

  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrderItems[index].finalQuantity !== value) {
      clonedOrderItems[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
      considerEnableUpdateButton();
    }
  };

  //----------------------------------------

  if (isLoading || !orderNumber) {
    return <Loader />;
  }

  const editable = order.status === ORDER_STATUS.PENDING;

  return (
    <>
      <MetaData title={"Order Details"} />
      <Header title={`Order ${order.orderNumber}`} />
      <div className="container">
        <div className="col-12 order-details justify-content-center">
          <div className="row d-flex align-items-center">
            <div className="col-10">
              <h3 className="mt-5 mb-4">Your Order Details</h3>
            </div>
            {editable && (
              <div className="col-2 d-flex justify-content-end">
                <button className="btn btn-danger" onClick={handleDeleteOrder} disabled={!editable}> Remove </button>
              </div>
            )}
          </div>
          <div>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">ID</th>
                  <td>{orderNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Status</th>
                  <td
                    className={
                      String(status).includes(ORDER_STATUS.DELIVERED)
                        ? "greenColor"
                        : "redColor"
                    }
                  >
                    <b>{String(status).toLocaleUpperCase()}</b>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Date</th>
                  <td>{new Date(order?.createdAt).toLocaleString("en-US")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h3 className="mt-5 mb-4">Shipping Info</h3>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Name</th>
                  <td>{customer?.name}</td>
                </tr>
                <tr>
                  <th scope="row">Phone No</th>
                  <td>{shipInfo?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">Address</th>
                  <td>
                    {shipInfo?.address}, {shipInfo?.city},{" "}
                    {shipInfo?.zipCode}, {shipInfo?.country}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h3 className="mt-5 mb-4">Payment Info</h3>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Status</th>
                  <td className={isPaid ? "greenColor" : "redColor"}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <b>{String(paymentInfo?.status).toUpperCase()}</b>
                    </div>
                    <div>
                      <Link to={`/invoice/order/${order?._id}`} className="btn btn-success">
                        <i className="fa fa-print"></i> Invoice
                      </Link>
                    </div>
                  </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Method</th>
                  <td>{String(paymentInfo?.paymentType).toUpperCase()}</td>
                </tr>
                {/*
                <tr>
                  <th scope="row">Invoice #</th>
                  <td>{orderNumber}</td>
                </tr>
                */}
                <tr>
                  <th scope="row">Total Amount</th>
                  <td>{totalAmount === undefined ? '' : `${totalAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</td>
                </tr>
                {/*
                <tr>
                  <th scope="row">Amount Paid</th>
                  <td>{paymentInfo?.amount ? "$" + paymentInfo.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "" }</td>
                </tr>
                */}
              </tbody>
            </table>
          </div>

          {/* Loader */}
          {(isUpdatingOrder || isDeletingOrder) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}

          <div>
            <h3 className="mt-5 my-4">Order Items:</h3>
            <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse', padding: '8px'}}>
              <thead>
                <tr>
                  <th style={{ width: '8%',  border: '1px solid #000', textAlign: 'center', padding: '10px'}}>#</th>
                  <th style={{ width: '45%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Item</th>
                  <th style={{ width: '13%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Price Each</th>
                  <th style={{ width: '13%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Quantity</th>
                  <th style={{ width: '14%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Amount</th>
                  <th style={{ width: '9%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {clonedOrderItems.map((item, index) => (
                  <tr key={index} style={{ border: 'none' }}>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '10px'}}>
                      {index + 1}
                    </td>
                    <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', padding: '5px' }}>
                      {item.name}
                    </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                      {item.finalPrice}
                    </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                        {<EditableNumberCell readOnly={!editable} value={item.finalQuantity} onChange={(val) => {handleQuantityChange(index, val)}} color={clonedOrderItems[index]?.finalQuantity === orderItems[index]?.finalQuantity ? 'black' : 'red'}> </EditableNumberCell>}
                      </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                      {item && item.finalPrice !== undefined && item.finalQuantity !== undefined ? ((item.finalPrice * item.finalQuantity)?.toFixed(2)) : '_'}
                    </td>
                    <td style={{ textAlign: 'center', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                      {editable && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {handleRemoveItem(index);}} > </ButtonCell>)}                 
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colSpan="6" style={{ border: '1px solid #000', fontSize: '1.5em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px'}}>
                    <b>TOTAL</b>
                    <span style={{ float: 'right' }}>{order?.totalAmount === undefined ? '_' : `$${order?.totalAmount}`}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row my-0">
            <div className="col-7 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => { setNotes(e.target.value); }}
              />
            </div>
            <div className="col-4 my-0 offset-1">
              {/* Update Button */}
              {editable && (
              <div className="my-4">
                <button 
                    disabled={!thereAreValueChanges || isUpdatingOrder || isDeletingOrder}
                    onClick={() => {showUpdateOrderConfirmDialog(true);}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Update
                </button>
              </div>)}
            </div>
          </div>
        </div>
      </div>
      <div>
        <ConfirmDialog message={`Are you sure to remove ${clonedOrderItems[itemIndexToRemove] ? clonedOrderItems[itemIndexToRemove].name :"row"}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to delete this order?`} show={showingDeleteOrderConfirm} confirm={()=>{confirmDeletingOrder()}} cancel={()=> {showDeleteOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to update this order? `} show={showingUpdateOrderConfirm} confirm={()=>{confirmUpdatingOrder(); showUpdateOrderConfirmDialog(false); }} cancel={()=> {showUpdateOrderConfirmDialog(false)}}> </ConfirmDialog>
      </div>
    </>
  );
};

export default OrderDetails;
